import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ chartData }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      title: {
        display: true,
        text: 'Overall Case Report',
        color: '#203d7e',
        font: {
          size: 16, // Increase title font size
          weight: 600,
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default BarChart;
