import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import API_URL from '../../Apiurl';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  error: false,
  success: '',
  invoiceList: [],
  deleteLoading: false,
  deleteSuccess: '',
  deleteError: '',
  invoiceDetails: null
};

const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false; // Clear previous errors
      state.success = '';  // Clear previous success messages
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.error("Error:", action.payload); // Log error
    },

    // GET COMPANY LIST SUCCESS
    getInvoiceListSuccess(state, action) {
      state.isLoading = false;
      state.invoiceList = action.payload.data;
      state.error = false;
      state.success = 'Invoice list fetched successfully!';
    },

    // START DELETE LOADING
    startDeleteLoading(state) {
      state.deleteLoading = true;
      state.deleteError = false; // Clear previous errors
      state.deleteSuccess = ''; // Clear previous success messages
    },

    // DELETE HAS ERROR
    deleteHasError(state, action) {
      state.deleteLoading = false;
      state.deleteError = action.payload;
      console.error("Delete Error:", action.payload); // Log error
    },

    // DELETE COMPANY SUCCESS
    deleteInvoiceSuccess(state, action) {
      state.deleteLoading = false;
      state.deleteSuccess = 'Invoice deleted successfully!';
      state.deleteError = false;

      // Optionally, remove the deleted invoice from the invoiceList
      state.invoiceList = state.invoiceList?.filter(invoice => invoice.id !== action.payload.id);
    },
    getInvoiceDetailsSuccess(state, action) {
      state.isLoading = false;
      state.invoiceDetails = action.payload.data;
      state.error = false;
    }  
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  getInvoiceListSuccess,
  startDeleteLoading,
  deleteHasError,
  deleteInvoiceSuccess,
  getInvoiceDetailsSuccess
} = slice.actions;

// Async Action to Get Invoice List
export function getInvoiceList() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const emp_id = JSON.parse(localStorage.getItem("user")).user_id
      const resp = await axios.get(`${API_URL}list-invoice/${emp_id}`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getInvoiceListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch invoice list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the invoice list"));
    }
  };
}

// Async Action to Add Invoice
export function addInvoiceApi(e) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.post(`${API_URL}add-invoice`, e);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getInvoiceList());
        toast("Invoice added sucessfully!", {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
      } else {
        console.log(resp.message);
        toast.error(resp.message, {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
        dispatch(hasError(resp.message || "Failed to add invoice"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      toast.error(error.response.data.message, {
        autoClose: 5000 // 5000 milliseconds = 5 seconds
      });
      dispatch(hasError(error.message || "An error occurred while adding the invoice"));
    }
  };
}

// Async Action to Delete Invoice
export function deleteInvoice(invoiceId) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const resp = await axios.delete(`${API_URL}delete-invoice/${invoiceId}`);
      console.log("API delete response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(deleteInvoiceSuccess({ id: invoiceId }));
        dispatch(getInvoiceList());
      } else {
        dispatch(deleteHasError(resp.message || "Failed to delete invoice"));
      }
    } catch (error) {
      console.error("Caught delete error:", error); // Log caught error
      dispatch(deleteHasError(error.message || "An error occurred while deleting the invoice"));
    }
  };
}
export function getInvoiceDetails(invoiceId) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}view-invoice/${invoiceId}`);
      console.log("API response:", resp);
      if (resp && resp.status === 200) {
        dispatch(getInvoiceDetailsSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch invoice details"));
      }
    } catch (error) {
      console.error("Caught details error:", error);
      dispatch(hasError(error.message || "An error occurred while fetching the invoice details"));
    }
  };
}
export function editInvoice(id, e, logo) {
  return async (dispatch) => {
    console.log(e)
    dispatch(startLoading());
    try {
      const resp = await axios.put(`${API_URL}update-invoice/${id}`, e, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (resp && resp.status === 200) {
        dispatch(getInvoiceList());
        toast("Invoice added sucessfully!", {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
      } else {
        console.log(resp.message);
        toast.error(resp.message, {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
        dispatch(hasError(resp.message || "Failed to add invoice"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while adding the invoice"));
    }
  };
}
