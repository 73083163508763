import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { forgetPassword } from "../redux/slices/user";
import { useDispatch } from "../redux/store";

export const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');

        try {
            dispatch(forgetPassword({
                email: email
            }));
        } catch (err) {
            // Handle error response
            setError('Failed to send password reset link. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="auth-page d-flex align-items-center justify-content-center py-3">
            <div className="container-fluid">
                <div className="form-wrapper bg-white rounded-4 mx-auto px-3 py-4 p-sm-5" style={{ maxWidth: '550px' }}>
                    <div className="heading-wrapper mb-4">
                        <div className="fs-3 fw-bold lh-sm mb-1">Forgot Password</div>
                        <div className="small text-muted">Kindly enter the Email Address tied to your account, we would help you to reset your password</div>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Email address<span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter your email"
                                autoComplete="off"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        {error && <div className="text-danger mb-3">{error}</div>}
                        {success && <div className="text-success mb-3">{success}</div>}
                        <div className="btn-wrapper d-grid gap-2 pt-2">
                            <Button type="submit" variant='primary' className="btn-custom text-decoration-none" disabled={loading}>
                                {loading ? 'Sending...' : 'Reset Password'}
                            </Button>
                            <Link to={'/login'} className="btn btn-outline-secondary btn-custom text-decoration-none">
                                <i className="bi bi-arrow-left me-2"></i> Back to Login
                            </Link>
                        </div>
                        <div className="fs-12 text-clr2 text-center mt-3 pt-1">
                            (After submitting please click on the link sent to your email.)
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};
