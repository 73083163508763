import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import API_URL from '../../Apiurl';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  error: false,
  success: '',
  customerList: [],
  editCustomerList: [],
  deleteLoading: false,
  deleteSuccess: '',
  deleteError: '',
  customerDetails: null
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false; // Clear previous errors
      state.success = '';  // Clear previous success messages
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.error("Error:", action.payload); // Log error
    },

    // GET CUSTOMER LIST SUCCESS
    getCustomerListSuccess(state, action) {
      state.isLoading = false;
      state.customerList = action.payload.data;
      state.error = false;
      state.success = 'Customer list fetched successfully!';
    },
    getCustomerListEditSuccess(state, action) {
      // console.log(action, state)
      state.editCustomerList = action.payload;
    },

    // START DELETE LOADING
    startDeleteLoading(state) {
      state.deleteLoading = true;
      state.deleteError = false; // Clear previous errors
      state.deleteSuccess = ''; // Clear previous success messages
    },

    // DELETE HAS ERROR
    deleteHasError(state, action) {
      state.deleteLoading = false;
      state.deleteError = action.payload;
      // console.error("Delete Error:", action.payload); // Log error
    },

    // DELETE CUSTOMER SUCCESS
    deleteCustomerSuccess(state, action) {
      state.deleteLoading = false;
      state.deleteSuccess = 'customer deleted successfully!';
      state.deleteError = false;

      // Optionally, remove the deleted customer from the customerList
      state.customerList = state.customerList?.filter(customer => customer.id !== action.payload.id);
    },
    getCustomerDetailsSuccess(state, action) {
      state.isLoading = false;
      state.customerDetails = action.payload.data;
      state.error = false;
      // console.log(action.payload.data);
    }  
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  getCustomerListSuccess,
  getCustomerListEditSuccess,
  startDeleteLoading,
  deleteHasError,
  deleteCustomerSuccess,
  getCustomerDetailsSuccess
} = slice.actions;

// Async Action to Get Customer List
export function getCustomerList() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}list-customer`);
      // console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        const options = resp.data.data.map(customer => ({
          value: customer.id,
          label: `${customer.first_name} ${customer.last_name}`
        }));
        dispatch(getCustomerListEditSuccess(options));
        dispatch(getCustomerListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch customer list"));
      }
    } catch (error) {
      // console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the customer list"));
    }
  };
}
export function getCustomerListId(id) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const cmp_id = localStorage.getItem("cmp_id");
      const resp = await axios.get(`${API_URL}company-customer/${id}/${cmp_id}`);
      // console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        const options = resp.data.data.map(customer => ({
          value: customer.id,
          label: `${customer.first_name} ${customer.last_name}`
        }));
        dispatch(getCustomerListEditSuccess(options));
        dispatch(getCustomerListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch customer list"));
      }
    } catch (error) {
      // console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the customer list"));
    }
  };
}

// Async Action to Add Customer
export function addCustomer(e, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.post(`${API_URL}add-customer`, e);
      // console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        // await dispatch(getCustomerListSuccess(resp.data));
        const cmp_id = localStorage.getItem("cmp_id");
        await dispatch(getCustomerListId(cmp_id));
        toast("Customer added sucessfully!", {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
        await navigate('/clients-list');
      } else {
        toast.error("Failed to add customer: " + (resp.data.message || "Unknown error"), {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
        dispatch(hasError(resp.message || "Failed to add customer"));
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 5000 // 5000 milliseconds = 5 seconds
      });
      // console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while adding the customer"));
    }
  };
}

// Async Action to Delete customer
export function deleteCustomer(customerId) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const resp = await axios.delete(`${API_URL}delete-customer/${customerId}`);
      // console.log("API delete response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(deleteCustomerSuccess({ id: customerId }));
      } else {
        dispatch(deleteHasError(resp.message || "Failed to delete customer"));
      }
    } catch (error) {
      // console.error("Caught delete error:", error); // Log caught error
      dispatch(deleteHasError(error.message || "An error occurred while deleting the Customer"));
    }
  };
}
export function getCustomerDetails(customerId) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}view-customer/${customerId}`);
      // console.log("API response:", resp);
      if (resp && resp.status === 200) {
        // console.log(resp.data);
        dispatch(getCustomerDetailsSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch Customer details"));
      }
    } catch (error) {
      // console.error("Caught details error:", error);
      dispatch(hasError(error.message || "An error occurred while fetching the Customer details"));
    }
  };
}
export function editCustomer(id, e, navigate) {
  return async (dispatch) => {
    // console.log(e)
    dispatch(startLoading());
    try {
      const resp = await axios.put(`${API_URL}update-customer/${id}`, e, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      // console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        toast("Customer updated sucessfully!", {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
        const cmp_id = localStorage.getItem("cmp_id");
        await dispatch(getCustomerListId(e.cmp_id));
        // navigate('/clients-list');
        await navigate('/clients-list');
      } else {
        toast.error("Failed to add customer: " + (resp.data.message || "Unknown error"), {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
        dispatch(hasError(resp.message || "Failed to add Customer"));
      }
    } catch (error) {
      // console.error("Caught error:", error); // Log caught error
      toast.error(error.response.data.message, {
        autoClose: 5000 // 5000 milliseconds = 5 seconds
      });
      dispatch(hasError(error.message || "An error occurred while adding the Customer"));
    }
  };
}
export function editCustomerAccount(id, e) {
  return async (dispatch) => {
    // console.log(e)
    dispatch(startLoading());
    try {
      const resp = await axios.put(`${API_URL}update-customer/${id}`, e, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      // console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        toast("Customer updated sucessfully!", {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
        await dispatch(getCustomerDetails(id));
        // navigate('/clients-list');
        
      } else {
        toast.error("Failed to add customer: " + (resp.data.message || "Unknown error"), {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
        dispatch(hasError(resp.message || "Failed to add Customer"));
      }
    } catch (error) {
      // console.error("Caught error:", error); // Log caught error
      toast.error(error.response.data.message, {
        autoClose: 5000 // 5000 milliseconds = 5 seconds
      });
      dispatch(hasError(error.message || "An error occurred while adding the Customer"));
    }
  };
}
