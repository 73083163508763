import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { NoDataComponent } from "./NoDataComponent";
import SearchIcon from "../assets/search.svg";
import { Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getInvoiceList,
  getInvoiceDetails,
  deleteInvoice,
} from "../redux/slices/invoice";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Form, Button } from "react-bootstrap";
import { addInvoiceApi } from "../redux/slices/invoice";
import { getCompanyList } from "../redux/slices/company";
import {
  getProjectFullDetails,
  getProjectListById,
} from "../redux/slices/project";
export const InvoiceListing = () => {
  const navigate = useNavigate();
  const { invoiceList, invoiceDetails } = useSelector((state) => state.invoice);
  const dispatch = useDispatch();
  const [invoiceId, setInvoiceId] = useState(0);
  const [showPreviewInvoice, setShowPreviewInvoice] = useState(false);
  const [showEditInvoice, setShowEditInvoice] = useState(false);
  const [show, setShow] = useState(false);
  const [expenseId, setExpenseId] = useState(0);
  const [cmp_id, setCompany] = useState("");
  const [subTotal, setSubTotal] = useState(50);
  const [projectData, setProjectData] = useState({});
  const [company, setCompanyData] = useState({});
  const { projectDetails, projectFullDetails, projectList } = useSelector(
    (state) => state.project
  );
  const [companyId, setCompanyId] = useState(0);
  const { companyList } = useSelector((state) => state.company);
  useEffect(() => {
    dispatch(getCompanyList());
  }, [dispatch]);

  const [formData, setFormData] = useState({
    prj_id: "",
    cmp_id: "",
    invoice_date: "",
    total_price: subTotal,
    invoice_from: "",
    invoice_to: "",
    order_no: "",
    adjuster: "",
    shipped_by: "",
    shipping_price: 0,
    status: "Unpaid",
    invoicedetails: [],
    emp_id: localStorage.getItem("lead_emp_id"),
    expenses_id: expenseId,
  });
  const [formDataEdit, setFormDataEdit] = useState({
    prj_id: 0,
    cmp_id: 0,
    invoice_date: "",
    total_price: subTotal,
    invoice_from: "",
    invoice_to: "",
    order_no: "",
    adjuster: "",
    shipped_by: "",
    shipping_price: 0,
    status: "Unpaid",
    emp_id: localStorage.getItem("lead_emp_id"),
    invoicedetails: [],
    expenses_id: expenseId,
  });
  const [invoice, setInvoice] = useState([
    {
      date: "2021-10-11",
      expenses: "",
      description: "",
      price: 50,
      expenses_id: expenseId,
    },
  ]);
  const handleInputInvoiceChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleEditInputInvoiceChange = (e) => {
    const { name, value } = e.target;
    setFormDataEdit({
      ...formDataEdit,
      [name]: value,
    });
  };
  const openPreviewInvoiceModal = (id) => {
    setShowPreviewInvoice(true);
    setInvoiceId(id);
    dispatch(getInvoiceDetails(id));
  };
  useEffect(() => {
    if (invoiceDetails) {
      setFormDataEdit(invoiceDetails);
      setCompanyData(invoiceDetails?.company);
      dispatch(
        getProjectListById(
          localStorage.getItem("lead_emp_id"),
          invoiceDetails.cmp_id
        )
      );
      const formattedInvoiceDate = invoiceDetails?.invoice_date.split(" ")[0];
      setFormDataEdit({
        ...invoiceDetails,
        invoice_date: formattedInvoiceDate,
      });
      setProjectData(invoiceDetails?.project);
    }
  }, [invoiceDetails]);

  const openEditInvoiceModal = async (id) => {
    // console.log(id);
    // return
    await setInvoiceId(id);
    await dispatch(getInvoiceDetails(id));
    // await setFormDataEdit(invoiceDetails);
    // console.log(invoiceDetails, "invoiceDetails");
    // await setCompany(invoiceDetails.cmp_id);
    await setCompanyData(invoiceDetails?.company);
    await setInvoice(invoiceDetails?.invoice_details);
    await setShowEditInvoice(true);
  };
  const closeEditInvoiceModal = () => {
    setShowEditInvoice(false);
    setInvoiceId(0);
  };

  const closePreviewInvoiceModal = () => {
    setShowPreviewInvoice(false);
    setInvoiceId(0);
  };

  const handleRemoveInvoice = (index) => {
    console.log(index);
    setInvoice(invoice.filter((_, i) => i !== index));
    // if (id) {
    //   dispatch(deleteExpenseDetail(id));
    // }
    setSubTotal(invoice[index].price + 50);
    if (index === 0) {
      setInvoice([
        {
          date: "2021-10-11",
          expenses: "",
          description: "",
          price: 50,
          expenses_id: expenseId,
        },
      ]);
      setSubTotal(50);
    }
  };
  const handleAddInvoice = () => {
    setInvoice([
      ...invoice,
      {
        date: "2021-10-11",
        expenses: "",
        description: "",
        price: 50,
        expenses_id: expenseId,
      },
    ]);
    setSubTotal(subTotal + 50);
  };
  const handleInputChangeInvoice = (index, field, value) => {
    console.log(index, field, value);
    const newInvoice = invoice.map((invoice, i) =>
      i === index ? { ...invoice, [field]: value } : invoice
    );
    console.log(newInvoice);
    const newInvoices = [...invoice];
    newInvoices[index][field] = value;
    if (field === "price" || field === "quantity") {
      const newSubtotal = newInvoices.reduce((acc, invoice) => {
        // const quantity = parseFloat(invoice.quantity) || 0;
        const price = parseFloat(invoice.price) || 0;
        return acc + price;
      }, 0);
      setSubTotal(newSubtotal);
    }

    setInvoice(newInvoice);
    setFormData({
      ...formData,
      invoicedetails: invoice,
      expenses_id: expenseId,
    });
    console.log(formData);
  };
  const addInvoice = async (e) => {
    console.log(invoice);
    e.preventDefault();
    if (invoiceId) {
      await dispatch(getInvoiceDetails(invoiceId, formDataEdit));
      setInvoice(0);
    } else {
      await dispatch(addInvoiceApi(formData));
    }
  };
  console.log(invoiceDetails);
  useEffect(() => {
    // const id = localStorage.getItem("prj_id");
    dispatch(getInvoiceList());
  }, [dispatch]);

  const columns = [
    // {
    //   name: "ID",
    //   selector: (row) => row.id,
    //   sortable: true,
    //   width: "100px",
    // },
    {
      name: "Invoice Number",
      selector: (row) => row.invoice_no,
      sortable: true,
    },
    {
      name: "Total Price",
      selector: (row) => row.total_price,
      sortable: true,
      maxWidth: "120px",
    },
    {
      name: "Invoice From",
      selector: (row) => row.invoice_from,
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (row) => row.company_name,
      sortable: true,
    },
    {
      name: "Project Name",
      selector: (row) => row.project_name,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="action-wrapper">
          <button
            className="btn btn-danger btn-sm"
            title="Delete"
            data-bs-toggle="modal"
            data-bs-target="#deleteCompany"
            onClick={() => setCompanyId(row.id)}
          >
            <i className="bi bi-trash3 fs-14"></i>
          </button>
          <button
            className="fs-14 btn btn-link border-0 text-decoration-none text-success text-start lh-sm p-0"
            onClick={() => openPreviewInvoiceModal(row.id)}
          >
            Preview Invoice/Expense
          </button>
          {/* <button
              className="btn btn-warning btn-sm"
              title="Edit"
              onClick={() => openEditInvoiceModal(row.id)}
            >
              <i className="bi bi-pencil-fill fs-14"></i>
            </button> */}
        </div>
      ),
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.status,
    //   sortable: true,
    //   cell: (row) => (
    //     <Badge
    //       pill
    //       bg={`${
    //         row.status === "Paid"
    //           ? "success"
    //           : "no-status" && row.status === "Pending"
    //           ? "danger"
    //           : "no-status"
    //       }`}
    //       style={{ width: "60px" }}
    //     >
    //       {row.status}
    //     </Badge>
    //   ),
    // },
  ];

  // Style Customization for Data Table
  const customStyles = {
    rows: {
      style: {
        fontSize: "14px",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "600",
        padding: "5px 12px",
        color: "#fff",
        backgroundColor: "var(--bs-primary)",
        minHeight: "45px",
      },
    },
    cells: {
      style: {
        color: "#3f474f",
        fontSize: "14px",
        padding: "5px 12px",
        minHeight: "40px",
      },
    },
  };

  const [prj_id, setProjectId] = useState("");

  const downloadPDF = () => {
    const input = document.getElementById("invoiceModalContent");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("invoice.pdf");
    });
  };

  const printPDF = () => {
    const input = document.getElementById("invoiceModalContent");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

      // Open the PDF in a new window
      const pdfBlob = pdf.output("blob");
      const pdfURL = URL.createObjectURL(pdfBlob);
      window.open(pdfURL, "_blank").print();
    });
  };
  const closeAddInvoicModal = () => setShow(false);
  const openAddInvoicModal = (row) => {
    console.log(row);
    setShow(true);
    setExpenseId(row.id);
  };
  return (
    <div className="InvoiceListing-page py-3">
      <div className="container-fluid">
        <div className="heading-wrapper d-flex flex-wrap align-items-center justify-content-between gap-3 mb-3">
          <div className="fs-4 fw-bold lh-1">Invoice List</div>
          <div className="d-flex flex-wrap gap-2">
            <button className="btn btn-primary" onClick={openAddInvoicModal}>
              <i className="bi bi-person-plus-fill"></i> Add Invoice
            </button>
          </div>
        </div>
        <div className="card border-0 rounded-3 shadow-sm p-3 invoice-wrapper">
          <div className="searchfield-wrapper position-relative fs-6 ms-auto mb-3">
            <input
              type="search"
              className="form-control ms-auto"
              placeholder="Search cases here..."
              autoComplete="off"
            />
            <img
              src={SearchIcon}
              alt="Search Icon"
              className="position-absolute top-50 end-0 translate-middle-y pe-3"
            />
          </div>
          <div className="table-responsive table-custom-wrapper">
            <DataTable
              columns={columns}
              data={invoiceList}
              pagination // Enable pagination
              paginationPerPage={10} // Number of items per page
              highlightOnHover
              noDataComponent={<NoDataComponent />}
              customStyles={customStyles}
              dense
              responsive
            />
          </div>
        </div>
      </div>
      <Modal
        show={showPreviewInvoice}
        onHide={closePreviewInvoiceModal}
        centered
        size="xl"
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Header closeButton className="bg-primary bg-opacity-10">
          <Modal.Title className="fs-6 text-primary lh-sm">
            <i className="bi bi-receipt-cutoff"></i> Preview Invoice/Expense
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="info-card-wrapper px-xl-5 py-4"
          id="invoiceModalContent"
        >
          <div className="address-section mb-4">
            <div className="row g-3">
              <div className="col-sm-6 col-xl-8">
                <address className="fs-14 text-capitalize m-0">
                  <p className="fw-semibold mb-0">
                    {invoiceDetails?.company?.name}
                  </p>
                  <p className="mb-0">{invoiceDetails?.company?.address1}</p>
                  <p className="mb-0">{invoiceDetails?.company?.city}</p>
                  <p className="mb-0">
                    Phone: {invoiceDetails?.company?.phone}
                  </p>
                  <p className="mb-0">Fax: {invoiceDetails?.company?.fax}</p>
                  <p className="mb-0">
                    URL:{" "}
                    <span className="text-lowercase">
                      {invoiceDetails?.company?.url}
                    </span>
                  </p>
                </address>
              </div>
              <div className="col-sm-6 col-xl-4">
                <address className="fs-14 text-capitalize m-0">
                  <p className="fw-semibold mb-0">
                    {invoiceDetails?.project?.name}
                  </p>
                  <p className="mb-0">3790 EL Camino Real Suite 225</p>
                  <p className="mb-0">Palo Alto Office, CA 94306</p>
                  <p className="mb-0">Phone: (887)700-OIPL(6474)</p>
                  <p className="mb-0">Fax: (808) 791-8377</p>
                </address>
              </div>
            </div>
          </div>
          <div className="invoice-info-wrapper">
            <div className="row g-3 mb-4">
              <div className="col-lg-6 col-xl-8">
                <div className="text-primary fs-14 text-capitalize m-0">
                  <div className="d-flex gap-1 mb-0">
                    <span className="label fw-semibold">Invoice Number:</span>
                    <span className="label">{invoiceDetails?.invoice_no}</span>
                  </div>
                  <div className="d-flex gap-1 mb-0">
                    <span className="label fw-semibold">Invoice Range:</span>
                    <span className="label">
                      {invoiceDetails?.invoice_from} -{" "}
                      {invoiceDetails?.invoice_to}
                    </span>
                  </div>
                  <div className="d-flex gap-1 mb-0">
                    <span className="label fw-semibold">Order No:</span>
                    <span className="label">{invoiceDetails?.order_no}</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-4">
                <div className="text-primary fs-14 text-capitalize m-0">
                  <div className="d-flex gap-1 mb-0">
                    <span className="label fw-semibold">Adjuster:</span>
                    <span className="label">{invoiceDetails?.adjuster}</span>
                  </div>
                  <div className="d-flex gap-1 mb-0">
                    <span className="label fw-semibold">Shipped By:</span>
                    <span className="label">{invoiceDetails?.shipped_by}</span>
                  </div>
                  <div className="d-flex gap-1 mb-0">
                    <span className="label fw-semibold">Order Date:</span>
                    <span className="label">{invoiceDetails?.order_date}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive table-custom-wrapper border-0 rounded-0 fs-14">
              <table className="table table-bordered border-secondary border-opacity-25 align-middle m-0">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="fw-medium h-auto"
                      style={{ minWidth: "170px" }}
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="fw-medium h-auto"
                      style={{ minWidth: "170px" }}
                    >
                      Expenses
                    </th>
                    <th
                      scope="col"
                      className="fw-medium h-auto"
                      style={{ minWidth: "170px" }}
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="fw-medium h-auto"
                      style={{ minWidth: "170px" }}
                    >
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* {[...Array(3)].map((elementInArray, index) => (
                    <tr key={index}>
                      <td>11/03/2011</td>
                      <td>Miles</td>
                      <td>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </td>
                      <td>45.00</td>
                    </tr>
                  ))} */}
                  {invoiceDetails?.invoice_details?.map((e) => {
                    return (
                      <tr key={e.id}>
                        <td>{e.date}</td>
                        <td>{e.expenses}</td>
                        <td>{e.description}</td>
                        <td>{e.price}</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={"3"} className="text-primary">
                      <p className="m-0">It was a Pleasure to work with you.</p>
                      <p className="m-0">
                        An Itemized invoice can be provided at client's request.
                      </p>
                      <p className="m-0">All services are net 30*</p>
                    </td>
                    <td className="text-primary text-end mx-3">
                      <p className="d-flex align-items-center gap-1 text-nowrap fw-medium m-0">
                        Subtotal($):{" "}
                        <span className="fs-6 fw-bold">
                          {invoiceDetails?.total_price}
                        </span>
                      </p>
                      <p className="d-flex align-items-center gap-1 text-nowrap fw-medium m-0">
                        Shipping/Handling($):{" "}
                        <span className="fs-6 fw-bold">
                          {invoiceDetails?.shipping_price}
                        </span>
                      </p>
                      <hr className="border-secondary mx-n2 my-1" />
                      <p className="d-flex align-items-center gap-1 text-nowrap fw-medium m-0">
                        Total Amount due($):{" "}
                        <span className="fs-5 fw-bold">
                          {invoiceDetails?.amount_due}
                        </span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="btn-wrapper d-flex justify-content-center gap-2 mt-3 mb-xl-3">
              <Link
                to="mailto:recipient@example.com"
                className="btn btn-warning btn-sm px-3"
              >
                <i className="bi bi-envelope-at-fill"></i> Email
              </Link>
              <Link
                download="dummy-user.jpeg"
                className="btn btn-success btn-sm px-3"
                onClick={downloadPDF}
              >
                <i className="bi bi-download"></i> Download PDF
              </Link>
              <Link
                variant="info"
                className="btn btn-info btn-sm px-3"
                onClick={printPDF}
              >
                <i className="bi bi-printer"></i> Print
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showEditInvoice}
        onHide={closeEditInvoiceModal}
        centered
        size="xl"
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Header closeButton className="bg-primary bg-opacity-10">
          <Modal.Title className="fs-6 text-primary lh-sm">
            <i className="bi bi-receipt-cutoff"></i> Edit Invoice/Expense
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="info-card-wrapper">
          <div className="form-section bg-secondary bg-opacity-10 p-3 p-lg-4">
            <form
              action=""
              className="w-100 mx-auto"
              style={{ maxWidth: "700px" }}
            >
              <Form.Group className="mb-3" controlId="BillerName">
                <Form.Label>
                  Biller Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  value={formDataEdit?.cmp_id}
                  onChange={(e) => {
                    const selectedIndex = e.target.selectedIndex;
                    const selectedCompany = companyList[selectedIndex - 1]; // minus 1 to account for the default option

                    setCompany(selectedCompany.id); // Ensure setCompany updates cmp_id correctly
                    setCompanyData(selectedCompany);

                    // Log the selected company to verify
                    console.log("Selected Company:", selectedCompany);

                    // Use the selected company's ID to update formData
                    setFormDataEdit({
                      ...formData,
                      cmp_id: selectedCompany.id,
                    });

                    dispatch(
                      getProjectListById(
                        localStorage.getItem("lead_emp_id"),
                        selectedCompany.id
                      )
                    );
                  }}
                  required
                >
                  <option selected disabled value="">
                    Select Company
                  </option>
                  {companyList?.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="CaseName">
                <Form.Label>
                  Case Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  value={formDataEdit?.prj_id}
                  onChange={(e) => {
                    const selectedIndex = e.target.selectedIndex;
                    const selected = projectList[selectedIndex - 1]; // minus 1 to account for the default option

                    setProjectData(selected);
                    setProjectId(e.target.value);

                    console.log("Selected Project:", selected);
                    console.log("Selected Project ID:", selected.id);

                    // Use the selected project's ID to update formData
                    setFormDataEdit({
                      ...formData,
                      prj_id: selected.id,
                    });

                    console.log("Form Data after update:", {
                      ...formData,
                      prj_id: selected.id,
                    });
                  }}
                  required
                >
                  <option selected disabled value="">
                    Select case name
                  </option>
                  {projectList?.map((e) => {
                    return <option value={e.id}>{e.name}</option>;
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="BillingDate">
                <Form.Label>
                  Date<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  value={formDataEdit?.invoice_date}
                  onChange={handleEditInputInvoiceChange}
                  name="invoice_date"
                  type="date"
                  defaultValue="2021/10/11"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="DateRange">
                <Form.Label>
                  Date Range<span className="text-danger">*</span>
                </Form.Label>
                <div className="row g-3">
                  <div className="col-sm-6">
                    <Form.Control
                      type="date"
                      value={formDataEdit?.invoice_from}
                      onChange={handleEditInputInvoiceChange}
                      name="invoice_from"
                      defaultValue="2021/10/11"
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <Form.Control
                      type="date"
                      value={formDataEdit?.invoice_to}
                      onChange={handleEditInputInvoiceChange}
                      name="invoice_to"
                      defaultValue="2021/11/11"
                      required
                    />
                  </div>
                </div>
              </Form.Group>
              <div className="btn-wrapper text-center pt-2">
                <Button type="submit" variant="primary" className="px-4 py-2">
                  Generate invoice
                </Button>
              </div>
            </form>
          </div>
          <div className="address-section my-4">
            <div className="row g-3">
              <div className="col-sm-6 col-xl-8">
                <address className="fs-14 text-capitalize m-0">
                  <p className="fw-semibold mb-0">{company?.name}</p>
                  <p className="mb-0">{company?.address1}</p>
                  <p className="mb-0">
                    {company?.address2 +
                      " " +
                      company?.cnt_iso +
                      " " +
                      company?.zip}
                  </p>
                  <p className="mb-0">Phone: {company?.phone}</p>
                  <p className="mb-0">Fax: {company?.fax}</p>
                  <p className="mb-0">
                    URL: <span className="text-lowercase">{company?.url}</span>
                  </p>
                </address>
              </div>
              <div className="col-sm-6 col-xl-4">
                <address className="fs-14 text-capitalize m-0">
                  <p className="fw-semibold mb-0">{projectData?.name}</p>
                  <p className="mb-0">3790 EL Camino Real Suite 225</p>
                  <p className="mb-0">Palo Alto Office, CA 94306</p>
                  <p className="mb-0">Phone: (887)700-OIPL(6474)</p>
                  <p className="mb-0">Fax: (808) 791-8377</p>
                  <p className="mb-0">
                    URL:{" "}
                    <span className="text-lowercase">www.carolinacas.com</span>
                  </p>
                </address>
              </div>
            </div>
          </div>

          <div className="table-content-wrapper">
            <div className="bg-secondary bg-opacity-10 mb-3 p-3">
              <div className="row g-3">
                <div className="col-lg-4">
                  <Form.Group controlId="BillingDate">
                    <Form.Label>
                      Order No:<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={formDataEdit?.order_no}
                      onChange={handleEditInputInvoiceChange}
                      name="order_no"
                      defaultValue="123"
                      autoComplete="off"
                      required
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group controlId="BillingDate">
                    <Form.Label>
                      Adjuster:<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue="Test"
                      value={formDataEdit?.adjuster}
                      onChange={handleEditInputInvoiceChange}
                      name="adjuster"
                      autoComplete="off"
                      required
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group controlId="BillingDate">
                    <Form.Label>
                      Shipped By:<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={formDataEdit?.shipped_by}
                      onChange={handleEditInputInvoiceChange}
                      name="shipped_by"
                      defaultValue="Test"
                      autoComplete="off"
                      required
                    />
                  </Form.Group>
                </div>
              </div>
            </div>

            <div className="table-responsive table-custom-wrapper border-0 rounded-0 fs-14">
              <table className="table table-bordered border-secondary border-opacity-25 align-middle m-0">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Expenses
                    </th>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Price
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {invoice?.map((invoices, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="date"
                          className="form-control"
                          id="expDate"
                          name="expDate"
                          value={invoices?.date}
                          onChange={(e) =>
                            handleInputChangeInvoice(
                              index,
                              "date",
                              e.target.value
                            )
                          }
                          defaultValue="2021-10-11"
                        />
                      </td>
                      <td>
                        <select
                          name="expType"
                          id="expType"
                          className="form-select"
                          value={invoices.expenses}
                          onChange={(e) =>
                            handleInputChangeInvoice(
                              index,
                              "expenses",
                              e.target.value
                            )
                          }
                        >
                          <option selected disabled value="">
                            Select Expenses
                          </option>
                          <option value="Miles">Miles</option>
                          <option value="Lodging">Lodging</option>
                          <option value="Meals">Meals</option>
                          <option value="Hour worked">Hour worked</option>
                          <option value="Other">Other</option>
                        </select>
                      </td>
                      <td className="text-nowrap text-capitalize">
                        <textarea
                          rows={"2"}
                          name="expDescription"
                          id="expDescription"
                          className="form-control"
                          style={{ height: "45px", resize: "none" }}
                          value={invoices?.description}
                          onChange={(e) =>
                            handleInputChangeInvoice(
                              index,
                              "description",
                              e.target.value
                            )
                          }
                        ></textarea>
                      </td>
                      <td className="text-nowrap text-capitalize">
                        <input
                          type="text"
                          name="expPrice"
                          id="expPrice"
                          className="form-control"
                          defaultValue={"50"}
                          autoComplete="off"
                          value={invoices?.price}
                          // autoComplete="off"
                          onChange={(e) =>
                            handleInputChangeInvoice(
                              index,
                              "price",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <div className="action-btn d-flex gap-2">
                          <Button
                            variant="success"
                            title="Add"
                            onClick={handleAddInvoice}
                          >
                            <i className="bi bi-plus-lg"></i>
                          </Button>
                          <Button
                            variant="danger"
                            title="Remove"
                            onClick={() => handleRemoveInvoice(index)}
                          >
                            <i className="bi bi-dash-lg"></i>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td colSpan={"3"} className="text-primary">
                      <p className="m-0">It was a Pleasure to work with you.</p>
                      <p className="m-0">
                        An Itemized invoice can be provided at client's request.
                      </p>
                      <p className="m-0">All services are net 30*</p>
                    </td>
                    <td colSpan={"2"} className="text-primary text-end mx-3">
                      <p className="d-flex align-items-center justify-content-end gap-1 text-nowrap m-0">
                        Subtotal($):{" "}
                        <span className="fs-6 fw-bold">{subTotal}</span>
                      </p>
                      <p className="d-flex align-items-center justify-content-end gap-1 text-nowrap m-0">
                        Shipping/Handling($):{" "}
                        <span className="fs-6 fw-bold">0</span>
                      </p>
                      <hr className="border-secondary mx-n2 my-1" />
                      <p className="d-flex align-items-center justify-content-end gap-1 text-nowrap m-0">
                        Total Amount due($):{" "}
                        <span className="fs-5 fw-bold">{subTotal + 0}</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="text-center mt-3 mb-2">
              <Button
                variant="primary"
                className="px-4 py-2"
                onClick={addInvoice}
              >
                Add Invoice
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Add Invoice/Expense Modal Code */}
      <Modal
        show={show}
        onHide={closeAddInvoicModal}
        centered
        size="xl"
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Header closeButton className="bg-primary bg-opacity-10">
          <Modal.Title className="fs-6 text-primary lh-sm">
            <i className="bi bi-receipt-cutoff"></i> Add Invoice/Expense
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="info-card-wrapper">
        <Form
              onSubmit={addInvoice}
              className="w-100 mx-auto"
              // style={{ maxWidth: "700px" }}
            >
          <div className="form-section bg-secondary bg-opacity-10 p-3 p-lg-4">
            
              <Form.Group className="mb-3" controlId="BillerName">
                <Form.Label>
                  Biller Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  value={cmp_id}
                  onChange={(e) => {
                    const selectedIndex = e.target.selectedIndex;
                    const selectedCompany = companyList[selectedIndex - 1]; // minus 1 to account for the default option

                    setCompany(selectedCompany.id); // Ensure setCompany updates cmp_id correctly
                    setCompanyData(selectedCompany);

                    // Log the selected company to verify
                    console.log("Selected Company:", selectedCompany);

                    // Use the selected company's ID to update formData
                    setFormData({
                      ...formData,
                      cmp_id: selectedCompany.id,
                    });

                    dispatch(
                      getProjectListById(
                        localStorage.getItem("lead_emp_id"),
                        selectedCompany.id
                      )
                    );
                  }}
                  required
                >
                  <option selected disabled value="">
                    Select Company
                  </option>
                  {companyList?.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company?.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select a company.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="CaseName">
                <Form.Label>
                  Case Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  value={prj_id}
                  onChange={(e) => {
                    const selectedIndex = e.target.selectedIndex;
                    const selected = projectList[selectedIndex - 1]; // minus 1 to account for the default option

                    setProjectData(selected);
                    setProjectId(e.target.value);

                    console.log("Selected Project:", selected);
                    console.log("Selected Project ID:", selected.id);

                    // Use the selected project's ID to update formData
                    setFormData({
                      ...formData,
                      prj_id: selected.id,
                    });

                    console.log("Form Data after update:", {
                      ...formData,
                      prj_id: selected.id,
                    });
                  }}
                  required
                >
                  <option selected disabled value="">
                    Select Case Name
                  </option>
                  {projectList?.map((e) => {
                    return <option value={e.id}>{e.name}</option>;
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select a case name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="BillingDate">
                <Form.Label>
                  Date<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  value={formData?.invoice_date}
                  onChange={handleInputInvoiceChange}
                  name="invoice_date"
                  type="date"
                  defaultValue="2021/10/11"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please select a date.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="DateRange">
                <Form.Label>
                  Date Range<span className="text-danger">*</span>
                </Form.Label>
                <div className="row g-3">
                  <div className="col-sm-6">
                    <Form.Control
                      type="date"
                      value={formData?.invoice_from}
                      onChange={handleInputInvoiceChange}
                      name="invoice_from"
                      defaultValue="2021/10/11"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please select the start date of the range.
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control
                      type="date"
                      value={formData?.invoice_to}
                      onChange={handleInputInvoiceChange}
                      name="invoice_to"
                      defaultValue="2021/11/11"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please select the end date of the range.
                    </Form.Control.Feedback>
                  </div>
                </div>
              </Form.Group>

              {/* <div className="btn-wrapper text-center pt-2">
                <Button type="submit" variant="primary" className="px-4 py-2">
                  Generate Invoice
                </Button>
              </div> */}
            
          </div>
          <div className="address-section my-4">
            <div className="row g-3">
              <div className="col-sm-6 col-xl-8">
                <address className="fs-14 text-capitalize m-0">
                  <p className="fw-semibold mb-0">{company?.name}</p>
                  <p className="mb-0">{company?.address1}</p>
                  <p className="mb-0">
                    {company?.address2 +
                      " " +
                      company?.cnt_iso +
                      " " +
                      company?.zip}
                  </p>
                  <p className="mb-0">Phone: {company?.phone}</p>
                  <p className="mb-0">Fax: {company?.fax}</p>
                  <p className="mb-0">
                    URL: <span className="text-lowercase">{company?.url}</span>
                  </p>
                </address>
              </div>
              <div className="col-sm-6 col-xl-4">
                <address className="fs-14 text-capitalize m-0">
                  <p className="fw-semibold mb-0">{projectData?.name}</p>
                  <p className="mb-0">3790 EL Camino Real Suite 225</p>
                  <p className="mb-0">Palo Alto Office, CA 94306</p>
                  <p className="mb-0">Phone: (887)700-OIPL(6474)</p>
                  <p className="mb-0">Fax: (808) 791-8377</p>
                  <p className="mb-0">
                    URL:{" "}
                    <span className="text-lowercase">www.carolinacas.com</span>
                  </p>
                </address>
              </div>
            </div>
          </div>

          <div className="table-content-wrapper">
            <div className="bg-secondary bg-opacity-10 mb-3 p-3">
              <div className="row g-3">
                <div className="col-lg-4">
                  <Form.Group controlId="BillingDate">
                    <Form.Label>
                      Order No:<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={formData?.order_no}
                      onChange={handleInputInvoiceChange}
                      name="order_no"
                      defaultValue="123"
                      autoComplete="off"
                      required
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group controlId="BillingDate">
                    <Form.Label>
                      Adjuster:<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue="Test"
                      value={formData?.adjuster}
                      onChange={handleInputInvoiceChange}
                      name="adjuster"
                      autoComplete="off"
                      required
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group controlId="BillingDate">
                    <Form.Label>
                      Shipped By:<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={formData?.shipped_by}
                      onChange={handleInputInvoiceChange}
                      name="shipped_by"
                      defaultValue="Test"
                      autoComplete="off"
                      required
                    />
                  </Form.Group>
                </div>
              </div>
            </div>

            <div className="table-responsive table-custom-wrapper border-0 rounded-0 fs-14">
              <table className="table table-bordered border-secondary border-opacity-25 align-middle m-0">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Expenses
                    </th>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Price
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {invoice?.map((invoices, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="date"
                          className="form-control"
                          id="expDate"
                          name="expDate"
                          value={invoices?.date}
                          onChange={(e) =>
                            handleInputChangeInvoice(
                              index,
                              "date",
                              e.target.value
                            )
                          }
                          defaultValue="2021-10-11"
                          required
                        />
                      </td>
                      <td>
                        <select
                          name="expType"
                          id="expType"
                          className="form-select"
                          value={invoices.expenses}
                          onChange={(e) =>
                            handleInputChangeInvoice(
                              index,
                              "expenses",
                              e.target.value
                            )
                          }
                          required
                        >
                          <option selected disabled value="">
                            Select Expenses
                          </option>
                          <option value="Miles">Miles</option>
                          <option value="Lodging">Lodging</option>
                          <option value="Meals">Meals</option>
                          <option value="Hour worked">Hour worked</option>
                          <option value="Other">Other</option>
                        </select>
                      </td>
                      <td className="text-nowrap text-capitalize">
                        <textarea
                          rows={"2"}
                          name="expDescription"
                          id="expDescription"
                          className="form-control"
                          style={{ height: "45px", resize: "none" }}
                          value={invoices?.description}
                          onChange={(e) =>
                            handleInputChangeInvoice(
                              index,
                              "description",
                              e.target.value
                            )
                          }
                          required
                        ></textarea>
                      </td>
                      <td className="text-nowrap text-capitalize">
                        <input
                          type="text"
                          name="expPrice"
                          id="expPrice"
                          className="form-control"
                          defaultValue={"50"}
                          autoComplete="off"
                          value={invoices?.price}
                          // autoComplete="off"
                          required
                          onChange={(e) =>
                            handleInputChangeInvoice(
                              index,
                              "price",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <div className="action-btn d-flex gap-2">
                          <Button
                            variant="success"
                            title="Add"
                            onClick={handleAddInvoice}
                          >
                            <i className="bi bi-plus-lg"></i>
                          </Button>
                          <Button
                            variant="danger"
                            title="Remove"
                            onClick={() => handleRemoveInvoice(index)}
                          >
                            <i className="bi bi-dash-lg"></i>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td colSpan={"3"} className="text-primary">
                      <p className="m-0">It was a Pleasure to work with you.</p>
                      <p className="m-0">
                        An Itemized invoice can be provided at client's request.
                      </p>
                      <p className="m-0">All services are net 30*</p>
                    </td>
                    <td colSpan={"2"} className="text-primary text-end mx-3">
                      <p className="d-flex align-items-center justify-content-end gap-1 text-nowrap m-0">
                        Subtotal($):{" "}
                        <span className="fs-6 fw-bold">{subTotal}</span>
                      </p>
                      <p className="d-flex align-items-center justify-content-end gap-1 text-nowrap m-0">
                        Shipping/Handling($):{" "}
                        <span className="fs-6 fw-bold">0</span>
                      </p>
                      <hr className="border-secondary mx-n2 my-1" />
                      <p className="d-flex align-items-center justify-content-end gap-1 text-nowrap m-0">
                        Total Amount due($):{" "}
                        <span className="fs-5 fw-bold">{subTotal + 0}</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="text-center mt-3 mb-2">
              <Button
                variant="primary"
                className="px-4 py-2"
                type="submit"
              >
                Add Invoicess
              </Button>
            </div>
          </div>
          </Form>
        </Modal.Body>
      </Modal>
      <div
        className="modal fade"
        id="deleteCompany"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="deleteCompanyLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-4">
            <div className="modal-body p-md-4 p-lg-5 text-center">
              <div className="fs-18 fw-semibold lh-sm mb-4">
                Are you sure you want to delete this Invoice?
              </div>
              <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3">
                <button
                  type="button"
                  className="btn btn-danger px-4"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary px-4"
                  data-bs-dismiss="modal"
                  onClick={() => dispatch(deleteInvoice(companyId))}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
