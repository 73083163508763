import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../assets/search.svg";
import { useSelector, useDispatch } from "react-redux";
import { getCustomerListId, deleteCustomer } from "../redux/slices/customer";
// import Badge from "react-bootstrap/Badge";
import DataTable from 'react-data-table-component';
import { NoDataComponent } from "./NoDataComponent";
// import { useParams } from "react-router-dom";

export const ClientsDirectory = () => {
  const navigate = useNavigate();
  const { isLoading, customerList } = useSelector(
    (state) => state.customer
  );
  const [customerId, setCustomerId] = useState(0);
  const [search, setSearch] = useState("");
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const id = localStorage.getItem("cmp_id");
    dispatch(getCustomerListId(0));
  }, [dispatch]);

  const handleRowClick = (row) => {
    navigate(`/cases-list`);
    localStorage.setItem('lead_cus_id', JSON.stringify(row.lead_cus_id));
    localStorage.setItem('lead_emp_id', JSON.stringify(row.lead_emp_id));
  };
  useEffect(() => {
    setFilteredCustomers(
      customerList?.filter((customer) =>
        `${customer.first_name} ${customer.middle_name} ${customer.last_name}`
          .toLowerCase()
          .includes(search.toLowerCase())
      )
    );
  }, [search, customerList]);

  const handleViewDetails = (companyId) => {
    navigate("/edit-client/" + companyId, { state: { companyId } });
  };

  const columns = [
    // { name: 'Sr. No', selector: (row, index) => index + 1, sortable: true, width: '90px' },
    { name: 'Clients Name', selector: row => `${row.first_name} ${row.middle_name} ${row.last_name}`, sortable: true, minWidth: '150px' },
    { name: 'Department', selector: row => row.department, sortable: true, minWidth: '130px' },
    { name: 'Title', selector: row => row.title, sortable: true, minWidth: '150px' },
    { name: 'Email', selector: row => row.emailid, sortable: true, minWidth: '200px' },
    // { name: 'State Name', selector: row => row.state_name, sortable: true, minWidth: '150px' },
    // { name: 'City', selector: row => row.city, sortable: true, minWidth: '150px' },
    // { name: 'Country', selector: row => row.cnt_iso, sortable: true },
    // {
    //   name: 'Report',
    //   minWidth: '130px',
    //   cell: row => (
    //     <div className="d-flex align-items-center gap-1">
    //       <img
    //         src={require("../assets/reports.png")}
    //         alt="Client Report"
    //         className="img-fluid"
    //         style={{ width: 15 }}
    //       />
    //       <span>Report</span>
    //     </div>
    //   ),
    // },
    // {
    //   name: 'Closed',
    //   cell: row => (
    //     <Badge pill bg="danger" className="px-3">
    //       Closed
    //     </Badge>
    //   ),
    // },
    {
      name: 'Action',
      cell: row => (
        <div className="action-wrapper d-flex gap-2">
          <button
            className="btn btn-warning btn-sm"
            title="Edit"
            onClick={() => handleViewDetails(row.id)}
          >
            <i className="bi bi-pencil-fill fs-14"></i>
          </button>
          <button
            className="btn btn-danger btn-sm"
            title="Delete"
            data-bs-toggle="modal"
            data-bs-target="#deleteCompany"
            onClick={() => setCustomerId(row.id)}
          >
            <i className="bi bi-trash3 fs-14"></i>
          </button>
        </div>
      ),
    },
  ];

  // Style Customization for Data Table
  const customStyles = {
    rows: {
      style: {
        fontSize: '14px',
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: '600',
        padding: "5px 12px",
        color: '#fff',
        backgroundColor: 'var(--bs-primary)',
        minHeight:'45px',
      },
    },
    cells: {
      style: {
        color: '#3f474f',
        fontSize: "14px",
        padding: "5px 12px",
        minHeight:'40px',
      },
    },
  };

  return (
    <div className="ClientsListPage py-3">
      <div className="container-fluid">
        <div className="heading-wrapper d-flex flex-wrap align-items-center justify-content-between gap-3 mb-3">
          <div className="fs-4 fw-bold lh-1">Clients List</div>
          <div className="d-flex flex-wrap gap-2">
            <button
              className="btn btn-primary"
              onClick={() => navigate("/add-client")}
            >
              <i className="bi bi-person-plus-fill"></i> Add client
            </button>
          </div>
        </div>

        <div className="card border-0 rounded-3 shadow-sm p-3">
          <div className="searchfield-wrapper position-relative fs-6 ms-auto mb-3">
            <input
              type="search"
              className="form-control ms-auto"
              placeholder="Search clients here..."
              autoComplete="off"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <img
              src={SearchIcon}
              alt="Search Icon"
              className="position-absolute top-50 end-0 translate-middle-y pe-3"
            />
          </div>
          <div className="table-responsive table-custom-wrapper">
            <DataTable
              columns={columns}
              data={filteredCustomers}
              pagination
              highlightOnHover
              pointerOnHover
              progressPending={isLoading}
              onRowClicked={handleRowClick}
              noDataComponent={<NoDataComponent />}
              // selectableRows
              customStyles={customStyles}
              dense
              responsive
            />
          </div>
        </div>
      </div>

      {/* Delete Specific Company From Table */}
      <div
        className="modal fade"
        id="deleteCompany"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-4">
            <div className="modal-body p-md-4 p-lg-5 text-center">
              <div className="fs-18 fw-semibold lh-sm mb-4">
                Are you sure you want to delete this company
              </div>
              <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3">
                <button
                  type="button"
                  className="btn btn-danger px-4"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary px-4"
                  data-bs-dismiss="modal"
                  onClick={() => dispatch(deleteCustomer(customerId))}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
