import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SearchIcon from "../assets/search.svg";
import { useSelector, useDispatch } from "react-redux";
import { projectFilesId, deleteProjectFile } from "../redux/slices/journal";
import DataTable from "react-data-table-component";
import { NoDataComponent } from "./NoDataComponent";
import Modal from "react-bootstrap/Modal";
import { Tabs, Tab, Form, Button, Spinner } from "react-bootstrap";
import {
  getProjectListId,
  deleteProject,
  addProjectFiles,
  getProjectFullDetails,
} from "../redux/slices/project";

export const CompanyFilesProject = () => {
  const navigate = useNavigate();
  const { isLoading, filesListCustomer } = useSelector(
    (state) => state.journal
  );
  const { id } = useParams();
  const [companyId, setCompanyId] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    prj_id: id,
    cmp_id: localStorage.getItem("cmp_id"),
    name: "",
    path: "project_files/",
    size: "",
    type: "",
    description: "",
    status: "A",
    is_processed: "YES",
    file: [],
  });
  useEffect(() => {
    dispatch(projectFilesId(id));
    dispatch(getProjectFullDetails(id));
  }, [dispatch]);
  const { projectDetails, projectFullDetails, projectList } = useSelector(
    (state) => state.project
  );
  console.log(filesListCustomer);
  useEffect(() => {
    if (filesListCustomer.length > 0) {
      setFilteredCompanies(
        filesListCustomer?.filter(
          (company) =>
            company.name.toLowerCase().includes(searchText.toLowerCase())
          // company.address1.toLowerCase().includes(searchText.toLowerCase()) ||
          // company.address2.toLowerCase().includes(searchText.toLowerCase()) ||
          // company.city.toLowerCase().includes(searchText.toLowerCase()) ||
          // company.phone.toLowerCase().includes(searchText.toLowerCase()) ||
          // company.fax.toLowerCase().includes(searchText.toLowerCase()) ||
          // company.url.toLowerCase().includes(searchText.toLowerCase()) ||
          // company.status.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setFilteredCompanies([]);
    }
  }, [searchText, id, filesListCustomer, filesListCustomer]);

  const handleViewDetails = (companyId) => {
    navigate("/edit-company/" + companyId, { state: { companyId } });
  };

  // const handleRowClick = (row) => {
  //   // navigate(`/clients-list`);
  //   localStorage.setItem("cmp_id", JSON.stringify(row.id));
  // };

  const [showFilesModal, setFilesModal] = useState(false);
  const openFilesModal = () => {
    setFilesModal(true);
  };
  const closeFilesModal = () => setFilesModal(false);

  const columns = [
    // {
    //   name: "Sr. No",
    //   selector: (row, index) => index + 1,
    //   sortable: true,
    //   width: "90px",
    // },
    {
      name: "Image",
      selector: (row, index) => (
        <>
          {/* <img
          src={`https://oitrack.s3.us-west-1.amazonaws.com/${row.path}`}
          className="rounded-circle"
          style={{ width: "40px", height: "40px" }}
          alt=""
        /> */}
          <a
            target="_blank"
            href={`https://oitrack.s3.us-west-1.amazonaws.com/${row.name}`}
            rel="noreferrer"
          >
            {row.type === "image/png" || row.type === "image/jpeg" ? (
              <i className="bi bi-image-fill"></i>
            ) : row.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
              <i className="bi bi-filetype-docx"></i>
            ) : row.type === "application/pdf" ? (
              <i className="bi bi-file-earmark-pdf-fill"></i>
            ) : row.type === "application/msword" ? (
              <i className="bi bi-file-earmark-word"></i>
            ) : row.type === "video/x-ms-wmv" ||
              row.type === "video/mpeg" ||
              row.type === "video/mp4" ||
              row.type === "video/3gpp" ||
              row.type === "video/vnd.dlna.mpeg-tts" ? (
              <i className="bi bi-file-earmark-word"></i>
            ) : row.type === "application/vnd.openxmlformats-officedocument" ||
              row.type === "application/vnd.ms-excel" ? (
              <i class="bi bi-file-earmark-excel-fill"></i>
            ) : (
              <i class="bi bi-file-arrow-up-fill"></i>
            )}
          </a>
        </>
      ),
      sortable: true,
      minWidth: "200px",
    },
    { name: "Name", selector: (row) => row.name, sortable: true },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      minWidth: "200px",
    },
    // {
    //   name: "Is processed",
    //   selector: (row) => row.is_processed,
    //   sortable: true,
    //   minWidth: "200px",
    // },
    {
      name: "Size",
      selector: (row) => row.size,
      sortable: true,
      minWidth: "180px",
    },
    // {
    //   name: "Type",
    //   selector: (row) => row.type,
    //   sortable: true,
    //   minWidth: "150px",
    // },
    {
      name: "Action",
      cell: (row) => (
        <div className="action-wrapper d-flex gap-2">
          {/* <button
              className="btn btn-warning btn-sm"
              title="Edit"
              onClick={() => handleViewDetails(row.id)}
            >
              <i className="bi bi-pencil-fill fs-14"></i>
            </button> */}
          <button
            className="btn btn-danger btn-sm"
            title="Delete"
            data-bs-toggle="modal"
            data-bs-target="#deleteCompany"
            onClick={() => setCompanyId(row.id)}
          >
            <i className="bi bi-trash3 fs-14"></i>
          </button>
        </div>
      ),
    },
  ];

  // Style Customization for Data Table
  const customStyles = {
    rows: {
      style: {
        fontSize: "14px",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "600",
        padding: "5px 12px",
        color: "#fff",
        backgroundColor: "var(--bs-primary)",
        minHeight: "45px",
      },
    },
    cells: {
      style: {
        color: "#3f474f",
        fontSize: "14px",
        padding: "5px 12px",
        minHeight: "40px",
      },
    },
  };

  const handleFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      console.log(file);
      await setFormData({
        ...formData,
        name: file.name,
        type: file.type,
        size: file.size,
        prj_id: id,
        file: file,
      });
    }
    console.log(formData);
  };
  const handleSubmit = async () => {
    // e.preventDefault();
    console.log(formData);
    await dispatch(addProjectFiles(formData));
    await dispatch(projectFilesId(id));
    await setFilesModal(false);
  };
  const handleInputChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      description: value,
    });
  };
  return (
    <div className="companyListPage py-4">
      <div className="container-fluid">
        <div className="heading-wrapper d-flex flex-wrap align-items-center justify-content-between gap-3 mb-3">
          <div className="fs-4 fw-bold lh-1">Case Files</div>
          <div className="d-flex flex-wrap gap-2">
            {/* <button
              className="btn btn-primary"
              onClick={() => navigate("/add-company")}
            >
              <i className="bi bi-plus-circle"></i> Add company
            </button> */}
            <button
              className="btn btn-success"
              onClick={() => openFilesModal()}
            >
              <i className="bi bi-folder-plus"></i> Add File
            </button>
          </div>
        </div>

        <div className="card border-0 rounded-3 shadow-sm p-3">
          <div className="bg-dark bg-opacity-10 text-primary fw-medium rounded-top-3 px-3 py-2">
            {projectFullDetails?.name}
          </div>
          <div className="searchfield-wrapper position-relative fs-6 ms-auto mb-3">
            <input
              type="search"
              className="form-control ms-auto"
              placeholder="Search data here..."
              autoComplete="off"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <img
              src={SearchIcon}
              alt="Search Icon"
              className="position-absolute top-50 end-0 translate-middle-y pe-3"
            />
          </div>
          <div className="table-responsive table-custom-wrapper">
            <DataTable
              columns={columns}
              data={filteredCompanies}
              pagination
              highlightOnHover
              pointerOnHover
              // onRowClicked={handleRowClick}
              progressPending={isLoading}
              noDataComponent={<NoDataComponent />}
              // selectableRows
              customStyles={customStyles}
              dense
              responsive
            />
          </div>
        </div>
      </div>

      {/* Delete Specific Company From Table */}
      <div
        className="modal fade"
        id="deleteCompany"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="deleteCompanyLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-4">
            <div className="modal-body p-md-4 p-lg-5 text-center">
              <div className="fs-18 fw-semibold lh-sm mb-4">
                Are you sure you want to delete this file?
              </div>
              <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3">
                <button
                  type="button"
                  className="btn btn-danger px-4"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary px-4"
                  data-bs-dismiss="modal"
                  onClick={async () => {
                    await dispatch(deleteProjectFile(companyId));
                    const prj_id = await localStorage.getItem("prj_id");
                    await dispatch(projectFilesId(prj_id));
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showFilesModal}
        onHide={closeFilesModal}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Header closeButton className="bg-primary bg-opacity-10">
          <Modal.Title className="fs-6 text-primary lh-sm">
            <i className="bi bi-file-earmark-text-fill"></i> Add File Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="projectFile" className="mb-3">
              <Form.Label>
                File Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="file"
                className="shadow-none"
                // multiple
                required
                onChange={handleFileChange}
                style={{ minHeight: "auto" }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="fileDescription">
              <Form.Label>
                Description<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                autoComplete="off"
                maxLength={100}
                onChange={handleInputChange}
                name="description"
                placeholder="Enter your description here..."
                required
              />
              <div className="fs-12 text-secondary mt-1">
                Maximum 100 characters
              </div>
            </Form.Group>
            <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-2 pt-2 mb-3">
              <Button
                // type="submit"
                variant="primary"
                onClick={handleSubmit}
                className="px-4"
              >
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {" Ádding file wait..."}
                  </>
                ) : (
                  "Add"
                )}
              </Button>
              <Button type="reset" variant="danger" className="px-4">
                Reset
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
