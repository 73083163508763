import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SearchIcon from "../assets/search.svg";
import { useSelector, useDispatch } from "react-redux";
import { getProjectDetailsById } from "../redux/slices/project";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import { Tabs, Tab, Form, Button } from "react-bootstrap";
import { NoDataComponent } from "./NoDataComponent";
import { getJournalListId, deleteJournal } from "../redux/slices/journal";
import { getEventList, deleteEvent } from "../redux/slices/event";

export const CasesDetails = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showTab, setShowTab] = useState(false);
  const [meetingId, setMeetingId] = useState("");
  // const [selectedProject, setSelectedProject] = useState(null);
  const [activeTab, setActiveTab] = useState("tab1");
  const [projectId, setProjectId] = useState(0);
  const navigate = useNavigate();
  const { id } = useParams();
  const { projectDetails } = useSelector((state) => state.project);
  const { eventList } = useSelector((state) => state.event);
  const { filesList, journalList } = useSelector((state) => state.journal);
  const dispatch = useDispatch();
  const [journalId, setJournalId] = useState(0);
  const handleShow = (id) => {
    setShow(true);
    setJournalId(id);
  };
  const [formData, setFormData] = useState({
    prj_id: projectId,
    cmp_id: localStorage.getItem("cmp_id"),
    name: "",
    path: "project_files/",
    size: "",
    type: "",
    description: "",
    status: "A",
    is_processed: "YES",
    file: [],
  });
  // Add Project Files Modal
  const [showFilesModal, setFilesModal] = useState(false);
  const openFilesModal = (id) => {
    setProjectId(id);
    setFilesModal(true);
  };
  const closeFilesModal = () => setFilesModal(false);
  const handleDelete = () => {
    console.log(meetingId);
    dispatch(deleteEvent(meetingId));
    setShow(false);
  };
  useEffect(() => {
    dispatch(getProjectDetailsById(id));
  }, [dispatch]);

  // console.log(projectDetails);
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const handleCloseTab = () => setShowTab(false);
  const handleShow2 = (id) => {
    // debugger
    console.log(id)
    setMeetingId(id);
    setShow2(true);
  };

  return (
    <div className="CasesListPage py-4">
      <div className="container-fluid">
        <div className="heading-wrapper d-flex flex-wrap align-items-center justify-content-between gap-3 mb-3">
          <div className="fs-4 fw-bold lh-1">Project Details</div>
          <div className="d-flex flex-wrap gap-2">
            <button
              className="btn btn-primary"
              onClick={() => navigate("/add-case")}
            >
              <i className="bi bi-folder-plus"></i> Add Case
            </button>
            {/* <button className="btn btn-success" onClick={() => navigate("/expenses-list")}>
              <i className="bi bi-folder-plus"></i> Add Expense
            </button> */}
          </div>
        </div>

        {/*  */}
        <div className="info-card-wrapper mt-4">
          <div className="bg-dark bg-opacity-10 text-primary fw-medium rounded-top-3 px-3 py-2">
            {projectDetails?.name}
          </div>
          <div className="card rounded-top-0 p-3">
            <div className="table-responsive table-custom-wrapperr">
              <table className="table table-bordered fs-14 m-0">
                <thead>
                  <tr>
                    <th
                      scope="column"
                      className="bg-secondary bg-opacity-10 text-center"
                      style={{ minWidth: "300px" }}
                    >
                      Case
                    </th>
                    <th
                      scope="column"
                      className="bg-secondary bg-opacity-10 text-center"
                      style={{ minWidth: "300px" }}
                    >
                      Case Journal
                    </th>
                    <th
                      scope="column"
                      className="bg-secondary bg-opacity-10 text-center"
                      style={{ minWidth: "420px" }}
                    >
                      Schedule
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="row justify-content-between g-3">
                        <div className="col-6 project-name fw-medium">
                          {projectDetails?.name}
                        </div>
                        <div className="col-6">
                          <Button
                            variant="link"
                            className="fs-14 text-success text-decoration-none p-0"
                            onClick={() => navigate('/company-files-project/'+id)}
                          >
                            <i className="bi bi-journal-bookmark-fill"></i> Case
                            Files
                          </Button>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <Button
                          variant="link"
                          className="fs-14 text-success text-decoration-none p-0"
                          onClick={() => navigate(`/add-journal`)}
                        >
                          <i className="bi bi-plus-circle"></i> Add Journal Form
                        </Button>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-center gap-4">
                        <Button
                          variant="link"
                          className="fs-14 text-success text-decoration-none p-0"
                          onClick={() => navigate(`/add-meeting`)}
                        >
                          <i className="bi bi-person-workspace"></i> Add Meeting
                        </Button>
                        <Button
                          variant="link"
                          className="fs-14 text-success text-decoration-none p-0"
                          onClick={() => navigate(`/add-milestone`)}
                        >
                          <i className="bi bi-signpost-fill"></i> Add Milestone
                        </Button>
                        <Button
                          variant="link"
                          className="fs-14 text-success text-decoration-none p-0"
                          onClick={() => navigate(`/add-deadline`)}
                        >
                          <i className="bi bi-calendar-week-fill"></i> Add
                          Deadline
                        </Button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="row g-3 mb-2">
                        <div className="col-6 label-name fw-medium">
                          Project Status
                        </div>
                        <div className="col-6 label-value">
                          {projectDetails?.status_id}
                        </div>
                      </div>
                      <div className="row g-3 mb-2">
                        <div className="col-6 label-name fw-medium">
                          Project Lead
                        </div>
                        <div className="col-6 label-value">
                          {projectDetails?.lead_employee}
                        </div>
                      </div>
                      <div className="row g-3 mb-2">
                        <div className="col-6 label-name fw-medium">
                          Customer Lead
                        </div>
                        <div className="col-6 label-value text-success">
                          {projectDetails?.lead_customer}
                        </div>
                      </div>
                      <div className="row g-3 mb-2">
                        <div className="col-6 label-name fw-medium">
                          Project Team
                        </div>
                        <div className="col-6 label-value">
                          {projectDetails?.participants}
                        </div>
                      </div>
                      {/* <div className="row g-3">
                        <div className="col-6 label-name fw-medium">
                          Customer Team
                        </div>
                        <div className="col-6 label-value">
                          {projectDetails?.lead_cus_id}
                        </div>
                      </div> */}
                    </td>
                    <td>
                      <div className="wrapper d-flex flex-column gap-2">
                        {projectDetails?.journals?.map((e) => {
                          return (
                            <div className="row g-3">
                              <div className="col-4 date">{e.date}</div>
                              <div className="col-5 title text-success text-capitalize">
                                {e.title}
                              </div>
                              <div className="col action d-flex justify-content-end gap-2">
                                <Button
                                  variant="link"
                                  className="fs-14 text-warning text-decoration-none p-0"
                                  title="Edit"
                                  onClick={() =>
                                    navigate(`/edit-journal/${e.id}`)
                                  }
                                >
                                  <i className="bi bi-pencil-square"></i>
                                </Button>
                                <Button
                                  variant="link"
                                  className="fs-14 text-danger text-decoration-none p-0"
                                  title="Delete"
                                  onClick={() => handleShow(e.id)}
                                >
                                  <i className="bi bi-trash3-fill"></i>
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </td>
                    <td>
                      <div className="wrapper d-flex flex-column gap-2">
                        {projectDetails?.schedule?.map((e) => {
                          return (
                            <div className="row g-3">
                              <div className="col-3 date">{e.date}</div>
                              <div className="col-7 title text-success text-capitalize">
                                {e.title}
                              </div>
                              <div className="col action d-flex justify-content-end gap-2">
                                <Button
                                  variant="link"
                                  className="fs-14 text-warning text-decoration-none p-0"
                                  title="Edit"
                                  onClick={() =>
                                    navigate(`/edit-meeting/${e.id}`)
                                  }
                                >
                                  <i className="bi bi-pencil-square"></i>
                                </Button>
                                <Button
                                  variant="link"
                                  className="fs-14 text-danger text-decoration-none p-0"
                                  title="Delete"
                                  onClick={() => handleShow2(e.id)}
                                >
                                  <i className="bi bi-trash3-fill"></i>
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Body className="p-md-4 p-lg-5 text-center">
          <div className="fs-18 fw-semibold lh-sm mb-4">
            Are you sure you want to delete this journal case?
          </div>
          <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3">
            <button
              type="button"
              className="btn btn-danger px-4"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary px-4"
              onClick={async () => {
                await dispatch(deleteJournal(journalId));
                await handleClose();
                await dispatch(getProjectDetailsById(id));
              }}
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show2}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Body className="p-md-4 p-lg-5 text-center">
          <div className="fs-18 fw-semibold lh-sm mb-4">
            Are you sure you want to delete this Meeting?
          </div>
          <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3">
            <button
              type="button"
              className="btn btn-danger px-4"
              onClick={handleClose2}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary px-4"
              onClick={async () => {
                await dispatch(deleteEvent(meetingId));
                await handleClose2();
                await dispatch(getProjectDetailsById(id));
              }}
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
