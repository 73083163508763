import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import API_URL from '../../Apiurl';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  error: false,
  success: '',
  expenseList: [],
  expenseListId: [],
  deleteLoading: false,
  deleteSuccess: '',
  deleteError: '',
  expenseDetails: null
};

const slice = createSlice({
  name: 'expense',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false; // Clear previous errors
      state.success = '';  // Clear previous success messages
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.error("Error:", action.payload); // Log error
    },

    // GET COMPANY LIST SUCCESS
    getExpenseListSuccess(state, action) {
      state.isLoading = false;
      state.expenseList = action.payload.data;
      state.error = false;
      state.success = 'Expense list fetched successfully!';
    },
    getExpenseListSuccessId(state, action) {
      state.isLoading = false;
      state.expenseListId = action.payload.data;
      state.error = false;
      state.success = 'Expense list fetched successfully!';
    },

    // START DELETE LOADING
    startDeleteLoading(state) {
      state.deleteLoading = true;
      state.deleteError = false; // Clear previous errors
      state.deleteSuccess = ''; // Clear previous success messages
    },

    // DELETE HAS ERROR
    deleteHasError(state, action) {
      state.deleteLoading = false;
      state.deleteError = action.payload;
      console.error("Delete Error:", action.payload); // Log error
    },

    // DELETE COMPANY SUCCESS
    deleteExpenseSuccess(state, action) {
      state.deleteLoading = false;
      state.deleteSuccess = 'Expense deleted successfully!';
      state.deleteError = false;

      // Optionally, remove the deleted expense from the expenseList
      state.expenseList = state.expenseList?.filter(expense => expense.id !== action.payload.id);
    },
    getExpenseDetailsSuccess(state, action) {
      state.isLoading = false;
      state.expenseDetails = action.payload.data;
      state.error = false;
    }  
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  getExpenseListSuccess,
  startDeleteLoading,
  deleteHasError,
  deleteExpenseSuccess,
  getExpenseDetailsSuccess,
  getExpenseListSuccessId
} = slice.actions;

// Async Action to Get Expense List
export function getExpenseList() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const id = localStorage.getItem("prj_id");
      const resp = await axios.get(`${API_URL}list-expense/${id}`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getExpenseListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch expense list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the expense list"));
    }
  };
}
export function getExpenseListId() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const id = localStorage.getItem("prj_id");
      const resp = await axios.get(`${API_URL}list-expense-detials/${id}`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getExpenseListSuccessId(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch expense list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the expense list"));
    }
  };
}

// Async Action to Add Expense
export function addExpense(e) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.post(`${API_URL}add-expense`, e, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getExpenseListSuccess(resp.data));
        toast("Expenses added sucessfully!", {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
      } else {
        dispatch(hasError(resp.message || "Failed to add expense"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while adding the expense"));
    }
  };
}

// Async Action to Delete Expense
export function deleteExpense(expenseId) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const resp = await axios.delete(`${API_URL}delete-expense/${expenseId}`);
      console.log("API delete response:", resp); // Log API response
      if (resp && resp.status === 200) {
        const prj_id = localStorage.getItem("prj_id");
        dispatch(getExpenseDetails(prj_id));
      } else {
        dispatch(deleteHasError(resp.message || "Failed to delete expense"));
      }
    } catch (error) {
      console.error("Caught delete error:", error); // Log caught error
      dispatch(deleteHasError(error.message || "An error occurred while deleting the expense"));
    }
  };
}
export function deleteExpenseDetail(expenseId) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const resp = await axios.delete(`${API_URL}delete-expense-detail/${expenseId}`);
      console.log("API delete response:", resp); // Log API response
      if (resp && resp.status === 200) {
        // dispatch(deleteExpenseSuccess({ id: expenseId }));
        const prj_id = localStorage.getItem("prj_id");
        dispatch(getExpenseDetails(prj_id));
      } else {
        dispatch(deleteHasError(resp.message || "Failed to delete expense"));
      }
    } catch (error) {
      console.error("Caught delete error:", error); // Log caught error
      dispatch(deleteHasError(error.message || "An error occurred while deleting the expense"));
    }
  };
}
export function getExpenseDetails(expenseId) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}view-expense/${expenseId}`);
      console.log("API response:", resp);
      if (resp && resp.status === 200) {
        dispatch(getExpenseDetailsSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch expense details"));
      }
    } catch (error) {
      console.error("Caught details error:", error);
      dispatch(hasError(error.message || "An error occurred while fetching the expense details"));
    }
  };
}
export function editExpense(id, e) {
  return async (dispatch) => {
    console.log(e)
    dispatch(startLoading());
    try {
      const resp = await axios.put(`${API_URL}update-expense/${id}`, e, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getExpenseListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to add expense"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while adding the expense"));
    }
  };
}
