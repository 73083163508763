import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { editExpense, getExpenseDetails } from "../redux/slices/expense";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import {
  getProjectFullDetails,
  getProjectListById,
} from "../redux/slices/project";
import {
  getExpenseListId,
  deleteExpenseDetail,
  deleteExpense,
} from "../redux/slices/expense";
import { getCompanyList } from "../redux/slices/company";
import { addInvoiceApi } from "../redux/slices/invoice";
import { NoDataComponent } from "./NoDataComponent";

export const ExpensesListing = () => {
  // Add Invoice/Expense Bootstrap Modal
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [expenseId, setExpenseId] = useState(0);
  const [cmp_id, setCompany] = useState("");
  const [company, setCompanyData] = useState({});
  const [projectData, setProjectData] = useState({});
  const [subTotal, setSubTotal] = useState(50);

  const [prj_id, setProjectId] = useState("");
  const [invoice, setInvoice] = useState([
    {
      date: "2021-10-11",
      expenses: "",
      description: "",
      price: 50,
      expenses_id: expenseId,
    },
  ]);
  const [formData, setFormData] = useState({
    prj_id: "",
    cmp_id: "",
    invoice_date: "",
    total_price: subTotal,
    invoice_from: "",
    invoice_to: "",
    order_no: "",
    adjuster: "",
    shipped_by: "",
    shipping_price: 10,
    status: "Unpaid",
    invoicedetails: [],
    expenses_id: expenseId,
  });
  const addInvoice = async () => {
    console.log(invoice);

    await dispatch(addInvoiceApi(formData));
  };
  console.log(projectData);
  const openAddInvoicModal = (row) => {
    console.log(row);
    setShow(true);
    setExpenseId(row.id);
  };
  const closeAddInvoicModal = () => setShow(false);
  const [showExpense, setShowExpense] = useState(false);
  const [expFrom, setExpFrom] = useState("2021-10-11");
  const [expTo, setExpTo] = useState("2021-11-11");
  const [rowId, setRowId] = useState(0);
  // const [expenseId, setExpenseId] = useState(0);
  const handleShow = (id) => {
    setShowDelete(true);
    setExpenseId(id);
  };
  const handleClose = () => setShowDelete(false);
  const { isLoading, expenseDetails } = useSelector((state) => state.expense);
  const [expenses, setExpenses] = useState([
    // { date: "2021-10-11", type: "", description: "", price: "50" },
  ]);
  const { projectDetails, projectFullDetails, projectList } = useSelector(
    (state) => state.project
  );
  const { expenseListId } = useSelector((state) => state.expense);
  const handleInputChange = (index, field, value) => {
    const newExpenses = expenses.map((expense, i) =>
      i === index ? { ...expense, [field]: value } : expense
    );
    setExpenses(newExpenses);
  };
  const handleInputChangeInvoice = (index, field, value) => {
    console.log(index, field, value);
    const newInvoice = invoice.map((invoice, i) =>
      i === index ? { ...invoice, [field]: value } : invoice
    );
    console.log(newInvoice);
    const newInvoices = [...invoice];
    newInvoices[index][field] = value;
    if (field === 'price' || field === 'quantity') {
      const newSubtotal = newInvoices.reduce((acc, invoice) => {
        // const quantity = parseFloat(invoice.quantity) || 0;
        const price = parseFloat(invoice.price) || 0;
        return acc + price;
      }, 0);
      setSubTotal(newSubtotal);
    }

    setInvoice(newInvoice);
    setFormData({
      ...formData,
      invoicedetails: invoice,
      expenses_id: expenseId,
    });
    console.log(formData);
  };
  const handleInputInvoiceChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  // Preview Invoice/Expense Bootstrap Modal
  const dispatch = useDispatch();
  const [showPreviewInvoice, setShowPreviewInvoice] = useState(false);
  const openPreviewInvoiceModal = () => setShowPreviewInvoice(true);
  const closePreviewInvoiceModal = () => setShowPreviewInvoice(false);
  const openExpenseModal = (id) => {
    console.log(id);
    setShowExpense(true);
    setRowId(id);
    dispatch(getExpenseListId(id));
  };
  const { companyList } = useSelector((state) => state.company);
  useEffect(() => {
    dispatch(getCompanyList());
  }, [dispatch]);
  const closeExpenseModal = () => setShowExpense(false);
  const handleAddExpense = () => {
    setExpenses([
      ...expenses,
      { date: "2021-10-11", type: "", description: "", price: "50" },
    ]);
  };
  const handleAddInvoice = () => {
    setInvoice([
      ...invoice,
      {
        date: "2021-10-11",
        expenses: "",
        description: "",
        price: 50,
        expenses_id: expenseId,
      },
    ]);
    setSubTotal(subTotal + 50);
  };
  useEffect(() => {
    const id = localStorage.getItem("prj_id");
    dispatch(getExpenseDetails(id));
    dispatch(getProjectFullDetails(id));
  }, [dispatch]);
  useEffect(() => {
    if (expenseListId) {
      setExpFrom(expenseListId.expenses_from);
      setExpTo(expenseListId.expenses_to);
      setExpenses(expenseListId.data_expense);
    }
  }, [expenseListId]);

  const customStyles = {
    rows: {
      style: {
        fontSize: '14px',
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: '600',
        padding: "5px 12px",
        color: '#fff',
        backgroundColor: 'var(--bs-primary)',
        minHeight:'45px',
      },
    },
    cells: {
      style: {
        color: '#3f474f',
        fontSize: "14px",
        padding: "5px 12px",
        minHeight:'40px',
      },
    },
  };

  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => index + 1,
      sortable: true,
      sortFunction: (a, b) => a - b,
    },
    {
      name: "Claim Number",
      selector: (row) => row.claim_number,
      sortable: true,
      sortFunction: (a, b) => a.claim_number.localeCompare(b.claim_number),
    },
    {
      name: "Expenses",
      selector: (row) => row.total_expenses,
      sortable: true,
      sortFunction: (a, b) => a.total_expenses - b.total_expenses,
    },
    {
      name: "Expenses Period",
      selector: (row) => row.adddate,
      sortable: true,
      sortFunction: (a, b) => new Date(a.adddate) - new Date(b.adddate),
    },
    {
      name: "Total Expenses",
      selector: (row) => row.total_expenses,
      sortable: true,
      sortFunction: (a, b) => a.total_expenses - b.total_expenses,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="action-wrapper d-flex gap-2">
          <Button
            variant="warning"
            className="d-flex align-items-center justify-content-center"
            title="Edit"
            onClick={() => openExpenseModal(row.id)}
          >
            <i className="bi bi-eye-fill fs-6"></i>
          </Button>
          <Button
            variant="danger"
            className="d-flex align-items-center justify-content-center"
            title="Delete"
            // onClick={() => openExpenseModal(row.id)}
            onClick={() => handleShow(row.id)}
          >
            <i className="bi bi-trash3-fill fs-6"></i>
          </Button>
          {/* <div className="d-flex flex-wrap gap-2">
            <Button
              className="btn btn-sm btn-primary"
              onClick={openPreviewInvoiceModal}
            >
              <i className="bi bi-folder-plus"></i> Preview Invoice/Expense
            </Button>
            <Button
              className="btn btn-sm btn-primary"
              onClick={openAddInvoicModal}
            >
              <i className="bi bi-folder-plus"></i> Add Invoice/Expense
            </Button>
          </div> */}
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="action-wrapper d-flex gap-2">
          {/* <Button
            variant="warning"
            className="d-flex align-items-center justify-content-center"
            title="Edit"
            onClick={() => openExpenseModal(row.id)}
          >
            <i className="bi bi-eye-fill fs-6"></i>
          </Button>
          <Button
            variant="danger"
            className="d-flex align-items-center justify-content-center"
            title="Delete"
            // onClick={() => openExpenseModal(row.id)}
            onClick={() => handleShow(row.id)}
          >
            <i className="bi bi-trash3-fill fs-6"></i>
          </Button> */}
          {/* <div className="d-flex flex-wrap gap-2"> */}
          <Button
            className="btn btn-sm btn-primary"
            onClick={(e) => {
              console.log(row);
              openPreviewInvoiceModal();
              setExpenseId(row.id);
            }}
          >
            <i className="bi bi-folder-plus"></i> Preview Invoice
          </Button>
          <Button
            className="btn btn-sm btn-primary"
            onClick={() => {
              openAddInvoicModal(row);
            }}
          >
            <i className="bi bi-folder-plus"></i> Add Invoice
          </Button>
          {/* </div> */}
        </div>
      ),
    },
  ];
  const handleRemoveExpense = (index, id) => {
    console.log(index, id);
    setExpenses(expenses.filter((_, i) => i !== index));
    if (id) {
      dispatch(deleteExpenseDetail(id));
    }
    if (index === 0) {
      setExpenses([
        { date: "2021-10-11", type: "", description: "", price: "50" },
      ]);
    }
  };

  const handleRemoveInvoice = (index) => {
    console.log(index);
    setInvoice(invoice.filter((_, i) => i !== index));
    // if (id) {
    //   dispatch(deleteExpenseDetail(id));
    // }
    setSubTotal(invoice[index].price + 50);
    if (index === 0) {
      setInvoice([
        {
          date: "2021-10-11",
          expenses: "",
          description: "",
          price: 50,
          expenses_id: expenseId,
        },
      ]);
      setSubTotal(50);
    }
  };
  const handleSubmit = () => {
    const formData = {
      expenses_from: expFrom,
      expenses_to: expTo,
      expensesdetails: expenses,
    };
    console.log("Form Data:", formData);
    dispatch(editExpense(rowId, formData));
    // Handle form submission, e.g., send data to server
    closeExpenseModal();
  };
  return (
    <div className="ExpensesListingPage py-3">
      <div className="container-fluid">
        <div className="fs-4 fw-bold lh-1 mb-3"></div>
        <div className="heading-wrapper d-flex flex-wrap align-items-center justify-content-between gap-3 mb-3">
          <div className="fs-4 fw-bold lh-1">
            <i className="bi bi-receipt"></i> Listing Expenses
          </div>
          <div className="d-flex flex-wrap gap-2">
            <button
              className="btn btn-primary"
              onClick={openPreviewInvoiceModal}
            >
              <i className="bi bi-folder-plus"></i> Preview Invoice/Expense
            </button>
            <button
              className="btn btn-primary"
              onClick={openAddInvoicModal}
            >
              <i className="bi bi-folder-plus"></i> Add Invoice/Expense
            </button>
          </div>
        </div>

        {/* <div className="alert alert-success text-success text-center" role="alert"><i className="bi bi-check-circle-fill"></i> Expenses Added Successfully</div> */}
        <div className="info-card-wrapper">
          <div className="bg-dark bg-opacity-10 text-primary fw-medium rounded-top-3 px-3 py-2">
            Proj - {projectFullDetails.name}
          </div>
          <div className="card rounded-top-0 p-3">
            <div className="table-responsive table-custom-wrapper">
              {/* <DataTable
              columns={columns}
              data={expenseDetails || []}
              pagination
              highlightOnHover
              pointerOnHover
              noDataComponent={<NoDataComponent />}
              progressPending={isLoading}
              customStyles={customStyles}
              dense
              responsive
            /> */}
            
              <DataTable
                columns={columns}
                data={expenseDetails || []}
                pagination
                highlightOnHover
                pointerOnHover
                // onRowClicked={handleRowClick}
                progressPending={isLoading}
                noDataComponent={<NoDataComponent />}
                // selectableRows
                defaultSortAsc={false}
                customStyles={customStyles}
                dense
                responsive
              />
            </div>
          </div>
        </div>
      </div>

      {/* Add Invoice/Expense Modal Code */}
      <Modal
        show={show}
        onHide={closeAddInvoicModal}
        centered
        size="xl"
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Header closeButton className="bg-primary bg-opacity-10">
          <Modal.Title className="fs-6 text-primary lh-sm">
            <i className="bi bi-receipt-cutoff"></i> Add Invoice/Expense
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="info-card-wrapper">
          <div className="form-section bg-secondary bg-opacity-10 p-3 p-lg-4">
            <form
              action=""
              className="w-100 mx-auto"
              style={{ maxWidth: "700px" }}
            >
              <Form.Group className="mb-3" controlId="BillerName">
                <Form.Label>
                  Biller Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  value={cmp_id}
                  onChange={(e) => {
                    const selectedIndex = e.target.selectedIndex;
                    const selectedCompany = companyList[selectedIndex - 1]; // minus 1 to account for the default option

                    setCompany(selectedCompany.id); // Ensure setCompany updates cmp_id correctly
                    setCompanyData(selectedCompany);

                    // Log the selected company to verify
                    console.log("Selected Company:", selectedCompany);

                    // Use the selected company's ID to update formData
                    setFormData({
                      ...formData,
                      cmp_id: selectedCompany.id,
                    });

                    dispatch(
                      getProjectListById(
                        localStorage.getItem("lead_emp_id"),
                        selectedCompany.id
                      )
                    );
                  }}
                  required
                >
                  <option selected disabled value="">
                    Select Company
                  </option>
                  {companyList?.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="CaseName">
                <Form.Label>
                  Case Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  value={prj_id}
                  onChange={(e) => {
                    const selectedIndex = e.target.selectedIndex;
                    const selected = projectList[selectedIndex - 1]; // minus 1 to account for the default option

                    setProjectData(selected);
                    setProjectId(e.target.value);

                    console.log("Selected Project:", selected);
                    console.log("Selected Project ID:", selected.id);

                    // Use the selected project's ID to update formData
                    setFormData({
                      ...formData,
                      prj_id: selected.id,
                    });

                    console.log("Form Data after update:", {
                      ...formData,
                      prj_id: selected.id,
                    });
                  }}
                  required
                >
                  <option selected disabled value="">
                    Select case name
                  </option>
                  {projectList?.map((e) => {
                    return <option value={e.id}>{e.name}</option>;
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="BillingDate">
                <Form.Label>
                  Date<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  value={formData?.invoice_date}
                  onChange={handleInputInvoiceChange}
                  name="invoice_date"
                  type="date"
                  defaultValue="2021/10/11"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="DateRange">
                <Form.Label>
                  Date Range<span className="text-danger">*</span>
                </Form.Label>
                <div className="row g-3">
                  <div className="col-sm-6">
                    <Form.Control
                      type="date"
                      value={formData?.invoice_from}
                      onChange={handleInputInvoiceChange}
                      name="invoice_from"
                      defaultValue="2021/10/11"
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <Form.Control
                      type="date"
                      value={formData?.invoice_to}
                      onChange={handleInputInvoiceChange}
                      name="invoice_to"
                      defaultValue="2021/11/11"
                      required
                    />
                  </div>
                </div>
              </Form.Group>
              <div className="btn-wrapper text-center pt-2">
                <Button type="submit" variant="primary" className="px-4 py-2">
                  Generate invoice
                </Button>
              </div>
            </form>
          </div>
          <div className="address-section my-4">
            <div className="row g-3">
              <div className="col-sm-6 col-xl-8">
                <address className="fs-14 text-capitalize m-0">
                  <p className="fw-semibold mb-0">{company.name}</p>
                  <p className="mb-0">{company.address1}</p>
                  <p className="mb-0">
                    {company.address2 +
                      " " +
                      company.cnt_iso +
                      " " +
                      company.zip}
                  </p>
                  <p className="mb-0">Phone: {company.phone}</p>
                  <p className="mb-0">Fax: {company.fax}</p>
                  <p className="mb-0">
                    URL: <span className="text-lowercase">{company.url}</span>
                  </p>
                </address>
              </div>
              <div className="col-sm-6 col-xl-4">
                <address className="fs-14 text-capitalize m-0">
                  <p className="fw-semibold mb-0">{projectData.name}</p>
                  <p className="mb-0">3790 EL Camino Real Suite 225</p>
                  <p className="mb-0">Palo Alto Office, CA 94306</p>
                  <p className="mb-0">Phone: (887)700-OIPL(6474)</p>
                  <p className="mb-0">Fax: (808) 791-8377</p>
                  <p className="mb-0">
                    URL:{" "}
                    <span className="text-lowercase">www.carolinacas.com</span>
                  </p>
                </address>
              </div>
            </div>
          </div>

          <div className="table-content-wrapper">
            <div className="bg-secondary bg-opacity-10 mb-3 p-3">
              <div className="row g-3">
                <div className="col-lg-4">
                  <Form.Group controlId="BillingDate">
                    <Form.Label>
                      Order No:<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={formData?.order_no}
                      onChange={handleInputInvoiceChange}
                      name="order_no"
                      defaultValue="123"
                      autoComplete="off"
                      required
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group controlId="BillingDate">
                    <Form.Label>
                      Adjuster:<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue="Test"
                      value={formData?.adjuster}
                      onChange={handleInputInvoiceChange}
                      name="adjuster"
                      autoComplete="off"
                      required
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group controlId="BillingDate">
                    <Form.Label>
                      Shipped By:<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={formData?.shipped_by}
                      onChange={handleInputInvoiceChange}
                      name="shipped_by"
                      defaultValue="Test"
                      autoComplete="off"
                      required
                    />
                  </Form.Group>
                </div>
              </div>
            </div>

            <div className="table-responsive table-custom-wrapper rounded-0 fs-14">
              <table className="table table-bordered border-secondary align-middle m-0">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Expenses
                    </th>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Price
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {invoice.map((invoices, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="date"
                          className="form-control"
                          id="expDate"
                          name="expDate"
                          value={invoices?.date}
                          onChange={(e) =>
                            handleInputChangeInvoice(
                              index,
                              "date",
                              e.target.value
                            )
                          }
                          defaultValue="2021-10-11"
                        />
                      </td>
                      <td>
                        <select
                          name="expType"
                          id="expType"
                          className="form-select"
                          value={invoices.expenses}
                          onChange={(e) =>
                            handleInputChangeInvoice(
                              index,
                              "expenses",
                              e.target.value
                            )
                          }
                        >
                          <option selected disabled value="">
                            Select Expenses
                          </option>
                          <option value="Miles">Miles</option>
                          <option value="Lodging">Lodging</option>
                          <option value="Meals">Meals</option>
                          <option value="Hour worked">Hour worked</option>
                          <option value="Other">Other</option>
                        </select>
                      </td>
                      <td className="text-nowrap text-capitalize">
                        <textarea
                          rows={"2"}
                          name="expDescription"
                          id="expDescription"
                          className="form-control"
                          style={{ height: "45px", resize: "none" }}
                          value={invoices?.description}
                          onChange={(e) =>
                            handleInputChangeInvoice(
                              index,
                              "description",
                              e.target.value
                            )
                          }
                        ></textarea>
                      </td>
                      <td className="text-nowrap text-capitalize">
                        <input
                          type="text"
                          name="expPrice"
                          id="expPrice"
                          className="form-control"
                          defaultValue={"50"}
                          autoComplete="off"
                          value={invoices?.price}
                          // autoComplete="off"
                          onChange={(e) =>
                            handleInputChangeInvoice(
                              index,
                              "price",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <div className="action-btn d-flex gap-2">
                          <Button
                            variant="success"
                            title="Add"
                            onClick={handleAddInvoice}
                          >
                            <i className="bi bi-plus-lg"></i>
                          </Button>
                          <Button
                            variant="danger"
                            title="Remove"
                            onClick={() => handleRemoveInvoice(index)}
                          >
                            <i className="bi bi-dash-lg"></i>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td colSpan={"3"} className="text-primary">
                      <p className="m-0">It was a Pleasure to work with you.</p>
                      <p className="m-0">
                        An Itemized invoice can be provided at client's request.
                      </p>
                      <p className="m-0">All services are net 30*</p>
                    </td>
                    <td className="text-primary text-end mx-3">
                      <p className="d-flex align-items-center justify-content-end gap-1 text-nowrap m-0">
                        Subtotal($):{" "}
                        <span className="fs-6 fw-bold">{subTotal}</span>
                      </p>
                      <p className="d-flex align-items-center justify-content-end gap-1 text-nowrap m-0">
                        Shipping/Handling($):{" "}
                        <span className="fs-6 fw-bold">10</span>
                      </p>
                      <hr className="mx-n2 my-1" />
                      <p className="d-flex align-items-center justify-content-end gap-1 text-nowrap m-0">
                        Total Amount due($):{" "}
                        <span className="fs-5 fw-bold">{subTotal + 10}</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="text-center mt-3 mb-2">
              <Button
                variant="primary"
                className="px-4 py-2"
                onClick={addInvoice}
              >
                Add Invoice
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Preview Invoice/Expense Modal Code */}
      <Modal
        show={showPreviewInvoice}
        onHide={closePreviewInvoiceModal}
        centered
        size="xl"
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Header closeButton className="bg-primary bg-opacity-10">
          <Modal.Title className="fs-6 text-primary lh-sm">
            <i className="bi bi-receipt-cutoff"></i> Preview Invoice/Expense
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="info-card-wrapper px-xl-5 py-4">
          <div className="address-section mb-4">
            <div className="row g-3">
              <div className="col-sm-6 col-xl-8">
                <address className="fs-14 text-capitalize m-0">
                  <p className="fw-semibold mb-0">
                    Assurant Specialty Property
                  </p>
                  <p className="mb-0">A Lane</p>
                  <p className="mb-0">Marino US 53422</p>
                  <p className="mb-0">Phone: 567-243-5345</p>
                  <p className="mb-0">Fax: 567-243-5345</p>
                  <p className="mb-0">
                    URL: <span className="text-lowercase">www.asp.com</span>
                  </p>
                </address>
              </div>
              <div className="col-sm-6 col-xl-4">
                <address className="fs-14 text-capitalize m-0">
                  <p className="fw-semibold mb-0">Optimal Intelligence, Inc.</p>
                  <p className="mb-0">3790 EL Camino Real Suite 225</p>
                  <p className="mb-0">Palo Alto Office, CA 94306</p>
                  <p className="mb-0">Phone: (887)700-OIPL(6474)</p>
                  <p className="mb-0">Fax: (808) 791-8377</p>
                </address>
              </div>
            </div>
          </div>
          <div className="invoice-info-wrapper">
            <div className="row g-3 mb-4">
              <div className="col-lg-6 col-xl-8">
                <div className="text-primary fs-14 text-capitalize m-0">
                  <div className="d-flex gap-1 mb-0">
                    <span className="label fw-semibold">Invoice Number:</span>
                    <span className="label">Assu091120110911028</span>
                  </div>
                  <div className="d-flex gap-1 mb-0">
                    <span className="label fw-semibold">Invoice Range:</span>
                    <span className="label">11/03/2011 - 11/09/2011</span>
                  </div>
                  <div className="d-flex gap-1 mb-0">
                    <span className="label fw-semibold">Order No:</span>
                    <span className="label">12</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-4">
                <div className="text-primary fs-14 text-capitalize m-0">
                  <div className="d-flex gap-1 mb-0">
                    <span className="label fw-semibold">Adjuster:</span>
                    <span className="label">Smith</span>
                  </div>
                  <div className="d-flex gap-1 mb-0">
                    <span className="label fw-semibold">Shipped By:</span>
                    <span className="label">johny</span>
                  </div>
                  <div className="d-flex gap-1 mb-0">
                    <span className="label fw-semibold">Order Date:</span>
                    <span className="label">11/09/2011</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive table-custom-wrapper rounded-0 fs-14">
              <table className="table table-bordered border-secondary align-middle m-0">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="fw-medium h-auto"
                      style={{ minWidth: "170px" }}
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="fw-medium h-auto"
                      style={{ minWidth: "170px" }}
                    >
                      Expenses
                    </th>
                    <th
                      scope="col"
                      className="fw-medium h-auto"
                      style={{ minWidth: "170px" }}
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="fw-medium h-auto"
                      style={{ minWidth: "170px" }}
                    >
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[...Array(3)].map((elementInArray, index) => (
                    <tr key={index}>
                      <td>11/03/2011</td>
                      <td>Miles</td>
                      <td>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </td>
                      <td>45.00</td>
                    </tr>
                  ))}

                  <tr>
                    <td colSpan={"3"} className="text-primary">
                      <p className="m-0">It was a Pleasure to work with you.</p>
                      <p className="m-0">
                        An Itemized invoice can be provided at client's request.
                      </p>
                      <p className="m-0">All services are net 30*</p>
                    </td>
                    <td className="text-primary text-end mx-3">
                      <p className="d-flex align-items-center justify-content-end gap-1 text-nowrap fw-medium m-0">
                        Subtotal($): <span className="fs-6 fw-bold">70</span>
                      </p>
                      <p className="d-flex align-items-center justify-content-end gap-1 text-nowrap fw-medium m-0">
                        Shipping/Handling($):{" "}
                        <span className="fs-6 fw-bold">10</span>
                      </p>
                      <hr className="mx-n2 my-1" />
                      <p className="d-flex align-items-center justify-content-end gap-1 text-nowrap fw-medium m-0">
                        Total Amount due($):{" "}
                        <span className="fs-5 fw-bold">80</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="btn-wrapper d-flex justify-content-center gap-2 mt-3 mb-xl-3">
              <Link
                to="mailto:recipient@example.com"
                className="btn btn-warning btn-sm px-3"
              >
                <i className="bi bi-envelope-at-fill"></i> Email
              </Link>
              <Link
                download="dummy-user.jpeg"
                className="btn btn-success btn-sm px-3"
              >
                <i className="bi bi-download"></i> Download PDF
              </Link>
              <Link variant="info" className="btn btn-info btn-sm px-3">
                <i className="bi bi-printer"></i> Print
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Add Expenses Modal Code */}
      <Modal
        show={showExpense}
        onHide={closeExpenseModal}
        centered
        size="xl"
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Header closeButton className="bg-primary bg-opacity-10">
          <Modal.Title className="fs-6 text-primary lh-sm">
            <i className="bi bi-receipt-cutoff"></i> Add Expenses
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-md-4 px-xl-5 py-xl-4">
          <div className="fs-4 fw-semibold text-center lh-sm mb-3">
            Expense Report for Case{" "}
            <span className="text-capitalize">{projectFullDetails.name}</span>
          </div>
          <div className="row justify-content-between g-3 fs-14 mb-3">
            <div className="col-lg-6 col-xl-5">
              <div className="client-name d-flex text-capitalize mb-1">
                <span
                  className="d-inline-block fw-medium me-2"
                  style={{ minWidth: "120px" }}
                >
                  Client Name:
                </span>
                <span className="text-muted">
                  {projectFullDetails.lead_cus_id}
                </span>
              </div>
              <div className="case-name d-flex text-capitalize mb-1">
                <span
                  className="d-inline-block fw-medium me-2"
                  style={{ minWidth: "120px" }}
                >
                  Case Name:
                </span>
                <span className="text-muted">{projectFullDetails.name}</span>
              </div>
              <div className="address d-flex text-capitalize mb-1">
                <span
                  className="d-inline-block fw-medium me-2"
                  style={{ minWidth: "120px" }}
                >
                  Address:
                </span>
                <address className="text-muted m-0">
                  PO Box 2575
                  <br />
                  Jacksonville
                  <br />
                  US 32203
                </address>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="emp-name d-flex text-capitalize mb-1">
                <span
                  className="d-inline-block fw-medium me-2"
                  style={{ minWidth: "120px" }}
                >
                  Employee Name:
                </span>
                <span className="text-muted">
                  {projectFullDetails.lead_emp_id}
                </span>
              </div>
              <div className="emp-id d-flex text-capitalize mb-1">
                <span
                  className="d-inline-block fw-medium me-2"
                  style={{ minWidth: "120px" }}
                >
                  Employee ID:
                </span>
                <span className="text-muted">{expenseListId.emp_id}</span>
              </div>
              <div className="claim-number d-flex text-capitalize mb-1">
                <span
                  className="d-inline-block fw-medium me-2"
                  style={{ minWidth: "120px" }}
                >
                  Claim Number:
                </span>
                <span className="text-muted">{expenseListId.claim_number}</span>
              </div>
            </div>
          </div>
          <div className="table-content-wrapper">
            <div className="exp-period d-flex flex-column flex-md-row flex-wrap gap-2 align-items-md-center fs-14 text-capitalize mb-2">
              <span className="fw-medium me-2">Expense Period:</span>
              <span className="d-flex flex-column flex-sm-row align-items-sm-center gap-2">
                <div className="field-cover d-flex flex-column flex-sm-row align-items-sm-center gap-2">
                  <label htmlFor="expFrom">From</label>
                  <input
                    type="date"
                    id="expFrom"
                    className="form-control rounded-0"
                    value={expFrom}
                    onChange={(e) => setExpFrom(e.target.value)}
                    style={{ minHeight: "38px" }}
                  />
                </div>
                <div className="field-cover d-flex flex-column flex-sm-row align-items-sm-center gap-2">
                  <label htmlFor="expTo">To</label>
                  <input
                    type="date"
                    id="expTo"
                    className="form-control rounded-0"
                    value={expTo}
                    onChange={(e) => setExpTo(e.target.value)}
                    style={{ minHeight: "38px" }}
                  />
                </div>
              </span>
            </div>
            <div className="table-responsive table-custom-wrapper fs-14">
              <table className="table table-hover align-middle m-0">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Expenses
                    </th>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Price
                    </th>
                    <th scope="col" className="fw-medium text-nowrap">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {expenses?.length > 0 ? (
                    expenses.map((expense, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="date"
                            className="form-control"
                            id="expDate"
                            name="expDate"
                            value={expense?.date}
                            onChange={(e) =>
                              handleInputChange(index, "date", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <select
                            name="expType"
                            id="expType"
                            className="form-select"
                            value={expense.expenses}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "expenses",
                                e.target.value
                              )
                            }
                            // value={expense?.type}
                            // onChange={(e) =>
                            //   handleInputChange(index, "type", e.target.value)
                            // }
                          >
                            <option selected disabled value="">
                              Select Expenses
                            </option>
                            <option value="Miles">Miles</option>
                            <option value="Lodging">Lodging</option>
                            <option value="Meals">Meals</option>
                            <option value="Hour worked">Hour worked</option>
                            <option value="Other">Other</option>
                          </select>
                        </td>
                        <td className="text-nowrap text-capitalize">
                          <textarea
                            rows={"2"}
                            name="expDescription"
                            id="expDescription"
                            className="form-control"
                            style={{ height: "45px", resize: "none" }}
                            value={expense?.description}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "description",
                                e.target.value
                              )
                            }
                          ></textarea>
                        </td>
                        <td className="text-nowrap text-capitalize">
                          <input
                            type="text"
                            name="expPrice"
                            id="expPrice"
                            className="form-control"
                            value={expense?.price}
                            autoComplete="off"
                            onChange={(e) =>
                              handleInputChange(index, "price", e.target.value)
                            }
                          />
                        </td>
                        <td className="text-nowrap text-capitalize">
                          <div className="action-btn d-flex gap-2">
                            <Button
                              variant="success"
                              title="Add"
                              onClick={handleAddExpense}
                            >
                              <i className="bi bi-plus-lg"></i>
                            </Button>
                            <Button
                              variant="danger"
                              title="Remove"
                              onClick={() =>
                                handleRemoveExpense(index, expense.id)
                              }
                            >
                              <i className="bi bi-dash-lg"></i>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="btn-wrapper text-center mt-4">
            <button
              type="button"
              className="btn btn-primary px-4 py-2"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDelete}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Body className="p-md-4 p-lg-5 text-center">
          <div className="fs-18 fw-semibold lh-sm mb-4">
            Are you sure you want to delete this journal case?
          </div>
          <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3">
            <button
              type="button"
              className="btn btn-danger px-4"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary px-4"
              onClick={() => {
                dispatch(deleteExpense(expenseId));
                handleClose();
              }}
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
