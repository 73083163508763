import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import API_URL from '../../Apiurl';

const initialState = {
  isLoading: false,
  error: false,
  success: '',
  countryList: []
};

const slice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false; // Clear previous errors
      state.success = '';  // Clear previous success messages
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.error("Error:", action.payload); // Log error
    },

    getCountryListSuccess(state, action) {
      state.isLoading = false;
      state.countryList = action.payload.data;
      state.error = false;
      state.success = 'Country list fetched successfully!';
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  getCountryListSuccess,
} = slice.actions;

// Async Action
export function getCountryList() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}list-country`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getCountryListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch country list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the country list"));
    }
  };
}
