import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { changePassword } from "../redux/slices/user";
import { useSelector, useDispatch } from "react-redux";

export const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(currentPassword);
    console.log(newPassword);
    console.log(confirmPassword);

    if (newPassword !== confirmPassword) {
      setError("New password and confirm password do not match.");
      return;
    }

    try {
      dispatch(changePassword({
        username: JSON.parse(localStorage.getItem('username')),
        password: currentPassword,
        new_password :newPassword
      }))

      setSuccess("Password successfully changed.");
      setError("");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      setError(
        error.response?.data?.message ||
          "An error occurred while changing the password."
      );
      setSuccess("");
    }
  };

  return (
    <div className="ChangePassword-page py-3">
      <div className="container-fluid mt-2 mt-md-4">
        <div className="form-wrapper bg-white rounded-4 mx-auto px-3 py-4 p-sm-5" style={{ maxWidth: "600px" }} >
          <div className="heading-wrapper mt-sm-n2 mb-3">
            <div className="fs-4 fw-semibold mb-1">Change Password</div>
            <p className="small text-secondary">
              Password must contain at least 1 letter, 1 number, and 1 symbol.
              Minimum length is 8 characters.
            </p>
          </div>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">{success}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="currentPassword">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                // pattern="^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_]).{8,}$"
                // title="Password must contain at least 1 letter, 1 number, and 1 symbol, and be at least 8 characters long"
                // minLength={"8"}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                // pattern="^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_]).{8,}$"
                // title="Password must contain at least 1 letter, 1 number, and 1 symbol, and be at least 8 characters long"
                // minLength={"8"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="confirmPassword">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                // pattern="^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_]).{8,}$"
                // title="Password must contain at least 1 letter, 1 number, and 1 symbol, and be at least 8 characters long"
                // minLength={"8"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </Form.Group>

            <div className="btn-wrapper text-center pt-3">
              <Button
                variant="primary"
                type="submit"
                className="w-100 px-4 py-2"
                style={{ minHeight: "50px" }}
              >
                Change Password
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
