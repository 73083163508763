import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import API_URL from '../../Apiurl';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  error: false,
  success: '',
  journalList: [],
  filesList: [],
  filesListCustomer:[],
  deleteLoading: false,
  deleteSuccess: '',
  deleteError: '',
  journalDetails: null
};

const slice = createSlice({
  name: 'journal',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false; // Clear previous errors
      state.success = '';  // Clear previous success messages
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.error("Error:", action.payload); // Log error
    },

    // GET CUSTOMER LIST SUCCESS
    getJournalListSuccess(state, action) {
      state.isLoading = false;
      state.journalList = action.payload.data;
      state.error = false;
      state.success = 'Journal list fetched successfully!';
    },
    getFilesListSuccess(state, action) {
      state.isLoading = false;
      state.filesList = action.payload.data;
      state.error = false;
      state.success = 'Files list fetched successfully!';
    },
    
    getFilesProjectListSuccess(state, action) {
      state.isLoading = false;
      state.filesListCustomer = action.payload.data;
      state.error = false;
      state.success = 'Files list fetched successfully!';
    },

    // START DELETE LOADING
    startDeleteLoading(state) {
      state.deleteLoading = true;
      state.deleteError = false; // Clear previous errors
      state.deleteSuccess = ''; // Clear previous success messages
    },

    // DELETE HAS ERROR
    deleteHasError(state, action) {
      state.deleteLoading = false;
      state.deleteError = action.payload;
      console.error("Delete Error:", action.payload); // Log error
    },

    // DELETE CUSTOMER SUCCESS
    deleteJournalSuccess(state, action) {
      state.deleteLoading = false;
      state.deleteSuccess = 'journal deleted successfully!';
      state.deleteError = false;

      // Optionally, remove the deleted journal from the journalList
      state.journalList = state.journalList?.filter(journal => journal.id !== action.payload.id);
    },
    getJournalDetailsSuccess(state, action) {
      state.isLoading = false;
      state.journalDetails = action.payload.data;
      state.error = false;
    }  
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  getJournalListSuccess,
  getFilesListSuccess,
  getFilesProjectListSuccess,
  startDeleteLoading,
  deleteHasError,
  deleteJournalSuccess,
  getJournalDetailsSuccess
} = slice.actions;

// Async Action to Get Journal List
export function getJournalList() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}list-journal`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getJournalListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch journal list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the journal list"));
    }
  };
}
export function getJournalListId(id) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}project-journal/${id}`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getJournalListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch journal list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the journal list"));
    }
  };
}
export function getProjectFilesListId(id) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}project-files/${id}`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        await dispatch(getFilesListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch journal list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the journal list"));
    }
  };
}
export function projectFilesCustomer() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const id = localStorage.getItem("lead_cus_id");
      const resp = await axios.get(`${API_URL}project-files-id/${id}`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getFilesProjectListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch journal list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the journal list"));
    }
  };
}
export function projectFilesId(id) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      // const id = localStorage.getItem("prj_id");
      const resp = await axios.get(`${API_URL}project-files-project-id/${id}`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getFilesProjectListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch journal list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the journal list"));
    }
  };
}
export function deleteProjectFile(id) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const resp = await axios.delete(`${API_URL}delete-project-files-id/${id}`);
      console.log("API delete response:", resp); // Log API response
      if (resp && resp.status === 200) {
        const prj_id = await localStorage.getItem("prj_id");
        toast("Case file deleted sucessfully!", {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
        await dispatch(getProjectFilesListId(prj_id))
      } else {
        dispatch(deleteHasError(resp.message || "Failed to delete journal"));
      }
    } catch (error) {
      console.error("Caught delete error:", error); // Log caught error
      dispatch(deleteHasError(error.message || "An error occurred while deleting the Journal"));
    }
  };
}

// Async Action to Add Journal
export function addJournal(e) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.post(`${API_URL}add-journal`, e);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getJournalListId(localStorage.getItem("prj_id")));
        toast("Journal added sucessfully!", {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
      } else {
        dispatch(hasError(resp.message || "Failed to add journal"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while adding the journal"));
    }
  };
}
export function addJournalId(e) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.post(`${API_URL}add-journal`, e);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        // dispatch(getJournalListId(localStorage.getItem("prj_id")));
        toast("Journal added sucessfully!", {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
      } else {
        dispatch(hasError(resp.message || "Failed to add journal"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while adding the journal"));
    }
  };
}

// Async Action to Delete journal
export function deleteJournal(journalId) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const resp = await axios.delete(`${API_URL}delete-journal/${journalId}`);
      console.log("API delete response:", resp); // Log API response
      if (resp && resp.status === 200) {
        // dispatch(deleteJournalSuccess({ id: journalId }));
        // dispatch(getJournalList());
        dispatch(getJournalListId(localStorage.getItem("prj_id")));
      } else {
        dispatch(deleteHasError(resp.message || "Failed to delete journal"));
      }
    } catch (error) {
      console.error("Caught delete error:", error); // Log caught error
      dispatch(deleteHasError(error.message || "An error occurred while deleting the Journal"));
    }
  };
}
export function getJournalDetails(journalId) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}view-journal/${journalId}`);
      console.log("API response:", resp);
      if (resp && resp.status === 200) {
        dispatch(getJournalDetailsSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch Journal details"));
      }
    } catch (error) {
      console.error("Caught details error:", error);
      dispatch(hasError(error.message || "An error occurred while fetching the Journal details"));
    }
  };
}
export function editJournal(id, e) {
  return async (dispatch) => {
    console.log(e)
    dispatch(startLoading());
    try {
      const resp = await axios.put(`${API_URL}update-journal/${id}`, e);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getJournalList());
        toast("Journal updated sucessfully!", {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
      } else {
        dispatch(hasError(resp.message || "Failed to add Journal"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while adding the Journal"));
    }
  };
}
