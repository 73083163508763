import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import BarChart from "./BarChart";
import { getDashboardChart } from "../redux/slices/employee";
import { useSelector, useDispatch } from "react-redux";
import LineChart from "./LineChart";
import { useNavigate, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import { Form, Tabs, Tab, Button } from "react-bootstrap";
import SearchIcon from "../assets/search.svg";
import {
  getProjectListId,
  deleteProject,
  addProjectFiles,
} from "../redux/slices/project";
import { NoDataComponent } from "./NoDataComponent";
import { event } from "jquery";
import { getEventList, deleteEvent } from "../redux/slices/event";

export const Dashboard = () => {
  const { dashboard } = useSelector((state) => state.employee);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [projectId, setProjectId] = useState(0);
  const [showFilesModal, setFilesModal] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const [meetingId, setMeetingId] = useState("");
  
  const [formData, setFormData] = useState({
    prj_id: projectId,
    cmp_id: localStorage.getItem("cmp_id"),
    name: "",
    path: "project_files/",
    size: "",
    type: "",
    description: "",
    status: "A",
    is_processed: "YES",
    file: [],
  });
  const handleRowClick = (row) => {
    navigate(`/journal-cases`);
    localStorage.setItem("prj_id", JSON.stringify(row.id));
  };
  const openFilesModal = (id) => {
    setProjectId(id);
    setFilesModal(true);
  };
  const handleShow2 = (id) => {
    // debugger
    console.log(id)
    setMeetingId(id);
    setShow2(true)
  }
  const handleViewDetails = (companyId) => {
    navigate("/edit-case/" + companyId, { state: { companyId } });
  };
  const [show, setShow] = useState(false);

  // For Tabs Navigation
  const [activeTab, setActiveTab] = useState("tab1");

  // For User Log Information Modal
  const [showUserLog, setShowUserLog] = useState(false);
  const openUserLogModal = () => setShowUserLog(true);
  const closeUserLogModal = () => setShowUserLog(false);

  // For Case Journal Details Modal
  const [showJournalDetails, setShowJournalDetails] = useState(false);
  const openJournalDetailsModal = () => setShowJournalDetails(true);
  const closeJournalDetailsModal = () => setShowJournalDetails(false);
  const handleSubmit = async () => {
    // e.preventDefault();
    console.log(formData);
    dispatch(addProjectFiles(formData));
    setFilesModal(false);
  };

  const customStyles = {
    rows: {
      style: {
        fontSize: "14px",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "600",
        padding: "5px 12px",
        color: "#fff",
        backgroundColor: "var(--bs-primary)",
        minHeight: "45px",
      },
    },
    cells: {
      style: {
        color: "#3f474f",
        fontSize: "14px",
        padding: "5px 12px",
        minHeight: "40px",
      },
    },
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      description: value,
    });
  };
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    // console.log(user_id);
    const data = {
      user_id: user?.user_id,
      usertype: JSON.parse(localStorage.getItem("usertype")),
    };
    dispatch(getDashboardChart(data));
  }, [dispatch]);
  const handleClose = () => setShow(false);

  const handleShow = (row) => {
    setShow(true);
    setProjectId(row.id);
  };

  // console.log(dashboard);

  const barchartData = {
    labels: dashboard?.label,
    datasets: [
      {
        label: "Sales",
        data: dashboard?.values,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };
  console.log(dashboard?.event_list);
  const handleShowTabs = (row) => {
    console.log(row);
    localStorage.setItem("lead_cus_id", JSON.stringify(row.lead_cus_id));
    localStorage.setItem("lead_emp_id", JSON.stringify(row.lead_emp_id));
    localStorage.setItem("prj_id", JSON.stringify(row.id));
    navigate("/workspace/" + row.id);
  };

  const columns1 = [
    // { name: "Sr. No", selector: (row) => row.id, sortable: true },
    { name: "Project Name", selector: (row) => row.name, sortable: true },
    {
      name: "Project Type",
      selector: (row) => row.project_type,
      sortable: true,
    },
    {
      name: "Project Status",
      selector: (row) => row.project_status_name,
      sortable: true,
    },
    // { name: "Status", selector: (row) => row.status, sortable: true },
    { name: "Company Name", selector: (row) => row.name, sortable: true },
    {
      name: "Employee Lead",
      selector: (row) =>
        row.salutation1 + " " + row.first_name1 + " " + row.last_name1,
      sortable: true,
    },
    {
      name: "Client Lead",
      selector: (row) =>
        row.salutation2 + " " + row.first_name2 + " " + row.last_name2,
      sortable: true,
    },
    // { name: "Spec", selector: (row) => row.specs, sortable: true },
    {
      name: "Add Files",
      cell: (row) => (
        <button
          className="btn btn-success btn-sm"
          onClick={() => openFilesModal(row.id)}
        >
          <i className="bi bi-folder-plus"></i> Add Files
        </button>
      ),
      sortable: true,
    },
    // {
    //   name: "Action",
    //   cell: (row) => (
    //     <div className="action-wrapper d-flex gap-2">
    //       {/* <button
    //         className="btn btn-danger btn-sm"
    //         title="Workspaces"
    //         onClick={() => handleShowTabs(row)}
    //       >
    //         <span>W</span>
    //       </button> */}
    //       {/* <button
    //         className="btn btn-warning btn-sm"
    //         onClick={() => handleViewDetails(row.id)}
    //         title="Edit"
    //       >
    //         <i className="bi bi-pencil-fill fs-14"></i>
    //       </button> */}
    //       {/* <button
    //         className="btn btn-danger btn-sm"
    //         title="Delete"
    //         onClick={() => handleShow(row)}
    //       >
    //         <i className="bi bi-trash3 fs-14"></i>
    //       </button> */}
    //     </div>
    //   ),
    // },
  ];
  const closeFilesModal = () => setFilesModal(false);

  const handleFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      console.log(file);
      await setFormData({
        ...formData,
        name: file.name,
        type: file.type,
        size: file.size,
        prj_id: projectId,
        file: file,
      });
    }
    console.log(formData);
  };

  const columns = [
    // {
    //   name: "Sr. No",
    //   selector: (row, index) => index + 1,
    //   sortable: true,
    //   maxWidth: "90px",
    // },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Start time",
      selector: (row) => row.start_time,
      sortable: true,
      maxWidth: "120px",
    },
    {
      name: "End time",
      selector: (row) => row.end_time,
      sortable: true,
      maxWidth: "120px",
    },
    {
      name: "Project Name",
      selector: (row) => row.name,
      sortable: true,
    },
    // {
    //   name: "Action",
    //   cell: (row) => (
    //     <div className="action-wrapper d-flex gap-2">
    //       <Button
    //         variant="success"
    //         className="d-flex align-items-center justify-content-center"
    //         title="View"
    //       >
    //         <i className="bi bi-eye-fill fs-14"></i>
    //       </Button>
    //       <Button
    //         variant="warning"
    //         className="d-flex align-items-center justify-content-center"
    //         title="Edit"
    //       >
    //         <i className="bi bi-pencil-fill fs-14"></i>
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  const linechartData = {
    labels: dashboard?.projectsByDay,
    datasets: [
      {
        label: "Cases",
        data: dashboard?.count_days,
        borderColor: "rgba(153, 102, 255, 1)",
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        fill: true,
      },
      {
        label: "Files Uploaded",
        data: dashboard?.count_files,
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        fill: true,
      },
      {
        label: "Client",
        data: dashboard?.count_customer,
        borderColor: "rgba(50, 50, 20, 1)",
        backgroundColor: "rgba(50, 50, 20, 1, 0.2)",
        fill: true,
      },
    ],
  };

  const handleClick = (id) => {
    localStorage.setItem("prj_id", id);
    navigate(`/cases-details/${id}`);
  };

  // console.log(dashboard);
  const event_list = dashboard?.event_list;
  return (
    <div className="dashboard-page py-3">
      <div className="container-fluid">
        {/* <section className="info-card-sec mb-3">
          <div className="fs-5 fw-bold mb-2">Dashboard</div>
          <div className="row gy-1 gx-3">
            <div className="col-sm-6 col-xl-3">
              <div className="card-1 bg-white rounded-3 pointer h-100 p-3 py-xl-4">
                <div className="d-flex align-items-center gap-3">
                  <div className="card-icon bg-primary bg-opacity-10 d-flex align-items-center justify-content-center rounded-circle">
                    <i className="bi bi-bar-chart text-primary fs-4"></i>
                  </div>

                  <div className="card-details">
                    <div className="label-value fs-18 fw-semibold lh-1 mb-1">1478286</div>
                    <div className="label-title text-secondary text-opacity-50 fs-14 fw-medium lh-sm mb-1">Total Visits</div>
                    <div className="fs-14 d-flex flex-wrap column-gap-2">
                      <div className="progress-points d-flex gap-1 text-green"><i className="bi bi-graph-up-arrow"></i> 4.07%</div>
                      <div className="month text-secondary text-opacity-50">Last Month</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3">
              <div className="card-2 bg-white rounded-3 pointer h-100 p-3 py-xl-4">
                <div className="d-flex align-items-center gap-3">
                  <div className="card-icon bg-warning bg-opacity-10 d-flex align-items-center justify-content-center rounded-circle">
                    <i className="bi bi-file-earmark text-warning fs-4"></i>
                  </div>

                  <div className="card-details">
                    <div className="label-value fs-18 fw-semibold lh-1 mb-1">1478286</div>
                    <div className="label-title text-secondary text-opacity-50 fs-14 fw-medium lh-sm mb-1">Total Visits</div>
                    <div className="fs-14 d-flex flex-wrap column-gap-2">
                      <div className="progress-points d-flex gap-1 text-green"><i className="bi bi-graph-up-arrow"></i> 0.24%</div>
                      <div className="month text-secondary text-opacity-50">Last Month</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3">
              <div className="card-3 bg-white rounded-3 pointer h-100 p-3 py-xl-4">
                <div className="d-flex align-items-center gap-3">
                  <div className="card-icon d-flex align-items-center justify-content-center rounded-circle">
                    <i className="bi bi-file-earmark fs-4"></i>
                  </div>

                  <div className="card-details">
                    <div className="label-value fs-18 fw-semibold lh-1 mb-1">1478286</div>
                    <div className="label-title text-secondary text-opacity-50 fs-14 fw-medium lh-sm mb-1">Total Visits</div>
                    <div className="fs-14 d-flex flex-wrap column-gap-2">
                      <div className="progress-points d-flex gap-1 text-danger"><i className="bi bi-graph-up-arrow"></i> 0.24%</div>
                      <div className="month text-secondary text-opacity-50">Last Month</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3">
              <div className="card-4 bg-white rounded-3 pointer h-100 p-3 py-xl-4">
                <div className="d-flex align-items-center gap-3">
                  <div className="card-icon d-flex align-items-center justify-content-center rounded-circle">
                    <i className="bi bi-feather2 text-green fs-3" style={{ transform: 'rotate(45deg)' }}></i>
                  </div>

                  <div className="card-details">
                    <div className="label-value fs-18 fw-semibold lh-1 mb-1">1478286</div>
                    <div className="label-title text-secondary text-opacity-50 fs-14 fw-medium lh-sm mb-1">Total Visits</div>
                    <div className="fs-14 d-flex flex-wrap column-gap-2">
                      <div className="progress-points d-flex gap-1 text-warning"><i className="bi bi-graph-up-arrow"></i> 0.00%</div>
                      <div className="month text-secondary text-opacity-50">Last Month</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </section> 
          </div>
        </section>*/}

        <section className="chart-section mb-3">
          {localStorage.getItem("usertype") === '"E"' ? (
            <div className="row g-3">
              <div className="col-lg-6">
                <div className="chart-wrapper card border-0 p-3">
                  <BarChart chartData={barchartData} className="w-100" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="chart-wrapper card border-0 p-3">
                  <LineChart chartData={linechartData} className="w-100" />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </section>

        <section className="grid-divide-section">
          {/* <div className="row g-3"> */}
          {/* <div className="col-xl-8 col-xxl-9"> */}
          {localStorage.getItem("usertype") === '"E"' ? (
            <div className="accordion-wrapper">
              <Accordion
                defaultActiveKey="0"
                flush
                alwaysOpen
                className="d-flex flex-column gap-3"
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <i className="bi bi-image me-2"></i>{" "}
                    <span className="fw-semibold">Snapshots</span>
                  </Accordion.Header>
                  <Accordion.Body className="p-3">
                    <div
                      className="content-wrapper overflow-auto"
                      style={{ maxHeight: "400px" }}
                    >
                      <div className="table-responsive table-custom-wrapper fs-14">
                        <table className="table table-hover align-middle m-0">
                          <thead>
                            <tr>
                              {/* <th scope="col" className="fw-medium text-nowrap">
                                Sr. No
                              </th> */}
                              <th scope="col" className="fw-medium text-nowrap">
                                Name
                              </th>
                              <th scope="col" className="fw-medium text-nowrap">
                                New Today
                              </th>
                              <th scope="col" className="fw-medium text-nowrap">
                                Total
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {/* <td>1</td> */}
                              <td className="text-nowrap text-capitalize">
                                Clients
                              </td>
                              <td className="text-nowrap text-capitalize">
                                {dashboard?.customer_today}
                              </td>
                              <td className="text-nowrap text-capitalize">
                                {dashboard?.total_customer}
                              </td>
                            </tr>
                            <tr>
                              {/* <td>2</td> */}
                              <td className="text-nowrap text-capitalize">
                                Cases
                              </td>
                              <td className="text-nowrap text-capitalize">
                                {dashboard?.cases_today}
                              </td>
                              <td className="text-nowrap text-capitalize">
                                {dashboard?.cases_total}
                              </td>
                            </tr>
                            <tr>
                              {/* <td>3</td> */}
                              <td className="text-nowrap text-capitalize">
                                File Uploaded
                              </td>
                              <td className="text-nowrap text-capitalize">
                                {dashboard?.files_today}
                              </td>
                              <td className="text-nowrap text-capitalize">
                                {dashboard?.files_count}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <i className="bi bi-briefcase-fill me-2"></i>{" "}
                    <span className="fw-semibold">
                      My Cases ({dashboard?.count} Total)
                    </span>
                  </Accordion.Header>
                  <Accordion.Body className="p-3">
                    <div
                      className="content-wrapper overflow-auto"
                      style={{ maxHeight: "400px" }}
                    >
                      <div className="table-responsive table-custom-wrapper fs-14">
                        <table className="table table-hover align-middle m-0">
                          <tbody>
                            {dashboard?.list?.map((e) => {
                              return (
                                <tr>
                                  <td
                                    className="text-success fw-medium"
                                    onClick={() => handleClick(e.id)}
                                  >
                                    {e.name + " " + e.project_type_id}
                                  </td>
                                  {/* <td className="text-nowrap text-capitalize">
                                Customers
                              </td>
                              <td className="text-nowrap text-capitalize">0</td>
                              <td className="text-nowrap text-capitalize">4</td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <i className="bi bi-calendar2-week-fill me-2"></i>{" "}
                    <span className="fw-semibold">My Week</span>
                  </Accordion.Header>
                  <Accordion.Body className="p-3">
                    <div className="bg-secondary bg-opacity-10 p-3 table-responsive fs-14">
                      <table className="table table-bordered border-secondary border-opacity-25 m-0">
                        <tbody>
                          { event_list ? Object.keys(event_list).map((date) => {
                            return (
                              <tr key={date}>
                                <td className="text-success text-nowrap">
                                  {date}
                                </td>

                                <td>
                                  {event_list[date].map((event) => (
                                    <div
                                      key={event.event_id}
                                      className="project-wrapper d-flex flex-column gap-2 fw-medium"
                                    >
                                      <div className="d-flex justify-content-between row-gap-1 column-gap-5">
                                        <div className="d-flex flex-wrap column-gap-1 text-primary text-nowrap">
                                          <span>{event.title}</span>
                                          <span className="text-success">
                                            {event.prj_id}
                                          </span>
                                        </div>
                                        <div className="d-flex">
                                          <Link
                                            to={"#"}
                                            className="text-success"
                                          >
                                            Export/
                                          </Link>
                                          <Link
                                            to={'/edit-meeting/'+event.event_id}
                                            className="text-success"
                                          >
                                            Edit/
                                          </Link>
                                          <button
                                            // to={"#"}
                                            onClick={() => handleShow2(event.event_id)}
                                            className="text-success"
                                          >
                                            Delete
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </td>
                                <td>
                                  <a href="/add-meeting-dashboard">
                                    <div className="add-event d-flex gap-1 text-primary text-nowrap">
                                      <i className="bi bi-calendar-date-fill"></i>{" "}
                                      Add Event
                                    </div>
                                  </a>
                                </td>
                              </tr>
                            );
                          }) :<></>}
                        </tbody>
                      </table>
                    </div>

                    {/* <div className="table-responsive table-custom-wrapper mt-3">
                      <DataTable
                        columns={columns}
                        data={dashboard?.event_list || []}
                        pagination
                        highlightOnHover
                        noDataComponent={<NoDataComponent />}
                        // progressPending={isLoading}
                        customStyles={customStyles}
                        dense
                        responsive
                      />
                    </div> */}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          ) : (
            <></>
          )}

          {/* For User Visit */}
          {localStorage.getItem("usertype") === '"C"' ? (
            <div className="card border-0 rounded-3 shadow-sm p-3">
              <div className="searchfield-wrapper position-relative fs-6 ms-auto mb-3">
                <input
                  type="search"
                  className="form-control ms-auto"
                  placeholder="Search cases here..."
                  autoComplete="off"
                />
                <img
                  src={SearchIcon}
                  alt="Search Icon"
                  className="position-absolute top-50 end-0 translate-middle-y pe-3"
                />
              </div>
              <div className="table-responsive table-custom-wrapper">
                <DataTable
                  columns={columns1}
                  data={dashboard?.projects}
                  pagination
                  highlightOnHover
                  pointerOnHover
                  // progressPending={isLoading}
                  noDataComponent={<NoDataComponent />}
                  onRowClicked={handleShowTabs}
                  customStyles={customStyles}
                  dense
                  responsive
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* </div> */}

          {/* <div className="col-xl-4 col-xxl-3">
              <div className="card border-0 p-3">
                <div className="header">
                  <div className="title fs-6 fw-bold">Top Categories</div>
                  <div className="subtitle fs-12 text-secondary">28 Categories, 1400 Posts</div>
                </div>
                <div className="card-wrapper">
                  <div className="data-card fs-12 border-bottom mt-2 pb-2">
                    <div className="data-label d-flex flex-wrap align-items-center justify-content-between gap-2 text-body fw-medium">
                      <span className="fw-semibold">Lifestyle</span>
                      <span>8.2k</span>
                    </div>
                    <div className="data-value d-flex flex-wrap align-items-center justify-content-between gap-2 text-secondary">
                      <span>Daily lifestyle articles</span>
                      <span className="bg-primary bg-opacity-10 text-primary fs-10 fw-medium rounded-1 px-1">+472</span>
                    </div>
                  </div>
                  <div className="data-card fs-12 border-bottom mt-2 pb-2">
                    <div className="data-label d-flex flex-wrap align-items-center justify-content-between gap-2 text-body fw-medium">
                      <span className="fw-semibold">Tutorials</span>
                      <span>8.2k</span>
                    </div>
                    <div className="data-value d-flex flex-wrap align-items-center justify-content-between gap-2 text-secondary">
                      <span>Coding tutorials</span>
                      <span className="bg-danger bg-opacity-10 text-danger fs-10 fw-medium rounded-1 px-1">+472</span>
                    </div>
                  </div>
                  <div className="data-card fs-12 border-bottom mt-2 pb-2">
                    <div className="data-label d-flex flex-wrap align-items-center justify-content-between gap-2 text-body fw-medium">
                      <span className="fw-semibold">Technology</span>
                      <span>8.2k</span>
                    </div>
                    <div className="data-value d-flex flex-wrap align-items-center justify-content-between gap-2 text-secondary">
                      <span>Daily technology articles</span>
                      <span className="bg-success bg-opacity-10 text-success fs-10 fw-medium rounded-1 px-1">+472</span>
                    </div>
                  </div>
                  <div className="data-card fs-12 border-bottom mt-2 pb-2">
                    <div className="data-label d-flex flex-wrap align-items-center justify-content-between gap-2 text-body fw-medium">
                      <span className="fw-semibold">UX Design</span>
                      <span>8.2k</span>
                    </div>
                    <div className="data-value d-flex flex-wrap align-items-center justify-content-between gap-2 text-secondary">
                      <span>UX design tips</span>
                      <span className="bg-info bg-opacity-10 text-info fs-10 fw-medium rounded-1 px-1">+472</span>
                    </div>
                  </div>
                  <div className="data-card fs-12 border-bottom mt-2 pb-2">
                    <div className="data-label d-flex flex-wrap align-items-center justify-content-between gap-2 text-body fw-medium">
                      <span className="fw-semibold">Interaction Tips</span>
                      <span>8.2k</span>
                    </div>
                    <div className="data-value d-flex flex-wrap align-items-center justify-content-between gap-2 text-secondary">
                      <span>Interaction articles</span>
                      <span className="bg-secondary bg-opacity-10 text-secondary fs-10 fw-medium rounded-1 px-1">+472</span>
                    </div>
                  </div>
                  <div className="data-card fs-12 mt-2">
                    <div className="data-label d-flex flex-wrap align-items-center justify-content-between gap-2 text-body fw-medium">
                      <span className="fw-semibold">App Development</span>
                      <span>8.2k</span>
                    </div>
                    <div className="data-value d-flex flex-wrap align-items-center justify-content-between gap-2 text-secondary">
                      <span>Mobile development articles</span>
                      <span className="bg-warning bg-opacity-25 text-warning fs-10 fw-medium rounded-1 px-1">+472</span></div>
                  </div>
                </div>
              </div>
            </div> */}
          {/* </div> */}
        </section>

        {/* <div className="welcome-card d-flex gap-1 bg-white rounded p-3 mb-4">
          <div className="img-wrapper">
            <img
              src={require("../assets/waiving.gif")}
              alt="Waveing Hand"
              className="img-fluid"
              style={{ width: "45px", minWidth: "40px" }}
            />
          </div>
          <div className="welcome-desc">
            <div className="fs-18 mb-0 lh-sm">
              <span className="text-capitalize fw-semibold text-primary">
                Hi John,
              </span>{" "}
              Welcome back!
            </div>
            <div className="text-muted">
              This page provide information regarding company data!
            </div>
          </div>
        </div> */}
      </div>

      {/* Add File Form Modal Code */}
      <Modal
        show={showFilesModal}
        onHide={closeFilesModal}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Header closeButton className="bg-primary bg-opacity-10">
          <Modal.Title className="fs-6 text-primary lh-sm">
            <i className="bi bi-file-earmark-text-fill"></i> Add File Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="projectFile" className="mb-3">
              <Form.Label>
                File Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="file"
                className="shadow-none"
                // multiple
                required
                onChange={handleFileChange}
                style={{ minHeight: "auto" }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="fileDescription">
              <Form.Label>
                Description<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                autoComplete="off"
                maxLength={100}
                onChange={handleInputChange}
                name="description"
                placeholder="Enter your description here..."
                required
              />
              <div className="fs-12 text-secondary mt-1">
                Maximum 100 characters
              </div>
            </Form.Group>
            <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-2 pt-2 mb-3">
              <Button
                // type="submit"
                variant="primary"
                onClick={handleSubmit}
                className="px-4"
              >
                Add
              </Button>
              <Button type="reset" variant="danger" className="px-4">
                Reset
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Delete Modal Popup Code */}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Body className="p-md-4 p-lg-5 text-center">
          <div className="fs-18 fw-semibold lh-sm mb-4">
            Are you sure you want to delete this case
          </div>
          <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3">
            <button
              type="button"
              className="btn btn-danger px-4"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary px-4"
              onClick={() => {
                dispatch(deleteProject(projectId));
                handleClose();
              }}
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show2}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Body className="p-md-4 p-lg-5 text-center">
          <div className="fs-18 fw-semibold lh-sm mb-4">
            Are you sure you want to delete this Meeting?
          </div>
          <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3">
            <button
              type="button"
              className="btn btn-danger px-4"
              onClick={handleClose2}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary px-4"
              onClick={async () => {
                await dispatch(deleteEvent(meetingId));
                await handleClose2();
                // await dispatch(getProjectDetailsById(id));
                const user = JSON.parse(localStorage.getItem("user"));
                const data = {
                  user_id: user?.user_id,
                  usertype: JSON.parse(localStorage.getItem("usertype")),
                };
                dispatch(getDashboardChart(data));
              }}
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
