import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
import { Button, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "../redux/store";
import { login } from "../redux/slices/user";
import axios from "../utils/axios"; // Adjust the path as needed

export const Login = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("E"); // default to 'E'
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await axios("https://api.ipify.org?format=json");
    // const data = await response.json();
    // console.log(username,
    //   password,
    //   userType);
    // return
    try {
      dispatch(
        login(
          {
            username,
            password,
            usertype: userType,
            remote_addr: response.data.ip,
            platform: navigator.userAgent,
            message: "Login",
          },
          navigate
        )
      );
    } catch (error) {
      // handle error
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // debugger
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div className="auth-page d-flex align-items-center justify-content-center py-3">
      <div className="container-fluid">
        <div
          className="form-wrapper small bg-white rounded-4 mx-auto px-3 py-4 p-sm-5"
          style={{ maxWidth: "570px" }}
        >
          <div className="heading-wrapper mb-4">
            <div className="fs-3 fw-bold lh-sm mb-1">Login</div>
            <div className="text-muted">
              Please login with your username and password.
            </div>
          </div>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>
                Username<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                autoComplete="off"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                Password<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                User type<span className="text-danger">*</span>
              </Form.Label>
              <div className="d-flex row-gap-2 column-gap-5 mb-3">
                <div className="form-check fs-6">
                  <input
                    type="radio"
                    className="form-check-input border-secondary border-opacity-75"
                    id="Employee"
                    name="userType"
                    value="E"
                    checked={userType === "E"}
                    onChange={(e) => setUserType(e.target.value)}
                    required
                  />
                  <label className="form-check-label fs-14" htmlFor="Employee">
                    Employee
                  </label>
                </div>
                <div className="form-check fs-6">
                  <input
                    type="radio"
                    className="form-check-input border-secondary border-opacity-75"
                    id="Client"
                    name="userType"
                    value="C"
                    checked={userType === "C"}
                    onChange={(e) => setUserType(e.target.value)}
                  />
                  <label className="form-check-label fs-14" htmlFor="Client">
                    Client
                  </label>
                </div>
              </div>
            </Form.Group>

            <div className="d-flex gap-2 justify-content-between border-top mb-3 pt-3">
              <div className="form-check fs-6">
                <input
                  type="checkbox"
                  className="form-check-input border-secondary border-opacity-75"
                  id="rememberMe"
                />
                <label className="form-check-label fs-14" htmlFor="rememberMe">
                  Remember me
                </label>
              </div>
              <Link
                to={"/forgot-password"}
                className="btn-link fw-medium text-decoration-none"
              >
                Forgot Password
              </Link>
            </div>

            <div className="btn-wrapper d-grid pt-2">
              <Button
                type="submit"
                variant="primary"
                className="btn-custom text-decoration-none"
              >
                {/* Login */}
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {" Loading..."}
                  </>
                ) : (
                  "Login"
                )}
              </Button>
              {/* <div className="d-flex flex-wrap justify-content-center gap-2 mt-3">
                <span className="text-muted">Don't have an account?</span>
                <Link to={"/signup"} className="btn-link fw-medium text-decoration-none">
                  Register Now
                </Link>
              </div> */}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
