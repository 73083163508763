import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import API_URL from "../../Apiurl";
import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  error: false,
  success: "",
  customerFilesList: [],
  deleteLoading: false,
  deleteSuccess: "",
  deleteError: "",
};

const slice = createSlice({
  name: "customer files",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false; // Clear previous errors
      state.success = ""; // Clear previous success messages
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.error("Error:", action.payload); // Log error
    },

    // GET COMPANY LIST SUCCESS
    getCompanyListSuccess(state, action) {
      state.isLoading = false;
      state.customerFilesList = action.payload.data;
      state.error = false;
      state.success = "Company list fetched successfully!";
    },

    // START DELETE LOADING
    startDeleteLoading(state) {
      state.deleteLoading = true;
      state.deleteError = false; // Clear previous errors
      state.deleteSuccess = ""; // Clear previous success messages
    },

    // DELETE HAS ERROR
    deleteHasError(state, action) {
      state.deleteLoading = false;
      state.deleteError = action.payload;
      console.error("Delete Error:", action.payload); // Log error
    },

    // DELETE COMPANY SUCCESS
    deleteCompanySuccess(state, action) {
      state.deleteLoading = false;
      state.deleteSuccess = "Company deleted successfully!";
      state.deleteError = false;

      // Optionally, remove the deleted company from the companyList
      state.customerFilesList = state.customerFilesList?.filter(
        (customerFiles) => customerFiles.id !== action.payload.id
      );
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  getCompanyListSuccess,
  startDeleteLoading,
  deleteHasError,
  deleteCompanySuccess,
  getCompanyDetailsSuccess,
} = slice.actions;

// Async Action to Get Company List
export function getCustomerFileList(id) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}customer-files/`+id);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getCompanyListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch CustomerFile list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(
        hasError(
          error.message || "An error occurred while fetching the CustomerFile list"
        )
      );
    }
  };
}

// Async Action to Add Company
export function addCompanyFiles(e) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.post(`${API_URL}customer-files`, e, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getCustomerFileList(JSON.parse(localStorage.getItem("user")).user_id));
        toast("Company files added sucessfully!", {
          autoClose: 5000, // 5000 milliseconds = 5 seconds
        });
      } else {
        dispatch(hasError(resp.message || "Failed to add CustomerFile"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(
        hasError(error.message || "An error occurred while adding the CustomerFile")
      );
    }
  };
}

// Async Action to Delete Company
export function deleteCustomerFile(fileId) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const resp = await axios.delete(`${API_URL}customer-files/${fileId}`);
      console.log("API delete response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(deleteCompanySuccess({ id: fileId }));
      } else {
        dispatch(deleteHasError(resp.message || "Failed to delete fileId"));
      }
    } catch (error) {
      console.error("Caught delete error:", error); // Log caught error
      dispatch(
        deleteHasError(
          error.message || "An error occurred while deleting the fileId"
        )
      );
    }
  };
}
