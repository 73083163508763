import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getEmployeeList, deleteEmployee } from "../redux/slices/employee";
import DataTable from "react-data-table-component";
import SearchIcon from "../assets/search.svg";
import { NoDataComponent } from "./NoDataComponent";

export const EmployeeList = () => {
  const navigate = useNavigate();
  const { isLoading, error, success, employeeList } = useSelector(
    (state) => state.employee
  );
  const [employeeId, setEmployeeId] = useState(0);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEmployeeList());
  }, [dispatch]);

  const handleViewDetails = (companyId) => {
    navigate("/edit-employee/" + companyId, { state: { companyId } });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredEmployees = employeeList?.filter((employee) =>
    `${employee.first_name} ${employee.middle_name} ${employee.last_name}`
      .toLowerCase()
      .includes(searchText.toLowerCase())
  );

  const columns = [
    // {
    //   name: "Sr. No",
    //   selector: (row, index) => index + 1,
    //   sortable: true,
    // },
    {
      name: "Employee Name",
      selector: (row) =>
        `${row.first_name === null ? "" : row.first_name} ${
          row.middle_name === null ? "" : row.middle_name
        } ${row.last_name === null ? "" : row.last_name}`,
      sortable: true,
    },
    {
      name: "Department",
      selector: (row) => row.department,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.emailid,
      sortable: true,
    },
    // {
    //   name: "State Name",
    //   selector: (row) => row.state_name,
    //   sortable: true,
    // },
    // {
    //   name: "City",
    //   selector: (row) => row.city,
    //   sortable: true,
    // },
    // {
    //   name: "Country",
    //   selector: (row) => row.cnt_iso,
    //   sortable: true,
    // },
    {
      name: "Action",
      cell: (row) => (
        <div className="action-wrapper d-flex gap-2">
          <button
            className="btn btn-warning btn-sm"
            title="Edit"
            onClick={() => handleViewDetails(row.id)}
          >
            <i className="bi bi-pencil-fill fs-14"></i>
          </button>
          <button
            className="btn btn-danger btn-sm"
            title="Delete"
            data-bs-toggle="modal"
            data-bs-target="#deleteCompany"
            onClick={() => {
              setEmployeeId(row.id);
            }}
          >
            <i className="bi bi-trash3 fs-14"></i>
          </button>
        </div>
      ),
    },
  ];

  // Style Customization for Data Table
  const customStyles = {
    rows: {
      style: {
        fontSize: "14px",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "600",
        padding: "5px 12px",
        color: "#fff",
        backgroundColor: "var(--bs-primary)",
        minHeight: "45px",
      },
    },
    cells: {
      style: {
        color: "#3f474f",
        fontSize: "14px",
        padding: "5px 12px",
        minHeight: "40px",
      },
    },
  };

  return (
    <div className="EmployeeListPage py-3">
      <div className="container-fluid">
        <div className="heading-wrapper d-flex flex-wrap align-items-center justify-content-between gap-3 mb-3">
          <div className="fs-4 fw-bold lh-1">Employees List</div>
          <div className="d-flex flex-wrap gap-2">
            <button
              className="btn btn-primary"
              onClick={() => navigate("/add-employee")}
            >
              <i className="bi bi-person-plus-fill"></i> Add Employee
            </button>
          </div>
        </div>

        <div className="card border-0 rounded-3 shadow-sm p-3">
          <div className="searchfield-wrapper position-relative fs-6 ms-auto mb-3">
            <input
              type="search"
              className="form-control ms-auto"
              placeholder="Search clients here..."
              autoComplete="off"
              value={searchText}
              onChange={handleSearch}
            />
            <img
              src={SearchIcon}
              alt="Search Icon"
              className="position-absolute top-50 end-0 translate-middle-y pe-3"
            />
          </div>
          <div className="table-responsive table-custom-wrapper">
            <DataTable
              columns={columns}
              data={filteredEmployees}
              progressPending={isLoading}
              noDataComponent={<NoDataComponent />}
              pagination
              highlightOnHover
              // selectableRows
              customStyles={customStyles}
              dense
              responsive
            />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="deleteCompany"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-4">
            <div className="modal-body p-md-4 p-lg-5 text-center">
              <div className="fs-18 fw-semibold lh-sm mb-4">
                Are you sure you want to delete this company
              </div>
              <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3">
                <button
                  type="button"
                  className="btn btn-danger px-4"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary px-4"
                  data-bs-dismiss="modal"
                  onClick={() => dispatch(deleteEmployee(employeeId))}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
