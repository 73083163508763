import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// Import slices
import userReducer from './slices/user';
import companyReducer from './slices/company';
import countryReducer from './slices/countries';
import stateReducer from './slices/state';
import customerReducer from './slices/customer';
import employeeReducer from './slices/employee';
import journalReducer from './slices/journal';
import projectReducer from './slices/project';
import eventReducer from './slices/event';
import expenseReducer from './slices/expense';
import invoiceReducer from './slices/invoice';
import customerFilesReducer from './slices/customerFiles';

// Root persist configuration
const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [] // Add any reducers you want to whitelist here
};

// Combine all reducers
const rootReducer = combineReducers({
  user: userReducer,
  company: companyReducer,
  country: countryReducer,
  state: stateReducer,
  customer: customerReducer,
  employee: employeeReducer,
  journal: journalReducer,
  project: projectReducer,
  event: eventReducer,
  expense: expenseReducer,
  invoice: invoiceReducer,
  customerFiles: customerFilesReducer,
  // Uncomment and configure other reducers as needed
  // product: persistReducer(productPersistConfig, productReducer)
});

export { rootPersistConfig, rootReducer };
