import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { getStateList } from "../redux/slices/state";
import {
  addEmployee,
  getEmployeeDetails,
  editEmployeeAccount,
} from "../redux/slices/employee";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

export const AddEmployeeAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { stateList } = useSelector((state) => state.state);
  const { employeeDetails } = useSelector((state) => state.employee);
  const [formData, setFormData] = useState({
    user_name: "",
    password: "",
    title: "",
    salutation: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    department: "",
    // jobTitle: '',
    address1: "",
    address2: "",
    cnt_iso: "",
    state_id: "",
    city: "",
    zip: "",
    phone: "",
    alt_phone: "",
    alt_phone_2: "",
    fax: "",
    emailid: "",
    email_list: "",
    birthdate: "",
    status: "",
    subMobileAccess: [],
    division: [],
  });

  useEffect(() => {
    dispatch(getStateList());
    if (id) {
      dispatch(getEmployeeDetails(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && employeeDetails) {
      setFormData(employeeDetails);
    }
  }, [id, employeeDetails]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked
          ? [...prevState[name], value]
          : prevState[name].filter((v) => v !== value),
      }));
    } else {
      if (name === "state_id") {
        const selectedState = stateList.find(
          (state) => state.id === parseInt(value)
        );
        if (selectedState) {
          setFormData({
            ...formData,
            state_id: value,
            cnt_iso: selectedState.cnt_iso, // Set cnt_iso from the selected state
          });
        }
      }
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to an API or dispatch a Redux action
    console.log("Form submitted:", formData);
    if (id) {
      await dispatch(editEmployeeAccount(id, formData));
      //   debugger
      await dispatch(getEmployeeDetails(id));
      console.log(employeeDetails)
      await localStorage.setItem('user', JSON.stringify(employeeDetails))
      // await window.location.reload()
    } else {
        await dispatch(addEmployee(formData, navigate));
    }
  };

  return (
    <div className="addEmployeePage py-3">
      <div className="container-fluid">
        <div
          className="bg-white rounded-4 shadow-sm mx-auto px-3 px-md-5 pt-4 pb-5"
          style={{ maxWidth: "850px" }}
        >
          <div className="heading-wrapper text-center mt-md-2 mb-4">
            <div className="fs-3 fw-bold">{id ? "Edit" : "Add"} Employee</div>
          </div>
          <Form onSubmit={handleSubmit}>
            <div className="row gx-2 gx-md-3">
              {id ? (
                <></>
              ) : (
                <>
                  <div className="col-sm-6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        User name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="user_name"
                        placeholder="Enter user_name"
                        autoComplete="off"
                        value={formData?.user_name}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className="col-sm-6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Password<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="Enter password"
                        autoComplete="off"
                        value={formData?.password}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </div>
                </>
              )}
              <div className="col-4 col-sm-3 col-md-2">
                <Form.Group className="mb-3">
                  <Form.Label>Mr/Mrs/Ms</Form.Label>
                  <Form.Select
                    name="salutation"
                    value={formData?.salutation}
                    onChange={handleChange}
                  >
                    <option selected disabled value="">
                      Select...
                    </option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Ms">Ms</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-8 col-sm-9 col-md-4">
                <Form.Group className="mb-3">
                  <Form.Label>
                    First name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    placeholder="Enter first name"
                    autoComplete="off"
                    value={formData?.first_name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </div>
              <div className="col-6 col-md-3">
                <Form.Group className="mb-3">
                  <Form.Label>Middle Initial/Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="middle_name"
                    placeholder="Enter middle name"
                    autoComplete="off"
                    value={formData?.middle_name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-6 col-md-3">
                <Form.Group className="mb-3">
                  <Form.Label>
                    Last name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    placeholder="Enter last name"
                    autoComplete="off"
                    value={formData?.last_name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </div>
              {/* <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Enter title"
                    autoComplete="off"
                    value={formData?.title}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div> */}
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Department</Form.Label>
                  <Form.Control
                    type="text"
                    name="department"
                    placeholder="Enter department"
                    autoComplete="off"
                    value={formData?.department}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-12 col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Address1</Form.Label>
                  <Form.Control
                    type="text"
                    name="address1"
                    placeholder="Enter address 1"
                    autoComplete="off"
                    value={formData?.address1}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-12 col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Address2</Form.Label>
                  <Form.Control
                    type="text"
                    name="address2"
                    placeholder="Enter address 2"
                    autoComplete="off"
                    value={formData?.address2}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              {/* <div className="col-6 col-xl-3">
                <Form.Group className="mb-3">
                  <Form.Label>Country</Form.Label>
                  <Form.Select
                    name="cnt_iso"
                    value={formData?.cnt_iso}
                    onChange={handleChange}
                  >
                    <option selected disabled value="">
                      Select cnt_iso
                    </option>
                    <option value="United State">United State</option>
                    <option value="Canada">Canada</option>
                    <option value="Dubai">Dubai</option>
                    <option value="Unites Arab Emirates">
                      Unites Arab Emirates
                    </option>
                    <option value="India">India</option>
                  </Form.Select>
                </Form.Group>
              </div> */}
              <div className="col-6 col-md-4">
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Form.Select
                    name="state_id"
                    value={formData?.state_id}
                    onChange={handleChange}
                  >
                    <option selected disabled value="">
                      Select state
                    </option>
                    {stateList?.map((e) => {
                      return <option value={e.id}>{e.name}</option>;
                    })}
                    {/* <option value="New York">New York</option>
                                        <option value="California">California</option>
                                        <option value="Florida">Florida</option>
                                        <option value="New Delhi">New Delhi</option> */}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-6 col-md-4">
                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    placeholder="Enter city"
                    autoComplete="off"
                    value={formData?.city}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-sm-6 col-md-4">
                <Form.Group className="mb-3">
                  <Form.Label>Zipcode</Form.Label>
                  <Form.Control
                    type="text"
                    name="zip"
                    placeholder="Enter zip"
                    maxLength="6"
                    autoComplete="off"
                    value={formData?.zip}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="tel"
                    name="phone"
                    placeholder="Enter phone number"
                    autoComplete="off"
                    value={formData?.phone}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Fax</Form.Label>
                  <Form.Control
                    type="tel"
                    name="fax"
                    placeholder="Enter fax number"
                    autoComplete="off"
                    value={formData?.fax}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Alternative phone</Form.Label>
                  <Form.Control
                    type="tel"
                    name="alt_phone"
                    placeholder="Enter alternate number"
                    autoComplete="off"
                    value={formData?.alt_phone}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              {/* <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Alternative phone 2</Form.Label>
                  <Form.Control
                    type="tel"
                    name="alt_phone_2"
                    placeholder="Enter alternate number 2"
                    autoComplete="off"
                    value={formData?.alt_phone_2}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </div> */}
              <div className="col-sm-6 col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="emailid"
                    name="emailid"
                    placeholder="Enter email id"
                    autoComplete="off"
                    value={formData?.emailid}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-6 col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>On Email List</Form.Label>
                  <Form.Select
                    name="email_list"
                    value={formData?.email_list}
                    onChange={handleChange}
                    required
                  >
                    <option selected disabled value="">
                      Please select
                    </option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-6 col-md-12 col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>Birthday</Form.Label>
                  <Form.Control
                    type="date"
                    name="birthdate"
                    autoComplete="off"
                    value={formData?.birthdate}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <div className="radio-wrapper">
                    <div className="form-check form-check-inline me-5">
                      <input
                        className="form-check-input border-secondary border-opacity-75"
                        type="radio"
                        name="status"
                        id="A"
                        value="A"
                        checked={formData?.status === "A"}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label fs-14"
                        htmlFor="active"
                      >
                        Active
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input border-secondary border-opacity-75"
                        type="radio"
                        name="status"
                        id="I"
                        value="I"
                        checked={formData?.status === "I"}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label fs-14"
                        htmlFor="inactive"
                      >
                        Inactive
                      </label>
                    </div>
                  </div>
                </Form.Group>
              </div>
              {/* <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Sub Mobile Access</Form.Label>
                  <div className="radio-wrapper row-cols-1 row-cols-sm-2">
                    {[
                      "HomePage",
                      "ACOP",
                      "InactiveClients",
                      "ClosedProjects",
                      "FSDS",
                      "Calendar",
                      "ApplicationPreference",
                      "WebsiteManagement",
                      "SecurityLogs",
                      "UpdateServices",
                    ].map((option, index) => (
                      <div
                        className="form-check form-check-inline me-0 pe-3"
                        key={index}
                      >
                        <input
                          className="form-check-input border-secondary border-opacity-75"
                          type="checkbox"
                          name="subMobileAccess"
                          id={option}
                          value={option}
                          checked={formData?.subMobileAccess.includes(option)}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label fs-14"
                          htmlFor={option}
                        >
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </Form.Group>
              </div>
              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Division</Form.Label>
                  <div className="radio-wrapper">
                    {["SIU", "STU"].map((option, index) => (
                      <div
                        className="form-check form-check-inline me-5"
                        key={index}
                      >
                        <input
                          className="form-check-input border-secondary border-opacity-75"
                          type="checkbox"
                          name="division"
                          id={option}
                          value={option}
                          checked={formData?.division.includes(option)}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label fs-14"
                          htmlFor={option}
                        >
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </Form.Group>
              </div> */}
            </div>
            <div className="btn-wrapper d-flex flex-wrap gap-3 pt-2">
              <Button
                type="submit"
                variant="primary"
                className="btn-custom text-decoration-none px-4"
              >
                {id ? "Edit" : "Add"} Employee
              </Button>
              <Button
                type="reset"
                variant="secondary"
                className="btn-custom text-decoration-none px-5"
                onClick={() =>
                  setFormData({
                    user_name: "",
                    password: "",
                    title: "",
                    first_name: "",
                    middle_name: "",
                    last_name: "",
                    department: "",
                    jobTitle: "",
                    address1: "",
                    address2: "",
                    cnt_iso: "",
                    state: "",
                    city: "",
                    zip: "",
                    phone: "",
                    alt_phone: "",
                    alt_phone_2: "",
                    fax: "",
                    emailid: "",
                    email_list: "",
                    birthdate: "",
                    subMobileAccess: [],
                    division: [],
                  })
                }
              >
                Reset
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
