import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Tabs, Tab, Form, Button, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getProjectDetails,
  getProjectFullDetails,
} from "../redux/slices/project";
import {
  getProjectFilesListId,
  getJournalListId,
  deleteProjectFile,
  projectFilesId
} from "../redux/slices/journal";
import { getExpenseList } from "../redux/slices/expense";
import { addExpense, getExpenseDetails } from "../redux/slices/expense";
import { getEventListId } from "../redux/slices/event";
import { addProjectFiles } from "../redux/slices/project";

export const Workspace = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("tab1");
  const { id } = useParams();
  const dispatch = useDispatch();
  const { projectDetails, projectFullDetails, isLoading } = useSelector(
    (state) => state.project
  );
  const { eventList } = useSelector((state) => state.event);
  const { filesList, journalList } = useSelector((state) => state.journal);
  const { expenseDetails } = useSelector((state) => state.expense);
  // Bootstrap Modal
  const [show, setShow] = useState(false);
  const openExpenseModal = () => setShow(true);
  const closeExpenseModal = () => setShow(false);
  const [formData, setFormData] = useState({
    prj_id: id,
    cmp_id: localStorage.getItem("cmp_id"),
    name: "",
    path: "project_files/",
    size: "",
    type: "",
    description: "",
    status: "A",
    is_processed: "YES",
    file: [],
  });
  useEffect(() => {
    dispatch(getProjectFilesListId(id));
    dispatch(getJournalListId(id));
    dispatch(getProjectDetails(id));
    dispatch(getProjectFullDetails(id));
    dispatch(getEventListId(id));
    dispatch(getExpenseDetails(id));
    dispatch(getExpenseList(id));
  }, [dispatch, id]);
  const [expenses, setExpenses] = useState([
    { date: "2021-10-11", type: "", description: "", price: "50" },
  ]);

  const [expFrom, setExpFrom] = useState("2021-10-11");
  const [expTo, setExpTo] = useState("2021-11-11");
  const handleAddExpense = () => {
    setExpenses([
      ...expenses,
      { date: "2021-10-11", type: "", description: "", price: "50" },
    ]);
  };
  const handleFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      console.log(file);
      await setFormData({
        ...formData,
        name: file.name,
        type: file.type,
        size: file.size,
        prj_id: id,
        file: file,
      });
    }
    console.log(formData);
  };
  const handleSubmitFile = async () => {
    // e.preventDefault();
    console.log(formData);
    await dispatch(addProjectFiles(formData));
    await dispatch(getProjectFilesListId(id)); 
    await setFilesModal(false);
  };
  const handleInputChangeFiles = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      description: value,
    });
  };
  useEffect(() => {
    if (id && expenseDetails) {
      console.log(expenseDetails);
      // setExpFrom(expenseDetails.expenses_from)
      // setExpTo(expenseDetails.expenses_to)

      // setExpenses([
      //   ...expenseDetails,
      //   { date: "", type: "", description: "", price: "" },
      // ]);
    }
  });
  const handleRemoveExpense = (index) => {
    setExpenses(expenses.filter((_, i) => i !== index));
    if (index === 0) {
      setExpenses([
        { date: "2021-10-11", type: "", description: "", price: "50" },
      ]);
    }
  };
  const [showFilesModal, setFilesModal] = useState(false);
  const openFilesModal = () => {
    setFilesModal(true);
  };
  const closeFilesModal = () => setFilesModal(false);

  const handleInputChange = (index, field, value) => {
    const newExpenses = expenses.map((expense, i) =>
      i === index ? { ...expense, [field]: value } : expense
    );
    setExpenses(newExpenses);
  };

  const handleSubmit = () => {
    const formData = {
      expenses_from: expFrom,
      expenses_to: expTo,
      comp_id:localStorage.getItem("cmp_id"),
      prj_id:localStorage.getItem("prj_id"),
      expensesdetails: expenses,
    };
    console.log("Form Data:", formData);
    dispatch(addExpense(formData));
    // Handle form submission, e.g., send data to server
    closeExpenseModal();
  };
  function formatDate(datetime) {
    const date = new Date(datetime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  }

  return (
    <div className="WorkspacePage py-3">
      <div className="container-fluid">
        <div className="heading-wrapper d-flex flex-wrap align-items-center justify-content-between gap-3 mb-3">
          <div className="fs-4 fw-bold lh-1">
            Workspace List({projectDetails?.name})
          </div>
          {JSON.parse(localStorage.getItem("usertype")) === "E" ? (
            <div className="d-flex flex-wrap gap-2">
              <button
                className="btn btn-primary"
                onClick={() => navigate("/expenses-list")}
              >
                <i className="bi bi-folder-plus"></i> List Expenses
              </button>
              <button className="btn btn-success" onClick={openExpenseModal}>
                <i className="bi bi-file-earmark-arrow-down"></i> Add Expenses
              </button>
            </div>
          ) : (
            <div className="d-flex flex-wrap gap-2">
              <button
                className="btn btn-success btn-sm"
                onClick={() => openFilesModal()}
              >
                <i className="bi bi-folder-plus"></i> Add Files
              </button>
              <button
                type="submit"
                variant="primary"
                className="btn-custom text-decoration-none px-4"
                onClick={() => navigate("/add-journal-id/" + id)}
              >
                Add Journal
              </button>
            </div>
          )}
        </div>

        <div className="wrapper">
          <Tabs
            id="controlled-tab-example"
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
            className="tabs-nav-wrapper flex-nowrap text-nowrap overflow-x-auto bg-secondary bg-opacity-10 border-bottom-0 rounded-top-3 fw-medium"
          >
            <Tab eventKey="tab1" title="Case File(s)" className="bg-white p-3">
              <div className="table-content-wrapper">
                <div className="table-responsive table-custom-wrapper fs-14">
                  <table className="table table-hover align-middle m-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="fw-medium text-nowrap"
                          style={{ minWidth: "auto" }}
                        >
                          {/* Sr. No */}
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Type
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Name
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Description
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Date Posted
                        </th>
                        {/* <th scope="col" className="fw-medium text-nowrap">
                          File Size
                        </th> */}
                        <th scope="col" className="fw-medium text-nowrap">
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filesList.map((e) => (
                        <tr>
                          {/* <td>{e.id}</td> */}
                          <td>
                            <a
                              target="_blank"
                              href={`https://oitrack.s3.us-west-1.amazonaws.com/${e.name}`}
                              rel="noreferrer"
                            >
                              {/* <img
                              src={`https://oitrack.s3.us-west-1.amazonaws.com/${e.name}`}
                              className="rounded-circle"
                              style={{ width: "40px", height: "40px" }}
                              alt=""
                            /> */}
                              {e.type === "image/png" ? (
                                <i className="bi bi-image-fill"></i>
                              ) : e.type ===
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                                <i className="bi bi-filetype-docx"></i>
                              ) : e.type === "application/pdf" ? (
                                <i className="bi bi-file-earmark-pdf-fill"></i>
                              ) : (
                                <i className="bi bi-camera-video"></i>
                              )}
                            </a>
                          </td>

                          <td>{e.type}</td>
                          <td className="text-nowrap text-capitalize">
                            {e.name}
                          </td>
                          <td className="text-nowrap text-capitalize">
                            {e.description}
                          </td>
                          <td className="text-nowrap text-capitalize">
                            {formatDate(e.created_at)}
                          </td>
                          {/* <td className="text-nowrap text-capitalize">
                            {e.size / 1024} kb
                          </td> */}
                          <td className="text-nowrap text-capitalize">
                            {/* <i className="bi bi-trash3 fs-14"></i> */}
                            <button
                              className="btn btn-danger btn-sm"
                              title="Delete"
                              onClick={() => {
                                console.log("deleteProjectFile");
                                dispatch(deleteProjectFile(e.id, id));
                              }}
                            >
                              <i className="bi bi-trash3 fs-14"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="tab2" title="Case Details" className="bg-white p-3">
              <div className="table-content-wrapper">
                <div>Project Details</div>
                <ul>
                  <li>Name : {projectDetails?.name}</li>
                  <li>Notes: {projectDetails?.notes}</li>
                  <li>Participants: {projectDetails?.participants.length}</li>
                </ul>
                {/* <div className="table-responsive table-custom-wrapper fs-14">
                  <table className="table table-hover align-middle m-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="fw-medium text-nowrap"
                          style={{ minWidth: "auto" }}
                        >
                          Sr. No
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Type
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Name
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Description
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Date Posted
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          File Size
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {[...Array(6)].map((elementInArray, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              src={require("../assets/dummy-user.jpeg")}
                              alt="Type"
                              className="rounded"
                              style={{ width: "50px" }}
                            />
                          </td>
                          <td className="text-nowrap text-capitalize">
                            Desert Landscape.jpg
                          </td>
                          <td className="text-nowrap text-capitalize">
                            This is dummy description
                          </td>
                          <td className="text-nowrap text-capitalize">
                            01/13/2012 06:15 AM
                          </td>
                          <td className="text-nowrap text-capitalize">
                            223 Kb
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div> */}
              </div>
            </Tab>
            <Tab
              eventKey="tab3"
              title="Case Journal(s)"
              className="bg-white p-3"
            >
              <div className="table-content-wrapper">
                <div className="table-responsive table-custom-wrapper fs-14">
                  <table className="table table-hover align-middle m-0">
                    <thead>
                      <tr>
                        <th scope="col" className="fw-medium text-nowrap">
                          Sr. No
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Title
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Description
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {journalList.map((e) => {
                        return (
                          <tr>
                            <td>{e.id}</td>
                            <td className="text-nowrap text-capitalize">
                              {e.title}
                            </td>
                            <td className="text-nowrap text-capitalize">
                              {e.description}
                            </td>
                            <td className="text-nowrap text-capitalize">
                              {e.date}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="tab4"
              title="Case Schedule(s)"
              className="bg-white p-3"
            >
              <div className="table-content-wrapper">
                <div className="table-responsive table-custom-wrapper fs-14">
                  <table className="table table-hover align-middle m-0">
                    <thead>
                      <tr>
                        <th scope="col" className="fw-medium text-nowrap">
                          Title
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Date
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Start Time
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          End Time
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Post Type
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Location
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Details
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {eventList.map((e) => {
                        return (
                          <tr>
                            <td>{e.title}</td>
                            <td className="text-nowrap text-capitalize">
                              {e.date}
                            </td>
                            <td className="text-nowrap text-capitalize">
                              {e.start_time}
                            </td>
                            <td className="text-nowrap text-capitalize">
                              {e.end_time}
                            </td>
                            <td className="text-nowrap text-capitalize">
                              {e.post_type}
                            </td>
                            <td className="text-nowrap text-capitalize">
                              {e.location}
                            </td>
                            <td className="text-nowrap text-capitalize">
                              {e.details}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>

      {/* Add Expenses Modal Code */}
      <Modal
        show={show}
        onHide={closeExpenseModal}
        centered
        size="xl"
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Header closeButton className="bg-primary bg-opacity-10">
          <Modal.Title className="fs-6 text-primary lh-sm">
            <i className="bi bi-receipt-cutoff"></i> Add Expenses
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-md-4 px-xl-5 py-xl-4">
          <div className="fs-4 fw-semibold text-center lh-sm mb-3">
            Expense Report for Case{" "}
            <span className="text-capitalize">{projectFullDetails.name}</span>
          </div>
          <div className="row justify-content-between g-3 fs-14 mb-3">
            <div className="col-lg-6 col-xl-5">
              <div className="client-name d-flex text-capitalize mb-1">
                <span
                  className="d-inline-block fw-medium me-2"
                  style={{ minWidth: "120px" }}
                >
                  Client Name:
                </span>
                <span className="text-muted">
                  {projectFullDetails.lead_cus_id}
                </span>
              </div>
              <div className="case-name d-flex text-capitalize mb-1">
                <span
                  className="d-inline-block fw-medium me-2"
                  style={{ minWidth: "120px" }}
                >
                  Case Name:
                </span>
                <span className="text-muted">{projectFullDetails.name}</span>
              </div>
              <div className="address d-flex text-capitalize mb-1">
                <span
                  className="d-inline-block fw-medium me-2"
                  style={{ minWidth: "120px" }}
                >
                  Address:
                </span>
                <address className="text-muted m-0">
                  PO Box 2575
                  <br />
                  Jacksonville
                  <br />
                  US 32203
                </address>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="emp-name d-flex text-capitalize mb-1">
                <span
                  className="d-inline-block fw-medium me-2"
                  style={{ minWidth: "120px" }}
                >
                  Employee Name:
                </span>
                <span className="text-muted">
                  {projectFullDetails.lead_emp_id}
                </span>
              </div>
              <div className="emp-id d-flex text-capitalize mb-1">
                <span
                  className="d-inline-block fw-medium me-2"
                  style={{ minWidth: "120px" }}
                >
                  Employee ID:
                </span>
                <span className="text-muted">00069</span>
              </div>
              <div className="claim-number d-flex text-capitalize mb-1">
                <span
                  className="d-inline-block fw-medium me-2"
                  style={{ minWidth: "120px" }}
                >
                  Claim Number:
                </span>
                <span className="text-muted">00069</span>
              </div>
            </div>
          </div>
          <div className="table-content-wrapper">
            <div className="exp-period d-flex flex-column flex-md-row flex-wrap gap-2 align-items-md-center fs-14 text-capitalize mb-2">
              <span className="fw-medium me-2">Expense Period:</span>
              <span className="d-flex flex-column flex-sm-row align-items-sm-center gap-2">
                <div className="field-cover d-flex flex-column flex-sm-row align-items-sm-center gap-2">
                  <label htmlFor="expFrom">From</label>
                  <input
                    type="date"
                    id="expFrom"
                    className="form-control rounded-0"
                    value={expFrom}
                    onChange={(e) => setExpFrom(e.target.value)}
                    style={{ minHeight: "38px" }}
                  />
                </div>
                <div className="field-cover d-flex flex-column flex-sm-row align-items-sm-center gap-2">
                  <label htmlFor="expTo">To</label>
                  <input
                    type="date"
                    id="expTo"
                    className="form-control rounded-0"
                    value={expTo}
                    onChange={(e) => setExpTo(e.target.value)}
                    style={{ minHeight: "38px" }}
                  />
                </div>
              </span>
            </div>
            <div className="table-responsive table-custom-wrapper fs-14">
              <table className="table table-hover align-middle m-0">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Expenses
                    </th>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="fw-medium text-nowrap"
                      style={{ minWidth: "170px" }}
                    >
                      Price
                    </th>
                    <th scope="col" className="fw-medium text-nowrap">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {expenses.map((expense, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="date"
                          className="form-control"
                          id="expDate"
                          name="expDate"
                          value={expense.date}
                          onChange={(e) =>
                            handleInputChange(index, "date", e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <select
                          name="expType"
                          id="expType"
                          className="form-select"
                          value={expense.expenses}
                          onChange={(e) =>
                            handleInputChange(index, "expenses", e.target.value)
                          }
                        >
                          <option selected disabled value="">
                            Select Expenses
                          </option>
                          <option value="Miles">Miles</option>
                          <option value="Lodging">Lodging</option>
                          <option value="Meals">Meals</option>
                          <option value="Hour worked">Hour worked</option>
                          <option value="Other">Other</option>
                        </select>
                      </td>
                      <td className="text-nowrap text-capitalize">
                        <textarea
                          rows={"2"}
                          name="expDescription"
                          id="expDescription"
                          className="form-control"
                          style={{ height: "45px", resize: "none" }}
                          value={expense.description}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "description",
                              e.target.value
                            )
                          }
                        ></textarea>
                      </td>
                      <td className="text-nowrap text-capitalize">
                        <input
                          type="text"
                          name="expPrice"
                          id="expPrice"
                          className="form-control"
                          value={expense.price}
                          autoComplete="off"
                          onChange={(e) =>
                            handleInputChange(index, "price", e.target.value)
                          }
                        />
                      </td>
                      <td className="text-nowrap text-capitalize">
                        <div className="action-btn d-flex gap-2">
                          <Button
                            variant="success"
                            title="Add"
                            onClick={handleAddExpense}
                          >
                            <i className="bi bi-plus-lg"></i>
                          </Button>
                          <Button
                            variant="danger"
                            title="Remove"
                            onClick={() => handleRemoveExpense(index)}
                          >
                            <i className="bi bi-dash-lg"></i>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="btn-wrapper text-center mt-4">
            <button
              type="button"
              className="btn btn-primary px-4 py-2"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showFilesModal}
        onHide={closeFilesModal}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Header closeButton className="bg-primary bg-opacity-10">
          <Modal.Title className="fs-6 text-primary lh-sm">
            <i className="bi bi-file-earmark-text-fill"></i> Add File Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="projectFile" className="mb-3">
              <Form.Label>
                File Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="file"
                className="shadow-none"
                // multiple
                required
                onChange={handleFileChange}
                style={{ minHeight: "auto" }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="fileDescription">
              <Form.Label>
                Description<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                autoComplete="off"
                maxLength={100}
                onChange={handleInputChangeFiles}
                name="description"
                placeholder="Enter your description here..."
                required
              />
              <div className="fs-12 text-secondary mt-1">
                Maximum 100 characters
              </div>
            </Form.Group>
            <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-2 pt-2 mb-3">
              <Button
                // type="submit"
                variant="primary"
                onClick={handleSubmitFile}
                className="px-4"
              >
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {" Ádding file wait..."}
                  </>
                ) : (
                  "Add"
                )}
              </Button>
              <Button type="reset" variant="danger" className="px-4">
                Reset
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
