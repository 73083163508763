import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../assets/search.svg";
import { useSelector, useDispatch } from "react-redux";
import { getJournalListId, deleteJournal } from "../redux/slices/journal";
// import Badge from "react-bootstrap/Badge";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DataTable from "react-data-table-component";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NoDataComponent } from "./NoDataComponent";

export const JournalCaseList = () => {
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const { isLoading, journalList } = useSelector(
    (state) => state.journal
  );
  const [journalId, setJournalId] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const id = localStorage.getItem("prj_id");
    dispatch(getJournalListId(id));
  }, [dispatch]);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setJournalId(id);
  };
  const handleViewDetails = (companyId) => {
    navigate("/edit-journal/" + companyId, { state: { companyId } });
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  function filterJournalList(journalList, search) {
    if (Array.isArray(journalList)) {
      return journalList.filter((journal) =>
        journal.title.toLowerCase().includes(search.toLowerCase())
      );
    } else {
      console.error('journalList is not an array', journalList);
      return []; // Return an empty array or handle the error as needed
    }
  }
  
  const filteredJournalList = filterJournalList(journalList, search);
  // const notify = () => {
  //   console.log("simran");
  //   toast("This is a toast notification!", {
  //     autoClose: 5000 // 5000 milliseconds = 5 seconds
  //   });
  // };

  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Project id",
      selector: (row) => row.prj_id,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="action-wrapper d-flex gap-2">
          <button
            className="btn btn-warning btn-sm"
            title="Edit"
            onClick={() => handleViewDetails(row.id)}
          >
            <i className="bi bi-pencil-fill fs-14"></i>
          </button>
          <button
            className="btn btn-danger btn-sm"
            title="Delete"
            onClick={() => handleShow(row.id)}
          >
            <i className="bi bi-trash3 fs-14"></i>
          </button>
        </div>
      ),
    },
  ];

  // Style Customization for Data Table
  const customStyles = {
    rows: {
      style: {
        fontSize: '14px',
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: '600',
        padding: "5px 12px",
        color: '#fff',
        backgroundColor: 'var(--bs-primary)',
        minHeight:'45px',
      },
    },
    cells: {
      style: {
        color: '#3f474f',
        fontSize: "14px",
        padding: "5px 12px",
        minHeight:'40px',
      },
    },
  };

  return (
    <div className="journalCaseListPage py-3">
      <div className="container-fluid">
        <div className="heading-wrapper d-flex flex-wrap align-items-center justify-content-between gap-3 mb-3">
          <div className="fs-4 fw-bold lh-1">Journal Case List</div>
          <div className="d-flex flex-wrap gap-2">
            <button
              className="btn btn-primary"
              onClick={() => navigate("/add-journal")}
            >
              <i className="bi bi-folder-plus"></i> Add Journal entry
            </button>
            <button className="btn btn-success">
              <i className="bi bi-file-earmark-arrow-down"></i> Download CSV
            </button>
          </div>
        </div>

        <div className="card border-0 rounded-3 shadow-sm p-3">
          <div className="searchfield-wrapper position-relative fs-6 ms-auto mb-3">
            <input
              type="search"
              className="form-control ms-auto"
              placeholder="Search journal cases here..."
              value={search}
              onChange={handleSearch}
              autoComplete="off"
            />
            <img
              src={SearchIcon}
              alt="Search Icon"
              className="position-absolute top-50 end-0 translate-middle-y pe-3"
            />
          </div>
          <div className="table-responsive table-custom-wrapper">
            <DataTable
              columns={columns}
              data={filteredJournalList}
              pagination
              highlightOnHover
              progressPending={isLoading}
              noDataComponent={<NoDataComponent />}
              // selectableRows
              customStyles={customStyles}
              dense
              responsive
            />
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Body className="p-md-4 p-lg-5 text-center">
          <div className="fs-18 fw-semibold lh-sm mb-4">
            Are you sure you want to delete this journal case?
          </div>
          <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3">
            <button
              type="button"
              className="btn btn-danger px-4"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary px-4"
              onClick={() => {
                dispatch(deleteJournal(journalId));
                handleClose();
              }}
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
