import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../assets/search.svg";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import DataTable from "react-data-table-component";
import { getEventList, deleteEvent } from "../redux/slices/event";
import { NoDataComponent } from "./NoDataComponent";

export const MeetingsList = () => {
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [meetingId, setMeetingId] = useState("");
  const navigate = useNavigate();
  // const { isLoading, error, success, eventList } = useSelector(
  //   (state) => state.event
  // );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEventList(3));
  }, [dispatch]);
  const { isLoading, error, success, eventList } = useSelector(
    (state) => state.event
  );

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setMeetingId(id);
    setShow(true);
  };
  const handleDelete = () => {
    console.log(meetingId);
    dispatch(deleteEvent(meetingId));
    setShow(false);
  };
  const handleSearch = (event) => setSearch(event.target.value);
  // const filteredeventList = [];
  const filteredeventList = eventList.filter(
    (event) => event.title.toLowerCase().includes(search.toLowerCase())
    //   event.middle_name.toLowerCase().includes(search.toLowerCase()) ||
    //   event.last_name.toLowerCase().includes(search.toLowerCase()) ||
    //   event.department.toLowerCase().includes(search.toLowerCase()) ||
    //   event.title.toLowerCase().includes(search.toLowerCase()) ||
    //   event.emailid.toLowerCase().includes(search.toLowerCase()) ||
    //   event.state_name.toLowerCase().includes(search.toLowerCase()) ||
    //   event.city.toLowerCase().includes(search.toLowerCase()) ||
    //   event.cnt_iso.toLowerCase().includes(search.toLowerCase())
  );

  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => `${row.title}`,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Start Time",
      selector: (row) => row.start_time,
      sortable: true,
    },
    {
      name: "End Time",
      selector: (row) => row.end_time,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.post_type,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
    },
    // {
    //   name: "Country",
    //   selector: (row) => row.cnt_iso,
    //   sortable: true,
    // },
    {
      name: "Action",
      cell: (row) => (
        <div className="action-wrapper d-flex gap-2">
          <button
            className="btn btn-warning btn-sm"
            title="Edit"
            onClick={() => handleViewDetails(row.id)}
          >
            <i className="bi bi-pencil-fill fs-14"></i>
          </button>
          <button
            className="btn btn-danger btn-sm"
            title="Delete"
            onClick={(e) => handleShow(row.id)}
          >
            <i className="bi bi-trash3 fs-14"></i>
          </button>
        </div>
      ),
    },
  ];

  const handleViewDetails = (companyId) => {
    navigate("/edit-meeting/" + companyId, { state: { companyId } });
  };

  // Style Customization for Data Table
  const customStyles = {
    rows: {
      style: {
        fontSize: '14px',
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: '600',
        padding: "5px 12px",
        color: '#fff',
        backgroundColor: 'var(--bs-primary)',
        minHeight:'45px',
      },
    },
    cells: {
      style: {
        color: '#3f474f',
        fontSize: "14px",
        padding: "5px 12px",
        minHeight:'40px',
      },
    },
  };

  return (
    <div className="meetingsListPage py-3">
      <div className="container-fluid">
        <div className="heading-wrapper d-flex flex-wrap align-items-center justify-content-between gap-3 mb-3">
          <div className="fs-4 fw-bold lh-1">Meeting List</div>
          <div className="d-flex flex-wrap gap-2">
            <button
              className="btn btn-primary"
              onClick={() => navigate("/add-meeting")}
            >
              <i className="bi bi-plus-circle"></i> Add Meeting
            </button>
            <button className="btn btn-success">
              <i className="bi bi-file-earmark-arrow-down"></i> Download CSV
            </button>
          </div>
        </div>

        <div className="card border-0 rounded-3 shadow-sm p-3">
          <div className="searchfield-wrapper position-relative fs-6 ms-auto mb-3">
            <input
              type="search"
              className="form-control ms-auto"
              placeholder="Search meetings here..."
              value={search}
              onChange={handleSearch}
              autoComplete="off"
            />
            <img
              src={SearchIcon}
              alt="Search Icon"
              className="position-absolute top-50 end-0 translate-middle-y pe-3"
            />
          </div>

          <div className="table-responsive table-custom-wrapper">
            <DataTable
              columns={columns}
              data={filteredeventList}
              pagination
              highlightOnHover
              progressPending={isLoading}
              noDataComponent={<NoDataComponent />}
              // selectableRows
              customStyles={customStyles}
              dense
              responsive
            />
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Body className="p-md-4 p-lg-5 text-center">
          <div className="fs-18 fw-semibold lh-sm mb-4">
            Are you sure you want to delete this meeting?
          </div>
          <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3">
            <button
              type="button"
              className="btn btn-danger px-4"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary px-4"
              onClick={handleDelete}
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
