import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChart = ({ chartData }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: true,
        labels: {
          color: '#000', // Set the color of the labels
          font: {
            size: 12, // Set the font size
          },
        },
      },      
      title: {
        display: true,
        text: 'Weekly Cases',
        color: '#203d7e',
        font: {
          size: 16,
          weight: 600,
        },
      },
    },
    scales: {
      y: {
        min: 0, // Start y-axis at 0
        ticks: {
          callback: function(value) {
            return Math.floor(value); // Ensure no decimals
          },
          stepSize: 1, // Ensure only integer steps
        },
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default LineChart;
