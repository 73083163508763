import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import API_URL from "../Apiurl";
import { getCalander, getEventListDay, deleteEvent } from "../redux/slices/event";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Tabs, Tab, Button } from "react-bootstrap";

export const Calender = () => {
  const dispatch = useDispatch();
  const [selectedView, setSelectedView] = useState("");
  const [myId, setMyId] = useState(0);
  const [meetingId, setMeetingId] = useState("");
  const [show2, setShow2] = useState(false);
  
  //   const [activeTab, setActiveTab] = useState("tab1");
  const { event } = useSelector((state) => state.event);
  const handleSelectChange = (event) => {
    setSelectedView(event.target.value);
    console.log(event.target.value);
    dispatch(getCalander(event.target.value));
  };
  console.log(myId);
  useEffect(() => {
    dispatch(getEventListDay());
    // setSelectedView();
  }, [dispatch]);
  const handleShow2 = (id) => {
    // debugger
    console.log(id)
    setMeetingId(id);
    setShow2(true)
  }
  const handleClose2 = () => setShow2(false);

  return (
    <div className="CalenderPage py-3">
      <div className="container-fluid">
        <div className="fs-4 fw-bold lh-1 mb-3">Calender - </div>
        <div className="filter-wrapper d-flex flex-wrap align-items-center justify-content-between gap-2 bg-secondary bg-opacity-10 p-2 mb-3">
          <Form.Group
            className="fs-14 d-flex align-items-center gap-2 ms-1"
            controlId="filterCalendar"
          >
            <Form.Label className="text-primary text-nowrap mb-0">
              Filter Calendar
            </Form.Label>
            <select
              className="shadow-none border border-secondary rounded-1 p-1"
              aria-label="Default select example"
              onChange={handleSelectChange}
            >
              {/* <option>Open this select menu</option> */}
              <option
                value={
                  myId === 0
                    ? `${API_URL}events-day`
                    : `${API_URL}events-day/${myId}`
                }
              >
                Daily View
              </option>
              <option
                value={
                  myId === 0
                    ? `${API_URL}events-week`
                    : `${API_URL}events-week/${myId}`
                }
              >
                Weekly View
              </option>
              <option
                value={
                  myId === 0
                    ? `${API_URL}events-month`
                    : `${API_URL}events-month/${myId}`
                }
              >
                Monthly View
              </option>
              <option
                value={
                  myId === 0
                    ? `${API_URL}events-year`
                    : `${API_URL}events-year/${myId}`
                }
              >
                Yearly View
              </option>
            </select>
          </Form.Group>
          <div className="tabs-wrapper">
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <button
                className="nav-link border-secondary border-opacity-75 rounded-0 active"
                id="tab1"
                data-bs-toggle="tab"
                data-bs-target="#showAllEvent"
                type="button"
                role="tab"
                aria-controls="showAllEvent"
                aria-selected="true"
                onClick={() =>
                  setMyId(0)
                }
              >
                Show all event
              </button>
              <button
                className="nav-link border-secondary border-opacity-75 rounded-0"
                id="tab1"
                data-bs-toggle="tab"
                data-bs-target="#showAllEvent"
                type="button"
                role="tab"
                aria-controls="showAllEvent"
                aria-selected="false"
                onClick={() =>
                  setMyId(JSON.parse(localStorage.getItem("user")).user_id)
                }
              >
                Show my events only
              </button>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="showAllEvent"
              role="tabpanel"
              aria-labelledby="tab1"
              tabIndex="0"
            >
              <div className="info-card-wrapper">
                <div className="bg-dark bg-opacity-10 text-primary fw-medium rounded-top-3 px-3 py-2">
                  <i className="bi bi-layout-text-sidebar-reverse me-1"></i>{" "}
                  {/* November 06, 2011 - November 12, 2011 */}
                </div>
                <div className="card rounded-top-0 p-3">
                  <div className="table-content-wrapper">
                    <div className="table-responsive fs-14">
                      <table className="table table-bordered border-secondary border-opacity-25 fw-medium m-0">
                        <tbody>
                          {Object.keys(event).map((value) => {
                            return (
                              <tr>
                                <td className="text-success text-nowrap">
                                  {value}
                                </td>

                                <td>
                                  {event[value].map((events) => (
                                    <div className="project-wrapper d-flex flex-column gap-2 fw-medium">
                                      <div className="d-flex justify-content-between row-gap-1 column-gap-5">
                                        <div className="d-flex flex-wrap column-gap-1 text-primary text-nowrap">
                                          <span>{events.title}</span>
                                          <span className="text-success">
                                            {events.prj_id}
                                          </span>
                                        </div>
                                        <div className="d-flex">
                                          <Link
                                            to={"#"}
                                            className="text-success"
                                          >
                                            Export/
                                          </Link>
                                          <Link
                                            // to={"#"}
                                            to={'/edit-meeting/'+events.event_id}
                                            className="text-success"
                                          >
                                            Edit/
                                          </Link>
                                          <Link
                                            // to={"#"}
                                            onClick={() => handleShow2(events.event_id)}
                                            className="text-success"
                                          >
                                            Delete
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </td>
                                <td>
                                  <a href="/add-meeting">
                                    <div className="add-event d-flex gap-1 text-primary text-nowrap">
                                      <i className="bi bi-calendar-date-fill"></i>{" "}
                                      Add Event
                                    </div>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="showMyEvents"
              role="tabpanel"
              aria-labelledby="tab2"
              tabIndex="0"
            >
              <div className="info-card-wrapper">
                <div className="bg-dark bg-opacity-10 text-primary fw-medium rounded-top-3 px-3 py-2">
                  <i className="bi bi-list-columns-reverse me-1"></i> Log List
                </div>
                <div className="card rounded-top-0 p-3">
                  <div className="table-content-wrapper">
                    <div className="table-responsive fs-14">
                      <table className="table table-bordered border-secondary border-opacity-25 m-0">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="bg-primary text-white fw-medium text-nowrap"
                            >
                              Remote ADDR
                            </th>
                            <th
                              scope="col"
                              className="bg-primary text-white fw-medium text-nowrap"
                            >
                              Platform
                            </th>
                            <th
                              scope="col"
                              className="bg-primary text-white fw-medium text-nowrap"
                            >
                              Time
                            </th>
                            <th
                              scope="col"
                              className="bg-primary text-white fw-medium text-nowrap"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="bg-primary text-white fw-medium text-nowrap"
                            >
                              MSG
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {[...Array(6)].map((elementInArray, index) => (
                            <tr key={index}>
                              <td className="fw-medium text-nowrap">
                                192.168.1.121
                              </td>
                              <td>
                                Mozilla/5.0 (Windows; U; Windows NT 5.2; en-US;
                                rv:1.9.2.10) Gecko/20100914 Firefox/3.6.10
                              </td>
                              <td>
                                <div className="d-flex gap-2 text-nowrap text-capitalize">
                                  <span className="date">10/09/2010</span>
                                  <span className="time">13:30</span>
                                </div>
                              </td>
                              <td className="text-nowrap text-capitalize">
                                Employee : Admin
                              </td>
                              <td className="text-nowrap text-capitalize">
                                Login
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show2}
        onHide={handleClose2}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Body className="p-md-4 p-lg-5 text-center">
          <div className="fs-18 fw-semibold lh-sm mb-4">
            Are you sure you want to delete this Meeting?
          </div>
          <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3">
            <button
              type="button"
              className="btn btn-danger px-4"
              onClick={handleClose2}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary px-4"
              onClick={async () => {
                await dispatch(deleteEvent(meetingId));
                await handleClose2();
                // await dispatch(getProjectDetailsById(id));
                const user = JSON.parse(localStorage.getItem("user"));
                const data = {
                  user_id: user?.user_id,
                  usertype: JSON.parse(localStorage.getItem("usertype")),
                };
                // let call =`${API_URL}events-day`;
                dispatch(getEventListDay());
              }}
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
