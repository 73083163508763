import React from 'react';
import { Routes, Route } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { Header } from '../components/Header';
import Sidebar from '../components/Sidebar';
import { CompanyList } from "../pages/CompanyList";
import { CompanyFiles } from "../pages/CompanyFiles";
import { CompanyFilesProject } from "../pages/CompanyFilesProject";
import { AddCompany } from "../pages/AddCompany";
import { NoPage } from "../pages/NoPage";
import { Dashboard } from './Dashboard';
import { ClientsList } from './ClientsList';
import { ClientsDirectory } from './ClientsDirectory';
import { AddClient } from './AddClient';
import { AddClientAccount } from './AddClientAccount';
import { AddEmployee } from './AddEmployee';
import { AddEmployeeAccount } from './AddEmployeeAccount';
import { EmployeeList } from './EmployeeList';
import { CasesList } from './CasesList';
import { ClientCasesList } from './ClientCasesList';
import { CasesDetails } from './CasesDetails';
import { AddCase } from './AddCase';
import { JournalCaseList } from './JournalCaseList';
import { AddJournalCase } from './AddJournalCase';
import { AddJournalCaseId } from './AddJournalCaseId';
import { MeetingsList } from './MeetingsList';
import { AddMeeting } from './AddMeeting';
import { AddMeetingDashboard } from './AddMeetingDashboard';
import { MilestoneList } from './MilestoneList';
import { AddMilestone } from './AddMilestone';
import { AddDeadline } from './AddDeadline';
import { DeadlineList } from './DeadlineList';
import { Workspace } from './Workspace';
import { ExpensesListing } from './ExpensesListing';
import { Settings } from './Settings';
import { ChangePassword } from './ChangePassword';
import { Calender } from './Calender';
import { InvoiceListing } from './InvoiceListing';

export const Layout = () => {
    return (
        <>
            <div className="main-wrapper d-flex w-100">
                <div className="sidebar-wrapper">
                    <Sidebar />
                </div>

                <div className="main-content">
                    <Header />
                    <Routes>
                        <Route index element={<Dashboard />} />
                        <Route path="/dashboard"  element={<Dashboard />} />
                        <Route path="/company-list" element={<CompanyList />} />
                        <Route path="/company-files" element={<CompanyFiles />} />
                        <Route path="/company-files-project/:id" element={<CompanyFilesProject />} />
                        <Route path="/add-company" element={<AddCompany />} />
                        <Route path="/edit-company/:id" element={<AddCompany />} />
                        <Route path="/clients-list" element={<ClientsList />} />
                        <Route path="/clients-directory" element={<ClientsDirectory />} />
                        <Route path="/add-client" element={<AddClient />} />
                        <Route path="/edit-client/:id" element={<AddClient />} />
                        <Route path="/edit-client-account/:id" element={<AddClientAccount />} />
                        <Route path="/employees-list" element={<EmployeeList />} />
                        <Route path="/add-employee" element={<AddEmployee />} />
                        <Route path="/edit-employee/:id" element={<AddEmployee />} />
                        <Route path="/edit-employee-account/:id" element={<AddEmployeeAccount />} />
                        <Route path="/cases-list" element={<CasesList />} />
                        <Route path="/client-cases-list" element={<ClientCasesList />} />
                        <Route path="/cases-details" element={<CasesDetails />} />
                        <Route path="/cases-details/:id" element={<CasesDetails />} />
                        <Route path="/add-case" element={<AddCase />} />
                        <Route path="/edit-case/:id" element={<AddCase />} />
                        <Route path="/journal-cases" element={<JournalCaseList />} />
                        <Route path="/add-journal" element={<AddJournalCase />} />
                        <Route path="/add-journal-id/:id" element={<AddJournalCaseId />} />
                        <Route path="/edit-journal/:id" element={<AddJournalCase />} />
                        <Route path="/meeting-list" element={<MeetingsList />} />
                        <Route path="/add-meeting" element={<AddMeeting />} />
                        <Route path="/add-meeting-dashboard" element={<AddMeetingDashboard />} />
                        <Route path="/edit-meeting/:id" element={<AddMeeting />} />
                        <Route path="/milestone-list" element={<MilestoneList />} />
                        <Route path="/add-milestone" element={<AddMilestone />} />
                        <Route path="/edit-milestone/:id" element={<AddMeeting />} />
                        <Route path="/deadline-list" element={<DeadlineList />} />
                        <Route path="/add-deadline" element={<AddDeadline />} />
                        <Route path="/edit-deadline" element={<AddDeadline />} />
                        <Route path="/workspace/:id" element={<Workspace />} />
                        <Route path="/expenses-list" element={<ExpensesListing />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/change-password" element={<ChangePassword />} />
                        <Route path="/calendar" element={<Calender />} />
                        <Route path="/invoice-listing" element={<InvoiceListing />} />
                        <Route path="*" element={<NoPage />} />
                    </Routes>
                </div>
            </div>

            <Outlet />
        </>
    )
}
