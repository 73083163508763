import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import API_URL from '../../Apiurl';

const initialState = {
  isLoading: false,
  error: false,
  success: '',
  stateList: [],
  logList: []
};

const slice = createSlice({
  name: 'state',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false; // Clear previous errors
      state.success = '';  // Clear previous success messages
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.error("Error:", action.payload); // Log error
    },

    // GET STATE LIST SUCCESS
    getStateListSuccess(state, action) {
      state.isLoading = false;
      state.stateList = action.payload.data;
      state.error = false;
      state.success = 'Company list fetched successfully!';
    },
    getLogsListSuccess(state, action) {
      state.isLoading = false;
      state.logList = action.payload.data;
      state.error = false;
      state.success = 'Company list fetched successfully!';
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  getStateListSuccess,
  getLogsListSuccess,
} = slice.actions;

// Async Action
export function getStateList() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}list-state`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getStateListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch state list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the state list"));
    }
  };
}
export function logs() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}logs`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getLogsListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch state list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the state list"));
    }
  };
}
