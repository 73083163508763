import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  getProjectStatusList,
  getProjectTypeList,
} from "../redux/slices/project";
import { getCompanyList } from "../redux/slices/company";
import { getEmployeeList } from "../redux/slices/employee";
import { getCustomerList, getCustomerListId } from "../redux/slices/customer";
import { addProject, editProject, getProjectDetails } from "../redux/slices/project";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

const animatedComponents = makeAnimated();

const projectstatus = [
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "D", label: "D" },
  { value: "E", label: "E" },
  { value: "F", label: "F" },
  { value: "I", label: "I" },
  { value: "C", label: "C" },
];

const customStyles = {
  placeholder: (provided) => ({
    ...provided,
    color: "#969fc2",
    fontSize: "14px",
    fontWeight: "400",
    padding: "0",
  }),
};

export const AddCase = () => {
  const dispatch = useDispatch();
  const [projectName, setProjectName] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  const [status, setStatus] = useState(1);
  const [type, setType] = useState("");
  const [cmp_id, setCompany] = useState("");
  const [customerLead, setCustomerLead] = useState("");
  const [employeeLead, setEmployeeLead] = useState("");
  const [customerParticipants, setCustomerParticipants] = useState([]);
  const [employeeParticipants, setEmployeeParticipants] = useState([]);

  const [estimateNumber, setEstimateNumber] = useState("");
  const [timeEstimate, setTimeEstimate] = useState("");
  const [materialEstimate, setMaterialEstimate] = useState("");
  const [deposit, setDeposit] = useState("");
  const [projectNotes, setProjectNotes] = useState("");
  const [projectSpecs, setProjectSpecs] = useState("");
  const [projectStatusValue, setProjectStatus] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  // const { employeeDetails } = useSelector((state) => state.employee);
  const { projectListType, projectListStatus } = useSelector(
    (state) => state.project
  );
  const { companyList } = useSelector((state) => state.company);
  const { employeeList, editEmployeeList } = useSelector((state) => state.employee);
  const { projectDetails } = useSelector((state) => state.project);
  const { customerList, editCustomerList } = useSelector(
    (state) => state.customer
  );
  // console.log(editCustomerList);
  useEffect(() => {
    dispatch(getProjectStatusList());
    dispatch(getProjectTypeList());
    dispatch(getCompanyList());
    dispatch(getEmployeeList());
    // dispatch(getCustomerList());
  }, [dispatch]);
  useEffect(() => {
    if (id) {
      dispatch(getProjectDetails(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && projectDetails) {
      console.log(projectDetails);
      setProjectName(projectDetails.name)
      setStatus(projectDetails.status)
      setCompany(projectDetails.cmp_id)
      setCustomerLead(projectDetails.lead_cus_id)
      setEmployeeLead(projectDetails.lead_emp_id)
      setCustomerParticipants(projectDetails.participants_array)
      setEmployeeParticipants(projectDetails.eparticipants_array)
      setEstimateNumber(projectDetails.estimate_number)
      setTimeEstimate(projectDetails.time_estimate)
      setMaterialEstimate(projectDetails.material_estimate)
      setDeposit(projectDetails.deposit)
      setProjectNotes(projectDetails.notes)
      setProjectSpecs(projectDetails.specs)
      setProjectStatus(projectDetails.status_id)
      setType(projectDetails.project_type_id)
      setStatus(projectDetails.status_id)
    }
  }, [id, projectDetails]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      name: projectName,
      status_id: status,
      lead_emp_id: employeeLead === "" ? user.id : employeeLead,
      lead_cus_id: customerLead,
      customerParticipants: customerParticipants,
      employeeParticipants: employeeParticipants,
      estimate_number: estimateNumber,
      time_estimate: timeEstimate,
      material_estimate: materialEstimate,
      deposit: deposit,
      notes: projectNotes,
      specs: projectSpecs,
      status: projectStatusValue,
      project_type_id: type,
      cmp_id: cmp_id
    };
    console.log("Form Data Submitted:", formData);
    // debugger
    if (id) {
      dispatch(editProject(id, formData, navigate));
    } else {
      dispatch(addProject(formData,navigate));
    }
    // Add your form submission logic here, e.g., sending data to a server
  };
  const changeCompany = (e) => {
    setCompany(e);
    dispatch(getCustomerListId(e));
  }
  return (
    <div className="addCasePage py-3">
      <div className="container-fluid">
        <div className="bg-white rounded-4 shadow-sm mx-auto px-3 px-md-5 pt-4 pb-5" style={{ maxWidth: "850px" }} >
          <div className="heading-wrapper text-center mt-md-2 mb-4">
            <div className="fs-3 fw-bold">{id ? "Edit" : "Add New"} Case</div>
          </div>
          <Form onSubmit={handleSubmit}>
            <div className="row gx-2 gx-md-3">
              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    Project Name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter project name"
                    name="projectName"
                    autoComplete="off"
                    required
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                  />
                </Form.Group>
              </div>

              <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    {/* <option value="">Preparing</option>
                    <option value="Completed">Completed</option>
                    <option value="Hold">Hold</option> */}
                    {/* <option selected disabled value="">
                      Select status
                    </option> */}
                    {projectListStatus?.map((e) => {
                      return <option value={e.id}>{e.name}</option>;
                    })}
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Company</Form.Label>
                  <Form.Select
                    value={cmp_id}
                    onChange={(e) => {
                      changeCompany(e.target.value);
                    }}
                  >
                    <option selected disabled value="">
                      Select Company
                    </option>
                    {companyList?.map((e) => {
                      return <option value={e.id}>{e.name}</option>;
                    })}
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Select Project type</Form.Label>
                  <Form.Select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    required
                  >
                    {/* <option value="">Preparing</option>
                                        <option value="Completed">Completed</option>
                                        <option value="Hold">Hold</option> */}
                    <option selected disabled value="">
                      Select Project type
                    </option>
                    {projectListType?.map((e) => {
                      return <option value={e.id}>{e.name}</option>;
                    })}
                  </Form.Select>
                </Form.Group>
              </div>

              {/* <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Select Project status</Form.Label>
                  <Form.Select
                    value={projectStatusValue}
                    onChange={(e) => setProjectStatus(e.target.value)}
                  >
                    <option selected disabled value="">
                      Select Project Status
                    </option>
                    {projectstatus?.map((e) => {
                      return <option value={e.value}>{e.label}</option>;
                    })}
                  </Form.Select>
                </Form.Group>
              </div> */}

              <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Employee Lead</Form.Label>
                  <Form.Select
                    value={employeeLead}
                    onChange={(e) => setEmployeeLead(e.target.value)}
                  >
                    <option selected disabled value="">
                      Select employee lead
                    </option>
                    {employeeList?.map((e) => {
                      return (
                        <option value={e.id}>
                          {e.first_name + " " + e.last_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Customer Lead</Form.Label>
                  <Form.Select
                    value={customerLead}
                    onChange={(e) => setCustomerLead(e.target.value)}
                  >
                    <option selected disabled value="">
                      Select Customer
                    </option>
                    {customerList?.map((e) => {
                      return (
                        <option value={e.id}>
                          {e.first_name + " " + e.last_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Employee Participants</Form.Label>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    className="custom-select text-dark"
                    classNamePrefix="custom-select"
                    options={editEmployeeList}
                    styles={customStyles}
                    placeholder="Add Employee Participants"
                    value={employeeParticipants}
                    onChange={(selected) => setEmployeeParticipants(selected)}
                  />
                </Form.Group>
              </div>

              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Customer Participants</Form.Label>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    className="custom-select text-dark"
                    classNamePrefix="custom-select"
                    options={editCustomerList}
                    styles={customStyles}
                    placeholder="Add Participants"
                    value={customerParticipants}
                    onChange={(selected) => setCustomerParticipants(selected)}
                  />
                </Form.Group>
              </div>

              <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Estimate Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter estimate number"
                    name="estimateNumber"
                    autoComplete="off"
                    value={estimateNumber}
                    onChange={(e) => setEstimateNumber(e.target.value)}
                  />
                </Form.Group>
              </div>

              <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Time Estimate($)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter time estimate"
                    name="timeEstimate"
                    autoComplete="off"
                    value={timeEstimate}
                    onChange={(e) => setTimeEstimate(e.target.value)}
                  />
                </Form.Group>
              </div>

              <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Material Estimate($)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter material estimate"
                    name="materialEstimate"
                    autoComplete="off"
                    value={materialEstimate}
                    onChange={(e) => setMaterialEstimate(e.target.value)}
                  />
                </Form.Group>
              </div>

              <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Deposit($)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter deposit"
                    name="Deposit"
                    autoComplete="off"
                    value={deposit}
                    onChange={(e) => setDeposit(e.target.value)}
                  />
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Project Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Enter project notes"
                    name="projectNotes"
                    maxLength="250"
                    autoComplete="off"
                    value={projectNotes}
                    required
                    onChange={(e) => setProjectNotes(e.target.value)}
                  />
                  <div className="fs-12 text-secondary text-end mt-1">
                    Maximum 250 characters
                  </div>
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Project Specs</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Enter project specs"
                    name="projectSpecs"
                    maxLength="250"
                    autoComplete="off"
                    value={projectSpecs}
                    required
                    onChange={(e) => setProjectSpecs(e.target.value)}
                  />
                  <div className="fs-12 text-secondary text-end mt-1">
                    Maximum 250 characters
                  </div>
                </Form.Group>
              </div>
            </div>

            <div className="btn-wrapper d-flex flex-wrap gap-3 pt-3">
              <Button
                type="submit"
                variant="primary"
                className="btn-custom text-decoration-none px-4"
              >
                {id ? "Edit" : "Add New"} Project
              </Button>
              <Button
                type="reset"
                variant="secondary"
                className="btn-custom text-decoration-none px-5"
                onClick={() => {
                  setProjectName("");
                  setStatus("");
                  setCompany("");
                  setCustomerLead("");
                  // setEmployeeLead("");
                  setCustomerParticipants([]);
                  setEmployeeParticipants([]);
                  setEstimateNumber("");
                  setTimeEstimate("");
                  setMaterialEstimate("");
                  setDeposit("");
                  setProjectNotes("");
                  setProjectSpecs("");
                }}
              >
                Reset
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
