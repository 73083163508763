import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { getCompanyList } from "../redux/slices/company";
import { useSelector, useDispatch } from "react-redux";
import { getStateList } from "../redux/slices/state";
import {
  addCustomer,
  getCustomerDetails,
  editCustomer,
} from "../redux/slices/customer";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

export const AddClient = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { customerDetails } = useSelector((state) => state.customer);

  const { isLoading, error, success, companyList } = useSelector(
    (state) => state.company
  );
  const { countryList } = useSelector((state) => state.country);
  const { stateList } = useSelector((state) => state.state);

  useEffect(() => {
    dispatch(getStateList());
    dispatch(getCompanyList());
  }, [dispatch]);
  useEffect(() => {
    if (id) {
      dispatch(getCustomerDetails(id));
    }
  }, [dispatch, id]);
  useEffect(() => {
    if (id && customerDetails) {
      setFormData(customerDetails);
      console.log(customerDetails);
    }
  }, [customerDetails, dispatch, id])
  const [formData, setFormData] = useState({
    cmp_id: localStorage.getItem("cmp_id"),
    salutation: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    department: "",
    title: "",
    address1: "",
    address2: "",
    city: "",
    sta_id: "",
    zipcode: "",
    cnt_iso: "",
    phone: "",
    alt_phone: "",
    // alt_phone_2: "",
    fax: "",
    emailid: "",
    email_list: "Y",
    birthdate: "",
    status: "A",
    user_name: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData, [name]: value };

    if (name === "sta_id") {
      const selectedState = stateList.find(
        (state) => state.id === parseInt(value)
      );
      if (selectedState) {
        updatedFormData = {
          ...updatedFormData,
          cnt_iso: selectedState.cnt_iso,
        };
      }
    }

    setFormData(updatedFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData);
    // dispatch(addCustomer(formData));
    if (id) {
      await dispatch(editCustomer(id, formData, navigate));
    } else {
      await dispatch(addCustomer(formData, navigate));
    }
  };
  const today = moment().format("YYYY-MM-DD");

  return (
    <div className="addClientPage py-3">
      <div className="container-fluid">
        <div className="bg-white rounded-4 shadow-sm mx-auto px-3 px-md-5 pt-4 pb-5" style={{ maxWidth: "850px" }} >
          <div className="heading-wrapper text-center mt-md-2 mb-4">
            <div className="fs-3 fw-bold">{id ? "Edit" : "Add"} Client</div>
          </div>
          <Form onSubmit={handleSubmit}>
            <div className="row gx-2 gx-md-3">
              {/* <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Salutation</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Salutation"
                    name="salutation"
                    value={formData?.salutation}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </div> */}
              {id ? (
                <>
                <div className="col-sm-6">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      User name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="user_name"
                      placeholder="Enter user_name"
                      autoComplete="off"
                      value={formData?.user?.username}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </div>
                <div className="col-sm-6">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Password<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Enter password"
                      autoComplete="off"
                      value={formData?.password}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </div>
              </>
              ) : (
                <>
                  <div className="col-sm-6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        User name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="user_name"
                        placeholder="Enter user_name"
                        autoComplete="off"
                        value={formData?.user_name}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className="col-sm-6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Password<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="Enter password"
                        autoComplete="off"
                        value={formData?.password}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </div>
                </>
              )}
              <div className="col-4 col-md-2">
                <Form.Group className="mb-3">
                  <Form.Label>Mr/Mrs/Ms</Form.Label>
                  <Form.Select
                    name="salutation"
                    value={formData?.salutation}
                    onChange={handleInputChange}
                  >
                    <option selected disabled value="">
                      Select...
                    </option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Ms">Ms</option>
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="col-8 col-md-4">
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    name="first_name"
                    value={formData?.first_name}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </div>

              <div className="col-6 col-md-3">
                <Form.Group className="mb-3">
                  <Form.Label>Middle Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter middle name"
                    name="middle_name"
                    value={formData?.middle_name}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </div>
              <div className="col-6 col-md-3">
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter last name"
                    name="last_name"
                    value={formData?.last_name}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter title"
                    name="title"
                    value={formData?.title}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Department</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Department"
                    name="department"
                    value={formData?.department}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </div>
              <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Address1</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter address1"
                    name="address1"
                    value={formData?.address1}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </div>
              <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Address2</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter address2"
                    name="address2"
                    value={formData?.address2}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter city"
                    name="city"
                    value={formData?.city}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Form.Select
                    name="sta_id"
                    value={formData?.sta_id}
                    onChange={handleInputChange}
                  >
                    <option disabled value="">
                      Select state
                    </option>
                    {stateList?.map((e) => (
                      <option key={e.id} value={e.id}>
                        {e.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Zipcode</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter zipcode"
                    name="zipcode"
                    value={formData?.zipcode}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter phone number"
                    name="phone"
                    value={formData?.phone}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Alt Phone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter alternate number"
                    name="alt_phone"
                    value={formData?.alt_phone}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </div>

              {/* <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Alt Phone 2</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter alt phone 2"
                    name="alt_phone_2"
                    value={formData?.alt_phone_2}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </div> */}

              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Fax</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter fax"
                    name="fax"
                    value={formData?.fax}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </Form.Group>
              </div>

              <div className="col-6 col-xl-3">
                <Form.Group className="mb-3">
                  <Form.Label>Company</Form.Label>
                  <Form.Select
                    name="cmp_id"
                    value={formData?.cmp_id}
                    onChange={handleInputChange}
                    required
                  >
                    <option disabled value="">
                      Select company
                    </option>
                    {companyList?.map((e) => (
                      <option key={e.id} value={e.id}>{e.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Email Id</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email Id"
                    name="emailid"
                    value={formData?.emailid}
                    onChange={handleInputChange}
                    autoComplete="off"
                    required
                  />
                </Form.Group>
              </div>

              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Email List</Form.Label>
                  <Form.Select
                    name="email_list"
                    value={formData?.email_list}
                    onChange={handleInputChange}
                  >
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Birthdate</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Enter birthdate"
                    name="birthdate"
                    value={formData?.birthdate}
                    onChange={handleInputChange}
                    autoComplete="off"
                  // min={today}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="submitBtn text-center pt-3">
              <Button
                variant="primary"
                className="px-5 py-2"
                type="submit"
                disabled={isLoading}
              >
                Submit
              </Button>
            </div>

            {/* {error && <p className="text-danger mt-3">{error.message}</p>}
            {success && <p className="text-success mt-3">{success}</p>} */}
          </Form>
        </div>
      </div>
    </div>
  );
};
