import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import API_URL from '../../Apiurl';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  error: false,
  success: '',
  companyList: [],
  deleteLoading: false,
  deleteSuccess: '',
  deleteError: '',
  companyDetails: null
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false; // Clear previous errors
      state.success = '';  // Clear previous success messages
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.error("Error:", action.payload); // Log error
    },

    // GET COMPANY LIST SUCCESS
    getCompanyListSuccess(state, action) {
      state.isLoading = false;
      state.companyList = action.payload.data;
      state.error = false;
      state.success = 'Company list fetched successfully!';
    },

    // START DELETE LOADING
    startDeleteLoading(state) {
      state.deleteLoading = true;
      state.deleteError = false; // Clear previous errors
      state.deleteSuccess = ''; // Clear previous success messages
    },

    // DELETE HAS ERROR
    deleteHasError(state, action) {
      state.deleteLoading = false;
      state.deleteError = action.payload;
      console.error("Delete Error:", action.payload); // Log error
    },

    // DELETE COMPANY SUCCESS
    deleteCompanySuccess(state, action) {
      state.deleteLoading = false;
      state.deleteSuccess = 'Company deleted successfully!';
      state.deleteError = false;

      // Optionally, remove the deleted company from the companyList
      state.companyList = state.companyList?.filter(company => company.id !== action.payload.id);
    },
    getCompanyDetailsSuccess(state, action) {
      state.isLoading = false;
      state.companyDetails = action.payload.data;
      state.error = false;
    }  
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  getCompanyListSuccess,
  startDeleteLoading,
  deleteHasError,
  deleteCompanySuccess,
  getCompanyDetailsSuccess
} = slice.actions;

// Async Action to Get Company List
export function getCompanyList() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}list-company`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getCompanyListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch company list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the company list"));
    }
  };
}

// Async Action to Add Company
export function addCompany(e) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.post(`${API_URL}add-company`, e, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getCompanyListSuccess(resp.data));
        toast("Company added sucessfully!", {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
      } else {
        toast.error("Company not added!", {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
        dispatch(hasError(resp.message || "Failed to add company"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while adding the company"));
    }
  };
}

// Async Action to Delete Company
export function deleteCompany(companyId) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const resp = await axios.delete(`${API_URL}delete-company/${companyId}`);
      console.log("API delete response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(deleteCompanySuccess({ id: companyId }));
      } else {
        dispatch(deleteHasError(resp.message || "Failed to delete company"));
      }
    } catch (error) {
      console.error("Caught delete error:", error); // Log caught error
      dispatch(deleteHasError(error.message || "An error occurred while deleting the company"));
    }
  };
}
export function getCompanyDetails(companyId) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}view-company/${companyId}`);
      console.log("API response:", resp);
      if (resp && resp.status === 200) {
        dispatch(getCompanyDetailsSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch company details"));
      }
    } catch (error) {
      console.error("Caught details error:", error);
      dispatch(hasError(error.message || "An error occurred while fetching the company details"));
    }
  };
}
export function editCompany(id, e, logo) {
  return async (dispatch) => {
    console.log(e)
    dispatch(startLoading());
    try {
      const resp = await axios.put(`${API_URL}update-company/${id}`, e, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        toast("Company updated sucessfully!", {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
        const formData = new FormData();
        formData.append('logo', logo);
        formData.append('id', id);
        const resp1 = await axios.post(`${API_URL}update-logo-company`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        
        if (resp1 && resp1.status === 200) {
          toast("Company logo changed sucessfully!", {
            autoClose: 5000 // 5000 milliseconds = 5 seconds
          });
          dispatch(getCompanyListSuccess(resp.data));
        }
        else {
          toast.error("Company not updated!", {
            autoClose: 5000 // 5000 milliseconds = 5 seconds
          });
          dispatch(hasError(resp.message || "Failed to add company"));
        }
      } else {
        dispatch(hasError(resp.message || "Failed to add company"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while adding the company"));
    }
  };
}
