import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../assets/search.svg";
import { useSelector, useDispatch } from "react-redux";
import { getProjectClientId, deleteProject, addProjectFiles } from "../redux/slices/project";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import { Tabs, Tab, Form, Button, Spinner } from "react-bootstrap";
import { NoDataComponent } from "./NoDataComponent";

export const ClientCasesList = () => {
  const [show, setShow] = useState(false);
  const [showTab, setShowTab] = useState(false);
  // const [selectedProject, setSelectedProject] = useState(null);
  const [activeTab, setActiveTab] = useState("tab1");
  const [projectId, setProjectId] = useState(0);
  const [cmp_id, setCompanyId] = useState(0);
  const navigate = useNavigate();
  const { isLoading, projectList, projectDetails } = useSelector(
    (state) => state.project
  );
  const { eventList } = useSelector((state) => state.event);
  const { filesList, journalList } = useSelector((state) => state.journal);
  const dispatch = useDispatch();
  
  const [selectedStatus, setSelectedStatus] = useState("In Progress");
  const [formData, setFormData] = useState({
    prj_id: projectId,
    cmp_id: cmp_id,
    name: "",
    path: "project_files/",
    size: "",
    type: "",
    description: "",
    status: "A",
    is_processed: "YES",
    file: []
  });
  const [search, setSearch] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);
  // Add Project Files Modal
  const [showFilesModal, setFilesModal] = useState(false);
  const openFilesModal = (id, companyId) => {
    setProjectId(id);
    setCompanyId(companyId)
    setFilesModal(true);
  };
  const closeFilesModal = () => setFilesModal(false);

  useEffect(() => {
    const user = localStorage.getItem("lead_cus_id");
    // const id = user.user_id;
    dispatch(getProjectClientId(user));
    console.log(projectList, "projectList");
    setFilteredProjects(projectList)
  }, [dispatch]);
  useEffect(() => {
    setFilteredProjects(
      projectList?.filter((project) => 
        project.project_status_name === selectedStatus
      )
    )
  }, [projectList]);
  useEffect(() => {
    setFilteredProjects(
      projectList?.filter((project) => 
        project.project_status_name === selectedStatus
      )
    )
  }, [selectedStatus]);

  // const handleRowClick = (row) => {
  //   navigate(`/journal-cases`);
  //   localStorage.setItem("prj_id", JSON.stringify(row.id));
  // };
  const handleRowClick = (row) => {
    navigate(`/cases-details/${row.id}`);
    localStorage.setItem("prj_id", JSON.stringify(row.id));
  };

  const handleViewDetails = (companyId) => {
    navigate("/edit-case/" + companyId, { state: { companyId } });
  };

  const handleShow = (row) => {
    setShow(true);
    setProjectId(row.id);
  };

  const handleShowTabs = (row) => {
    console.log(row);
    localStorage.setItem("lead_cus_id", JSON.stringify(row.lead_cus_id));
    localStorage.setItem("lead_emp_id", JSON.stringify(row.lead_emp_id));
    navigate("/workspace/" + row.id);
  };

  // console.log(projectDetails);
  const handleClose = () => setShow(false);
  const handleCloseTab = () => setShowTab(false);
  const handleSubmit = async () => {
    // e.preventDefault();
    console.log(formData)
    dispatch(addProjectFiles(formData));
    setFilesModal(false);
  };
  const columns = [
    // { name: "Sr. No", selector: (row, index) => index + 1, sortable: true, width: '90px' },
    { name: "Project Name", selector: (row) => row.name, sortable: true },
    {
      name: "Project Type",
      selector: (row) => row.project_type,
      sortable: true,
    },
    {
      name: "Project Status",
      selector: (row) => row.project_status_name,
      sortable: true,
    },
    // { name: "Status", selector: (row) => row.status, sortable: true },
    { name: "Company Name", selector: (row) => row.name, sortable: true },
    // {
    //   name: "Employee Lead",
    //   selector: (row) => row.salutation1 + " " + row.first_name1 + " " + row.last_name1,
    //   sortable: true,
    // },
    {
      name: "Customer Lead",
      selector: (row) => row.salutation2 + " " + row.first_name2 + " " + row.last_name2,
      sortable: true,
    },
    // { name: "Spec", selector: (row) => row.specs, sortable: true },
    {
      name: "Add Files",
      cell: (row) => (
        <button
          className="btn btn-success btn-sm"
          onClick={() => openFilesModal(row.id, row.cmp_id)}
        >
          <i className="bi bi-folder-plus"></i> Add Files
        </button>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="action-wrapper d-flex gap-2">
          <button
            className="btn btn-primary btn-sm"
            title="Workspaces"
            onClick={() => handleShowTabs(row)}
          >
            {/* <span>W</span> */}
            <i className="bi bi-eye fs-6"></i>
          </button>
          <button
            className="btn btn-warning btn-sm"
            onClick={() => handleViewDetails(row.id)}
            title="Edit"
          >
            <i className="bi bi-pencil-fill fs-14"></i>
          </button>
          <button
            className="btn btn-danger btn-sm"
            title="Delete"
            onClick={() => handleShow(row)}
          >
            <i className="bi bi-trash3 fs-14"></i>
          </button>
        </div>
      ),
    },
  ];

  // Style Customization for Data Table
  const customStyles = {
    rows: {
      style: {
        fontSize: '14px',
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: '600',
        padding: "5px 12px",
        color: '#fff',
        backgroundColor: 'var(--bs-primary)',
        minHeight:'45px',
      },
    },
    cells: {
      style: {
        color: '#3f474f',
        fontSize: "14px",
        padding: "5px 12px",
        minHeight:'40px',
      },
    },
  };

  const handleFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      console.log(file);
      await setFormData({
        ...formData,
        name: file.name,
        type: file.type,
        size: file.size,
        prj_id: projectId,
        file: file
      });
    }
    console.log(formData);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      description: value,
    });
  };
  var filteredProject = projectList?.filter((project) => 
    selectedStatus === "All" || project.project_status_name === selectedStatus
  );
  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    setFilteredProjects(
      projectList?.filter(
        (project) =>
          selectedStatus === "All" ||
          project.project_status_name === selectedStatus
      )
    );
  };
  const handleSearch = (event) => {
    
    setSearch(event.target.value);
    console.log(search)
    setFilteredProjects(projectList?.filter(
      (event) => event.name.toLowerCase().includes(search.toLowerCase())
    ));
}
  return (
    <div className="CasesListPage py-3">
      <div className="container-fluid">
        <div className="heading-wrapper d-flex flex-wrap align-items-center justify-content-between gap-3 mb-3">
          <div className="fs-4 fw-bold lh-1">Projects/Cases List</div>
          <div className="d-flex flex-wrap gap-2">
            <button className="btn btn-primary" onClick={() => navigate("/add-case")}>
              <i className="bi bi-folder-plus"></i> Add Case
            </button>
            {/* <button className="btn btn-success" onClick={() => navigate("/expenses-list")}>
              <i className="bi bi-folder-plus"></i> Add Expense
            </button> */}
          </div>
        </div>
        <div className="filter-wrapper mb-3">
          <Form.Select value={selectedStatus} onChange={(e) => handleStatusChange(e.target.value)}>
            {/* <option value="All">All Statuses</option> */}
            <option value="Preparing">Preparing</option>
            <option value="Waiting">Waiting</option>
            <option value="On Hold">On Hold</option>
            <option value="In Progress">In Progress</option>
            <option value="Billed">Billed</option>
          </Form.Select>
        </div>

        <div className="card border-0 rounded-3 shadow-sm p-3">
          <div className="searchfield-wrapper position-relative fs-6 ms-auto mb-3">
            <input type="search" className="form-control ms-auto" placeholder="Search cases here..." autoComplete="off" onChange={handleSearch}/>
            <img src={SearchIcon} alt="Search Icon" className="position-absolute top-50 end-0 translate-middle-y pe-3"
            />
          </div>
          <div className="table-responsive table-custom-wrapper">
            <DataTable
              columns={columns}
              data={filteredProjects}
              pagination
              highlightOnHover
              pointerOnHover
              progressPending={isLoading}
              noDataComponent={<NoDataComponent />}
              onRowClicked={handleRowClick}
              customStyles={customStyles}
              dense
              responsive
            />
          </div>
        </div>
      </div>

      <Modal
        show={showTab}
        onHide={handleCloseTab}
        centered
        size="xl"
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Header closeButton className="bg-primary bg-opacity-10">
          <Modal.Title className="fs-6 text-primary lh-sm">
            {/* Project: {selectedProject && selectedProject.name} */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            id="controlled-tab-example"
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
            className="mb-3"
          >
            <Tab eventKey="tab1" title="Case File(s)">
              <div className="content-wrapper">
                <div className="table-responsive table-custom-wrapper fs-14">
                  <table className="table table-hover align-middle m-0">
                    <thead>
                      <tr>
                        <th scope="col" className="fw-medium text-nowrap">
                          Sr. No
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Name
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          New Today
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filesList.map((e) => {
                        return (
                          <tr>
                            <td>{e.id}</td>
                            <td className="text-nowrap text-capitalize">
                              {e.name}
                            </td>
                            <td className="text-nowrap text-capitalize">
                              {e.size / 1024} kb
                            </td>
                            <td className="text-nowrap text-capitalize">
                              {e.name}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="tab2" title="Case Details">
              <div>Project Details</div>
              <ul>
                <li>Name : {projectDetails?.name}</li>
                <li>Notes: {projectDetails?.notes}</li>
                <li>Participants: {projectDetails?.participants?.length}</li>
              </ul>
            </Tab>
            <Tab eventKey="tab3" title="Case Journal(s)">
              <div className="content-wrapper">
                <div className="table-responsive table-custom-wrapper fs-14">
                  <table className="table table-hover align-middle m-0">
                    <thead>
                      <tr>
                        <th scope="col" className="fw-medium text-nowrap">
                          Sr. No
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Title
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Description
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {journalList.map((e) => {
                        return (
                          <tr>
                            <td>{e.id}</td>
                            <td className="text-nowrap text-capitalize">
                              {e.title}
                            </td>
                            <td className="text-nowrap text-capitalize">
                              {e.description}
                            </td>
                            <td className="text-nowrap text-capitalize">
                              {e.date}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="tab4" title="Case Schedule(s)">
              <div className="content-wrapper">
                <div className="table-responsive table-custom-wrapper fs-14">
                  <table className="table table-hover align-middle m-0">
                    <thead>
                      <tr>
                        <th scope="col" className="fw-medium text-nowrap">
                          Title
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Date
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Start Time
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          End Time
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Post Type
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Location
                        </th>
                        <th scope="col" className="fw-medium text-nowrap">
                          Details
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {eventList.map((e) => {
                        return (
                          <tr>
                            <td>{e.title}</td>
                            <td className="text-nowrap text-capitalize">
                              {e.date}
                            </td>
                            <td className="text-nowrap text-capitalize">
                              {e.start_time}
                            </td>
                            <td className="text-nowrap text-capitalize">
                              {e.end_time}
                            </td>
                            <td className="text-nowrap text-capitalize">
                              {e.post_type}
                            </td>
                            <td className="text-nowrap text-capitalize">
                              {e.location}
                            </td>
                            <td className="text-nowrap text-capitalize">
                              {e.details}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>

      {/* Delete Specific Company From Table */}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Body className="p-md-4 p-lg-5 text-center">
          <div className="fs-18 fw-semibold lh-sm mb-4">
            Are you sure you want to delete this case
          </div>
          <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3">
            <button
              type="button"
              className="btn btn-danger px-4"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary px-4"
              onClick={() => {
                dispatch(deleteProject(projectId));
                handleClose();
              }}
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Add Project Files Modal */}
      <Modal
        show={showFilesModal}
        onHide={closeFilesModal}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        contentClassName="rounded-4"
      >
        <Modal.Header closeButton className="bg-primary bg-opacity-10">
          <Modal.Title className="fs-6 text-primary lh-sm">
            <i className="bi bi-file-earmark-text-fill"></i> Add File Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="projectFile" className="mb-3">
              <Form.Label>
                File Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="file"
                className="shadow-none"
                // multiple
                required
                onChange={handleFileChange}
                style={{ minHeight: "auto" }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="fileDescription">
              <Form.Label>
                Description<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                autoComplete="off"
                maxLength={100}
                onChange={handleInputChange}
                name="description"
                placeholder="Enter your description here..."
                required
              />
              <div className="fs-12 text-secondary mt-1">
                Maximum 100 characters
              </div>
            </Form.Group>
            <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-2 pt-2 mb-3">
              <Button
                // type="submit"
                variant="primary"
                onClick={handleSubmit}
                className="px-4"
              >
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {" Loading..."}
                  </>
                ) : (
                  "Add"
                )}
                
              </Button>
              <Button type="reset" variant="danger" className="px-4">
                Reset
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
