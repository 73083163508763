import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Tabs, Tab } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logs } from "../redux/slices/state";
import DataTable from "react-data-table-component";
import { NoDataComponent } from "./NoDataComponent";

export const Settings = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("tab1");
  const { isLoading, logList } = useSelector((state) => state.state);
  // Bootstrap Modal
  const [show, setShow] = useState(false);
  const openExpenseModal = () => setShow(true);
  const closeExpenseModal = () => setShow(false);
  const dispatch = useDispatch();

  const customStyles = {
    rows: {
      style: {
        fontSize: '14px',
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: '600',
        padding: "5px 12px",
        color: '#fff',
        backgroundColor: 'var(--bs-primary)',
        minHeight:'45px',
      },
    },
    cells: {
      style: {
        color: '#3f474f',
        fontSize: "14px",
        padding: "5px 12px",
        minHeight:'40px',
      },
    },
  };

  useEffect(() => {
    dispatch(logs());
  }, []);

  // Columns for DataTable
  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Remote ADDR",
      selector: (row) => row.remote_addr,
      sortable: true,
      maxWidth: "250px",
    },
    {
      name: "Platform",
      selector: (row) => row.platform,
      sortable: true,
      maxWidth: "400px",
    },
    {
      name: "Time",
      selector: (row) => row.created_at,
      sortable: true,
      maxWidth: "150px",
      cell: (row) => (
        <div className="d-flex gap-2 text-nowrap text-capitalize">
          <span className="date">{row.date}</span>
          <span className="time">{row.time}</span>
        </div>
      ),
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      maxWidth: "200px",
    },
    {
      name: "MSG",
      selector: (row) => row.message,
      sortable: true,
    },
  ];

  return (
    <div className="WorkspacePage py-3">
      <div className="container-fluid">
        <div className="fs-4 fw-bold lh-1 mb-3">Settings Information</div>

        <div className="wrapper">
          <Tabs
            id="controlled-tab-example"
            className="tabs-nav-wrapper flex-nowrap text-nowrap overflow-x-auto bg-secondary bg-opacity-10 border-bottom-0 rounded-top-3 fw-medium mb-3"
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
          >
            <Tab eventKey="tab1" title="Application Preferences">
              <div className="info-card-wrapper">
                <div className="bg-dark bg-opacity-10 text-primary fw-medium rounded-top-3 px-3 py-2">
                  <i className="bi bi-layout-text-sidebar-reverse me-1"></i>{" "}
                  Application Preferences
                </div>
                <div className="card rounded-top-0 p-3">
                  <div className="table-content-wrapper">
                    <div className="table-responsive fs-14">
                      <table className="table table-bordered border-secondary border-opacity-25 m-0">
                        <tbody>
                          <tr>
                            <td className="fw-medium text-success text-nowrap">
                              Change Password
                            </td>
                            <td className="text-nowrap">
                              <Link
                                to={"/change-password"}
                                className="text-primary"
                              >
                                Change own password
                              </Link>
                            </td>
                          </tr>
                          {JSON.parse(localStorage.getItem("usertype")) ===
                          "E" ? (
                            <>
                              <tr>
                                <td className="fw-medium text-success text-nowrap">
                                  Edit Company Info
                                </td>
                                <td className="text-nowrap">
                                  <Link
                                    to={"/company-list"}
                                    className="text-primary"
                                  >
                                    Edit company info
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-medium text-success text-nowrap">
                                  Employees
                                </td>
                                <td className="text-nowrap">
                                  <Link
                                    to={"/employees-list"}
                                    className="text-primary"
                                  >
                                    Add/Edit/Delete Employees
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-medium text-success text-nowrap">
                                  Billing Rates
                                </td>
                                <td className="text-nowrap">
                                  <Link to={"#"} className="text-primary">
                                    Add/Edit/Delete Hour rates
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-medium text-success text-nowrap">
                                  Auto-Generated Emails
                                </td>
                                <td className="text-nowrap">
                                  <Link to={"#"} className="text-primary">
                                    Edit Application Email's Content
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-medium text-success text-nowrap">
                                  Internal Divisions(optional)
                                </td>
                                <td className="text-nowrap">
                                  <Link to={"#"} className="text-primary">
                                    Add/Edit/Delete Divisions
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-medium text-success text-nowrap">
                                  Client Industry(optional)
                                </td>
                                <td className="text-nowrap">
                                  <Link
                                    to={"/clients-list"}
                                    className="text-primary"
                                  >
                                    Add/Edit/Delete Industry
                                  </Link>
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="tab2" title="Security Logs">
              <div className="info-card-wrapper">
                <div className="bg-dark bg-opacity-10 text-primary fw-medium rounded-top-3 px-3 py-2">
                  <i className="bi bi-list-columns-reverse me-1"></i> Log List
                </div>
                <div className="card rounded-top-0 p-3">
                  <div className="table-responsive table-custom-wrapper">
                    <DataTable
                      columns={columns}
                      data={logList}
                      pagination
                      highlightOnHover
                      pointerOnHover
                      progressPending={isLoading}
                      noDataComponent={<NoDataComponent />}
                      // onRowClicked={handleRowClick}
                      customStyles={customStyles}
                      dense
                      responsive
                    />
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
