import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../assets/search.svg";
import { useSelector, useDispatch } from "react-redux";
import { getCompanyList, deleteCompany } from "../redux/slices/company";
import { getCountryList } from "../redux/slices/countries";
import { getStateList } from "../redux/slices/state";
import DataTable from 'react-data-table-component';
import { NoDataComponent } from "./NoDataComponent";

export const CompanyList = () => {
  const navigate = useNavigate();
  const { isLoading, error, success, companyList } = useSelector(
    (state) => state.company
  );
  const [companyId, setCompanyId] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCountryList());
    dispatch(getStateList());
    dispatch(getCompanyList());
  }, [dispatch]);

  useEffect(() => {
    if (companyList.length > 0) {
      setFilteredCompanies(
        companyList?.filter(company =>
          company.name.toLowerCase().includes(searchText.toLowerCase()) ||
          company.address1.toLowerCase().includes(searchText.toLowerCase()) ||
          company.address2.toLowerCase().includes(searchText.toLowerCase()) ||
          company.city.toLowerCase().includes(searchText.toLowerCase()) ||
          company.phone.toLowerCase().includes(searchText.toLowerCase()) ||
          company.fax.toLowerCase().includes(searchText.toLowerCase()) ||
          company.url.toLowerCase().includes(searchText.toLowerCase()) ||
          company.status.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
    else {
      setFilteredCompanies([])
    }
  }, [searchText, companyList]);

  const handleViewDetails = (companyId) => {
    navigate("/edit-company/" + companyId, { state: { companyId } });
  };

  const handleRowClick = (row) => {
    navigate(`/clients-list`);
    localStorage.setItem('cmp_id', JSON.stringify(row.id));
    localStorage.setItem('company_details', JSON.stringify(row));
  };

  const columns = [
    // { name: 'Sr. No', selector: (row, index) => index + 1, sortable: true, width:'90px' },
    { name: 'Name', selector: row => row.name, sortable: true, minWidth: '200px' },
    // {
    //   name: 'Logo',
    //   selector: row => (
    //     <img
    //       src={`https://oitrack.s3.us-west-1.amazonaws.com/${row.logo}`}
    //       className="rounded-circle"
    //       style={{ width: "40px", height: "40px" }}
    //       alt="Company Logo"
    //     />
    //   )
    // },
    { name: 'Address 1', selector: row => row.address1, sortable: true, minWidth: '200px' },
    { name: 'Address 2', selector: row => row.address2, sortable: true },
    { name: 'City', selector: row => row.city, sortable: true, minWidth: '180px' },
    { name: 'Phone Number', selector: row => row.phone, sortable: true, minWidth: '150px' },
    // { name: 'Fax Number', selector: row => row.fax, sortable: true, minWidth: '150px' },
    // { name: 'Website URL', selector: row => row.url, sortable: true, minWidth: '180px' },
    // { name: 'Status', selector: row => row.status, sortable: true },
    {
      name: 'Action',
      cell: row => (
        <div className="action-wrapper d-flex gap-2">
          <button
            className="btn btn-warning btn-sm"
            title="Edit"
            onClick={() => handleViewDetails(row.id)}
          >
            <i className="bi bi-pencil-fill fs-14"></i>
          </button>
          <button
            className="btn btn-danger btn-sm"
            title="Delete"
            data-bs-toggle="modal"
            data-bs-target="#deleteCompany"
            onClick={() => setCompanyId(row.id)}
          >
            <i className="bi bi-trash3 fs-14"></i>
          </button>
        </div>
      )
    }
  ];

  // Style Customization for Data Table
  const customStyles = {
    rows: {
      style: {
        fontSize: '14px',
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: '600',
        padding: "5px 12px",
        color: '#fff',
        backgroundColor: 'var(--bs-primary)',
        minHeight:'45px',
      },
    },
    cells: {
      style: {
        color: '#3f474f',
        fontSize: "14px",
        padding: "5px 12px",
        minHeight:'40px',
      },
    },
  };

  return (
    <div className="companyListPage py-3">
      <div className="container-fluid">
        <div className="heading-wrapper d-flex flex-wrap align-items-center justify-content-between gap-3 mb-3">
          <div className="fs-4 fw-bold lh-1">Company List</div>
          <div className="d-flex flex-wrap gap-2">
            <button
              className="btn btn-primary"
              onClick={() => navigate("/add-company")}
            >
              <i className="bi bi-plus-circle"></i> Add company
            </button>
          </div>
        </div>

        <div className="card border-0 rounded-3 shadow-sm p-3">
          <div className="searchfield-wrapper position-relative fs-6 ms-auto mb-3">
            <input
              type="search"
              className="form-control ms-auto"
              placeholder="Search data here..."
              autoComplete="off"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <img
              src={SearchIcon}
              alt="Search Icon"
              className="position-absolute top-50 end-0 translate-middle-y pe-3"
            />
          </div>
          <div className="table-responsive table-custom-wrapper">
            <DataTable
              columns={columns}
              data={filteredCompanies}
              pagination
              highlightOnHover
              pointerOnHover
              onRowClicked={handleRowClick}
              progressPending={isLoading}
              noDataComponent={<NoDataComponent />}
              // selectableRows
              customStyles={customStyles}
              dense
              responsive
            />
          </div>
        </div>
      </div>

      {/* Delete Specific Company From Table */}
      <div
        className="modal fade"
        id="deleteCompany"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="deleteCompanyLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-4">
            <div className="modal-body p-md-4 p-lg-5 text-center">
              <div className="fs-18 fw-semibold lh-sm mb-4">
                Are you sure you want to delete this company?
              </div>
              <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3">
                <button
                  type="button"
                  className="btn btn-danger px-4"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary px-4"
                  data-bs-dismiss="modal"
                  onClick={() => dispatch(deleteCompany(companyId))}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
