import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import API_URL from '../../Apiurl';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  error: false,
  success: '',
  employeeList: [],
  editEmployeeList: [],
  dashboard: [],
  deleteLoading: false,
  deleteSuccess: '',
  deleteError: '',
  employeeDetails: null
};

const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false; // Clear previous errors
      state.success = '';  // Clear previous success messages
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.error("Error:", action.payload); // Log error
    },

    // GET CUSTOMER LIST SUCCESS
    getEmployeeListSuccess(state, action) {
      state.isLoading = false;
      state.employeeList = action.payload.data;
      state.error = false;
      state.success = 'Employee list fetched successfully!';
    },

    // START DELETE LOADING
    startDeleteLoading(state) {
      state.deleteLoading = true;
      state.deleteError = false; // Clear previous errors
      state.deleteSuccess = ''; // Clear previous success messages
    },
    getEmployeeListEditSuccess(state, action) {
      // console.log(action, state)
      state.editEmployeeList = action.payload;
    },

    // DELETE HAS ERROR
    deleteHasError(state, action) {
      state.deleteLoading = false;
      state.deleteError = action.payload;
      console.error("Delete Error:", action.payload); // Log error
    },

    // DELETE CUSTOMER SUCCESS
    deleteEmployeeSuccess(state, action) {
      state.deleteLoading = false;
      state.deleteSuccess = 'employee deleted successfully!';
      state.deleteError = false;

      // Optionally, remove the deleted employee from the employeeList
      state.employeeList = state.employeeList?.filter(employee => employee.id !== action.payload.id);
    },
    getEmployeeDetailsSuccess(state, action) {
      state.isLoading = false;
      state.employeeDetails = action.payload.data;
      state.error = false;
    },
    getDashboardSuccess(state, action) {
      state.isLoading = false;
      state.dashboard = action.payload.data;
      state.error = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  getEmployeeListSuccess,
  startDeleteLoading,
  deleteHasError,
  deleteEmployeeSuccess,
  getDashboardSuccess,
  getEmployeeListEditSuccess,
  getEmployeeDetailsSuccess
} = slice.actions;

// Async Action to Get Employee List
export function getEmployeeList() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}list-employee`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        const options = resp.data.data.map(customer => ({
          value: customer.id,
          label: `${customer.first_name} ${customer.last_name}`
        }));
        dispatch(getEmployeeListEditSuccess(options));
        dispatch(getEmployeeListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch employee list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the employee list"));
    }
  };
}

// Async Action to Add Employee
export function addEmployee(e, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.post(`${API_URL}add-employee`, e);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        // dispatch(getEmployeeListSuccess(resp.data));
        dispatch(getEmployeeList());
        toast("Employee added sucessfully!", {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
      } else {
        console.log(resp.data.message)
        console.log(resp.data.message);
        toast.error("Failed to add employee: " + (resp.data.message || "Unknown error"), {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
        dispatch(hasError(resp.message || "Failed to add employee"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      toast.error(error.response.data.message, {
        autoClose: 5000 // 5000 milliseconds = 5 seconds
      });
      dispatch(hasError(error.message || "An error occurred while adding the employee"));
    }
  };
}

// Async Action to Delete employee
export function deleteEmployee(employeeId) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const resp = await axios.delete(`${API_URL}delete-employee/${employeeId}`);
      console.log("API delete response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(deleteEmployeeSuccess({ id: employeeId }));
      } else {
        dispatch(deleteHasError(resp.message || "Failed to delete employee"));
      }
    } catch (error) {
      console.error("Caught delete error:", error); // Log caught error
      dispatch(deleteHasError(error.message || "An error occurred while deleting the Employee"));
    }
  };
}
export function getEmployeeDetails(employeeId) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}view-employee/${employeeId}`);
      console.log("API response:", resp);
      if (resp && resp.status === 200) {
        dispatch(getEmployeeDetailsSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch Employee details"));
      }
    } catch (error) {
      console.error("Caught details error:", error);
      dispatch(hasError(error.message || "An error occurred while fetching the Employee details"));
    }
  };
}
export function getDashboardChart(data) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.post(`${API_URL}dashboard-chart`, data);
      console.log("API response:", resp);
      if (resp && resp.status === 200) {
        dispatch(getDashboardSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch Employee details"));
      }
    } catch (error) {
      console.error("Caught details error:", error);
      dispatch(hasError(error.message || "An error occurred while fetching the Employee details"));
    }
  };
}
export function editEmployee(id, e, navigate) {
  return async (dispatch) => {
    console.log(e)
    dispatch(startLoading());
    try {
      const resp = await axios.put(`${API_URL}update-employee/${id}`, e);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        await dispatch(getEmployeeList());
        await navigate('/employees-list')
      } else {
        dispatch(hasError(resp.message || "Failed to add Employee"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while adding the Employee"));
    }
  };
}
export function editEmployeeAccount(id, e) {
  return async (dispatch) => {
    console.log(e)
    dispatch(startLoading());
    try {
      const resp = await axios.put(`${API_URL}update-employee/${id}`, e);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        // await dispatch(getEmployeeList());
        await dispatch(getEmployeeDetails(id));
      } else {
        dispatch(hasError(resp.message || "Failed to add Employee"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while adding the Employee"));
    }
  };
}
