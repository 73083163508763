import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { rootPersistConfig, rootReducer } from './rootReducer';

// Configure store with persisted reducer and customized middleware
const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

// Initialize persistor
const persistor = persistStore(store);

// Custom hooks to use dispatch and selector
const useSelector = useReduxSelector;
const useDispatch = () => useReduxDispatch();

export { store, persistor, useSelector, useDispatch };
