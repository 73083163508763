import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { addJournalId, getJournalDetails, editJournal } from "../redux/slices/journal";
import { getProjectList } from "../redux/slices/project";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

export const AddJournalCaseId = () => {
  const [journalDate, setJournalDate] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState('');
//   const { projectList } = useSelector((state) => state.project);
  const { journalDetails } = useSelector((state) => state.journal);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
//   useEffect(() => {
//     dispatch(getProjectList());
//     // dispatch(getCountryList());
//     if (id) {
//         dispatch(getJournalDetails(id));
//         // setFormData(employeeDetails);
//         console.log(journalDetails);
//         setTitle(journalDetails?.title);
//         setDescription(journalDetails?.description);
//         setSelectedEmployees(journalDetails?.prj_id);
//         setJournalDate(journalDetails?.date);
//       }
//   }, [dispatch, id]);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      date: journalDate,
      title,
      description,
      prj_id: id,
      //   customers: selectedCustomers,
    };
    console.log("Form Data Submitted:", formData);
    if (id !== undefined) { 
        dispatch(editJournal(id, formData));
    } else {
        dispatch(addJournalId(formData));    
    }
    navigate("/cases-details/"+id);
    // Add your form submission logic here, e.g., sending data to a server
  };

  return (
    <div className="addJournalCasePage py-4">
      <div className="container-fluid">
        <div
          className="bg-white rounded-4 shadow-sm mx-auto px-3 px-md-5 pt-4 pb-5"
          style={{ maxWidth: "600px" }}
        >
          <div className="heading-wrapper text-center mt-md-2 mb-4">
            <div className="fs-3 fw-bold">Add New Journal</div>
          </div>
          <Form onSubmit={handleSubmit}>
            <div className="row gx-2 gx-md-3">
              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    Journal Date<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="journalDate"
                    autoComplete="off"
                    required
                    value={journalDate}
                    onChange={(e) => setJournalDate(e.target.value)}
                  />
                </Form.Group>
              </div>

              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter title"
                    name="caseTitle"
                    autoComplete="off"
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
              </div>

              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Enter description"
                    name="projectSpecs"
                    autoComplete="off"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>
              </div>

              {/* <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Project</Form.Label>
                  <Select
                    components={animatedComponents}
                    // isMulti
                    options={projectList}
                    styles={customStyles}
                    placeholder="Select employees"
                    value={selectedEmployees}
                    onChange={(selected) => setSelectedEmployees(selected)}
                  />
                </Form.Group>
              </div> */}
              {/* <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Project</Form.Label>
                  <Form.Select
                    name="state_id"
                    value={selectedEmployees}
                    onChange={(selected) => setSelectedEmployees(selected.target.value)}
                  >
                    <option selected disabled value="">
                      Select Project
                    </option>
                    {projectList?.map((e) => {
                      return <option value={e.id}>{e.name}</option>;
                    })}
                  </Form.Select>
                </Form.Group>
              </div> */}
            </div>

            <div className="btn-wrapper d-flex flex-wrap gap-3 pt-3">
              <Button
                type="submit"
                variant="primary"
                className="btn-custom text-decoration-none px-4"
              >
                Add Journal
              </Button>
              <Button
                type="reset"
                variant="secondary"
                className="btn-custom text-decoration-none px-5"
                onClick={() => {
                  setJournalDate("");
                  setTitle("");
                  setDescription("");
                  setSelectedEmployees('');
                }}
              >
                Reset
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
