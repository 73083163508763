import React, { useState, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { NavLink, Link, useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import SearchIcon from "../assets/search.svg";

export const Header = () => {
  // Start: Mobile sidebar offcanvas code
  const navigate = useNavigate();
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const openOffcanvas = () => {
    setIsOffcanvasOpen(true);
  };
  // console.log(user?.first_name)
  const closeOffcanvas = () => {
    setIsOffcanvasOpen(false);
  };
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/login");
  };

  const company =
    JSON.parse(localStorage.getItem("usertype")) === "C"
      ? JSON.parse(localStorage.getItem("company"))
      : "";
  return (
    <header className="sticky-top shadow-sm">
      <nav
        className="navbar navbar-light bg-white navbar-expand-lgg top-header sticky-top shadow-sm py-2"
        data-bs-theme="light"
        style={{ minHeight: "65px" }}
      >
        <div className="container-fluid gap-2">
          <div className="left-sec col col-lg-10 d-flex align-items-center justify-content-start gap-3">
            <button
              className="navbar-togglerr border-0 bg-transparent text-primary d-lg-none p-0"
              type="button"
              onClick={openOffcanvas}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20px"
                height="20px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </button>

            <div className="user-company-info d-none d-lg-block w-100">
              <div className="row w-100">
                <div className="col-lg-6">
                  <div className="info-box text-dark fs-12 fw-medium text-capitalize">
                    <div className="company-name text-truncate">
                      {user?.address1 + " " + user?.address2}
                    </div>
                    <div className="company-address text-truncate">
                      {user?.city + " " + user?.state?.name + " " + user?.zip}
                    </div>
                    <div className="company-zipcode text-truncate">
                      {/* Pleasanton US 23441 */}
                      {/* {user?.city + " " + user?.cnt_iso} */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info-box text-dark fs-12 fw-medium text-capitalize">
                    <div className="company-phone text-truncate">
                      Phone: {user?.phone}
                    </div>
                    <div className="company-fax text-truncate">
                      Fax: {user?.fax}
                    </div>
                    {JSON.parse(localStorage.getItem("usertype")) === "C" ? (
                      <div className="company-website text-truncate">
                        URL:{" "}
                        <Link
                          to={company?.url}
                          className="btn-link text-dark text-decoration-none text-lowercase"
                        >
                          {company?.url}
                        </Link>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="searchfield-wrapper d-none d-lg-block">
              <input type="search" className="form-control bg-secondary bg-opacity-10 border-0 shadow-none" placeholder="Enter keywords ..." autoComplete="off" style={{ minHeight: '40px' }} />
              <img src={SearchIcon} alt="Search Icon" className="position-absolute top-50 end-0 translate-middle-y pe-3" />
            </div> */}
          </div>

          <div className="middle-sec col text-center">
            <Link
              to="/"
              className="navbar-brand d-flex flex-column d-lg-none align-items-center p-0 px-2 m-0"
            >
              <div className="d-flex align-items-center gap-2">
                <img
                  className="img-fluid"
                  src={require("../assets/logo.png")}
                  alt="Logo"
                  style={{ maxWidth: "60px" }}
                />
                <div className="text-start">
                  <div className="fs-18 fw-semibold text-body lh-sm">
                    OI Track Admin
                  </div>
                  <div className="app-version text-muted fs-12">
                    Dashboard Version 1.0
                  </div>
                </div>
              </div>
            </Link>
          </div>

          {/* Start: Mobile Sidebar Drawer */}
          <Offcanvas
            show={isOffcanvasOpen}
            onHide={closeOffcanvas}
            style={{ maxWidth: "260px" }}
          >
            <div className="sidebar-wrapper" style={{ display: "block" }}>
              <Sidebar closeOffcanvas={closeOffcanvas} />
            </div>
          </Offcanvas>
          {/* End: Mobile Sidebar Drawer */}

          <ul className="right-sec col d-flex justify-content-end m-0 p-0">
            {/* <li className="nav-item dropdown bell-notification position-relative me-2 me-lg-3">
              <button type="button" className="bell-icon border-0 rounded-circle bg-light p-0" id="dLabel" data-bs-toggle="dropdown" aria-expanded="false" >
                <div className="chip-wrapper">
                  <div className="chip-img d-flex align-items-center justify-content-center rounded-circle bg-primary bg-opacity-25 overflow-hidden">
                    <i className="bi bi-bell-fill text-primary"></i>
                  </div>
                  <span className="position-absolute p-1 bg-primary border border-light rounded-circle" style={{ top: "3px", right: 0 }}>
                    <span className="visually-hidden">New alerts</span>
                  </span>
                </div>
              </button>

              <div className="dropdown-menu dropdown-menu-end notifications shadow p-0" aria-labelledby="dLabel">
                <div className="bg-light border-bottom d-flex align-items-center justify-content-between gap-2 p-3 mb-2">
                  <div className="menu-title fs-6 fw-semibold m-0">Notifications</div>
                  <div className="menu-title m-0">
                    <i className="bi bi-gear"></i>
                  </div>
                </div>
                <div className="notifications-wrapper small overflow-auto px-3" style={{ maxHeight: "300px" }}>
                  <Link className="content d-block" to={"#"}>
                    <div className="notification-item">
                      <div className="item-title text-dark fw-medium m-0">
                        Evaluation Deadline{" "}
                        <span className="text-danger">1 day ago</span>
                      </div>
                      <p className="item-info m-0">
                        Marketing 101, Video Assignment
                      </p>
                    </div>
                  </Link>
                  <Link className="content d-block" to={"#"}>
                    <div className="notification-item">
                      <div className="item-title text-dark fw-medium m-0">
                        Evaluation Deadline{" "}
                        <span className="text-danger">1 day ago</span>
                      </div>
                      <p className="item-info m-0">
                        Marketing 101, Video Assignment
                      </p>
                    </div>
                  </Link>
                  <Link className="content d-block" to={"#"}>
                    <div className="notification-item">
                      <div className="item-title text-dark fw-medium m-0">
                        Evaluation Deadline{" "}
                        <span className="text-danger">1 day ago</span>
                      </div>
                      <p className="item-info m-0">
                        Marketing 101, Video Assignment
                      </p>
                    </div>
                  </Link>
                  <Link className="content d-block" to={"#"}>
                    <div className="notification-item">
                      <div className="item-title text-dark fw-medium m-0">
                        Evaluation Deadline{" "}
                        <span className="text-danger">1 day ago</span>
                      </div>
                      <p className="item-info m-0">
                        Marketing 101, Video Assignment
                      </p>
                    </div>
                  </Link>
                  <Link className="content d-block" to={"#"}>
                    <div className="notification-item">
                      <div className="item-title text-dark fw-medium m-0">
                        Evaluation Deadline{" "}
                        <span className="text-danger">1 day ago</span>
                      </div>
                      <p className="item-info m-0">
                        Marketing 101, Video Assignment
                      </p>
                    </div>
                  </Link>
                  <Link className="content d-block" to={"#"}>
                    <div className="notification-item">
                      <div className="item-title text-dark fw-medium m-0">
                        Evaluation Deadline{" "}
                        <span className="text-danger">1 day ago</span>
                      </div>
                      <p className="item-info m-0">
                        Marketing 101, Video Assignment
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="bg-primary text-light small border-top rounded-bottom text-center p-3 mt-2">
                  See all Notification{" "}
                  <i className="bi bi-arrow-right-circle-fill"></i>
                </div>
              </div>
            </li> */}
            <li className="nav-item dropdown user-logout">
              <button
                className="nav-link text-white text-center border-0 bg-transparent p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="chip-wrapper">
                  <div className="chip-img bg-primary d-flex align-items-center justify-content-center rounded-circle overflow-hidden">
                    {/* <div className="user-shortname text-light">{shortName}</div> */}
                    {JSON.parse(localStorage.getItem("usertype")) === "E" ? (
                      // <img
                      //   className="w-100 h-100"
                      //   src={`https://oitrack.s3.us-west-1.amazonaws.com/${company?.logo}`}
                      //   alt="User"
                      //   referrerPolicy="no-referrer"
                      // />
                      <img
                        className="w-100 h-100"
                        src={require("../assets/logo.png")}
                        alt="User"
                        referrerPolicy="no-referrer"
                      />
                    ) : (
                      <img
                        className="w-100 h-100"
                        src={`https://oitrack.s3.us-west-1.amazonaws.com/${company?.logo}`}
                        alt="User"
                        referrerPolicy="no-referrer"
                      />
                    )}
                  </div>
                </div>
              </button>

              <ul
                className="dropdown-menu dropdown-menu-end caret-indicator shadow-lg p-3 pb-4 mt-2"
                style={{ width: "300px" }}
              >
                <div className="d-flex justify-content-between gap-2">
                  <div className="orgainization-name title-label fw-medium">
                    {/* Virtualemployee Pvt Ltd. */}
                    {JSON.parse(localStorage.getItem("username"))}
                  </div>
                  <Link
                    to="/login"
                    className="signout title-label text-primary fw-medium"
                    onClick={handleLogout}
                  >
                    Sign out
                  </Link>
                </div>
                <div className="d-flex align-items-center gap-2 mt-3">
                  <div className="chip-wrapper">
                    <div
                      className="chip-img bg-primary d-flex align-items-center justify-content-center rounded-pill border border-primary overflow-hidden"
                      style={{ height: "70px", width: "70px" }}
                    >
                      {/* <div className='user-shortname text-light fs-3'>VA</div> */}
                      {JSON.parse(localStorage.getItem("usertype")) === "E" ? (
                        // <img
                        //   className="w-100 h-100"
                        //   src={`https://oitrack.s3.us-west-1.amazonaws.com/${company?.logo}`}
                        //   alt="User"
                        //   referrerPolicy="no-referrer"
                        // />
                        <img
                          className="w-100 h-100"
                          src={require("../assets/logo.png")}
                          alt="User"
                          referrerPolicy="no-referrer"
                        />
                      ) : (
                        <img
                          className="w-100 h-100"
                          src={`https://oitrack.s3.us-west-1.amazonaws.com/${company?.logo}`}
                          alt="User"
                          referrerPolicy="no-referrer"
                        />
                      )}
                    </div>
                  </div>
                  <div className="user-info overflow-hidden">
                    <div className="user-name fw-medium text-capitalize text-truncate lh-sm mb-1">
                      {/* {user?.first_name +
                        " " +
                        user?.middle_name +
                        " " +
                        user?.last_name} */}
                      {(user?.first_name ? user.first_name + " " : "") +
                        (user?.middle_name ? user.middle_name + " " : "") +
                        (user?.last_name ? user.last_name : "").trim()}
                    </div>
                    <div className="user-id fs-12 text-truncate">
                      {/* {user?.emailid} */}
                      {user?.emailid ? user.emailid + " " : ""}
                    </div>
                    <div className="user-id fs-12 text-truncate">
                      {/* {user?.address1 + " " + user?.address2} */}
                      {(user?.address1 ? user.address1 + " " : "") +
                        (user?.address2 ? user.address2 + " " : "")}
                    </div>
                    <div className="user-id fs-12 text-truncate">
                      {/* {user?.address1 + " " + user?.address2} */}
                      {/* {user?.city + " " + user?.cnt_iso} */}
                      {(user?.city ? user.city + " " : "") +
                        (user?.cnt_iso ? user.cnt_iso + " " : "")}
                    </div>
                    <div className="user-id fs-12 text-truncate">
                      Phone : {user?.phone}
                    </div>
                    <div className="user-id fs-12 text-truncate">
                      Fax : {user?.fax}
                    </div>
                    {JSON.parse(localStorage.getItem("usertype")) === "E" ? (
                      <div className="lh-1 mt-1">
                        <Link
                          to={"edit-employee-account/" + user?.id}
                          className="fs-14 btn-link text-primary text-decoration-none"
                        >
                          View account
                        </Link>
                      </div>
                    ) : (
                      <div className="lh-1 mt-1">
                        <Link
                          to={"edit-client-account/" + user?.id}
                          className="fs-14 btn-link text-primary text-decoration-none"
                        >
                          View account
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};
