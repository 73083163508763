import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";

const Sidebar = (props) => {
  const [activepage, setActivepage] = useState("");

  const handlePageClick = (page) => {
    setActivepage(page);
  };

  const closeSidebar = (value) => {
    handlePageClick("activepage", value);
    if (window.innerWidth >= "1024") {
      handlePageClick(value);
    } else {
      handlePageClick(value);
      props.closeOffcanvas();
    }
  };

  return (
    <aside className="sidebar d-flex flex-column flex-shrink-0">
      <Link
        to="/dashboard"
        className="sidebar-logo text-center text-decoration-none border-bottom border-light border-opacity-25 btn-link px-3 py-2"
      >
        <div className="d-flex align-items-center gap-2">
          <img
            className="img-fluid"
            src={require("../assets/logo.png")}
            alt="Logo"
            style={{ maxWidth: "65px" }}
          />
          <div className="text-start">
            <div className="fs-18 fw-semibold text-white lh-sm">
              OI Track Admin
            </div>
            <div className="app-version text-light fs-12">
              Dashboard Version 1.0
            </div>
          </div>
        </div>
      </Link>

      <ul className="sidebar-item-cover list-inline m-0">
        <li className="nav-item">
          <Link
            to="/dashboard"
            className={`${
              activepage === "dashboard" ? "active" : " "
            } nav-link d-flex align-items-center gap-2`}
            onClick={() => closeSidebar("dashboard")}
          >
            <i className="bi bi-house-door"></i>
            <span>Dashboard</span>
          </Link>
        </li>

        {localStorage.getItem("usertype") === '"E"' ? (
          <li className="nav-item">
            <Link
              to={"/cases-list"}
              className={`${
                activepage === "cases-list" ? "active" : " "
              } nav-link d-flex align-items-center gap-2`}
              onClick={() => closeSidebar("cases-list")}
            >
              <i className="bi bi-building-add"></i>
              <span>Clients & Cases</span>
            </Link>
          </li>
        ) : (
          <></>
        )}

        {localStorage.getItem("usertype") === '"E"' ? (
          <li className="nav-item">
            <Link
              to={"/invoice-listing"}
              className={`${
                activepage === "invoice-listing" ? "active" : " "
              } nav-link d-flex align-items-center gap-2`}
              onClick={() => closeSidebar("invoice-listing")}
            >
              <i className="bi bi-receipt"></i>
              <span>Invoice & Expense</span>
            </Link>
          </li>
        ) : (
          <></>
        )}

        {localStorage.getItem("usertype") === '"C"' ? (
          <li className="nav-item">
            <Link
              to={"/company-files"}
              className={`${
                activepage === "company-files" ? "active" : " "
              } nav-link d-flex align-items-center gap-2`}
              onClick={() => closeSidebar("company-files")}
            >
              <i className="bi bi-receipt"></i> Company Files
            </Link>
          </li>
        ) : (
          <></>
        )}

        {JSON.parse(localStorage.getItem("usertype")) === "E" ? (
          <li className="nav-item">
            <Link
              to={"/company-list"}
              className={`${
                activepage === "clients-list" ? "active" : " "
              } nav-link d-flex align-items-center gap-2`}
              onClick={() => closeSidebar("clients-list")}
            >
              <i className="bi bi-journal-bookmark-fill"></i> Client Directory
            </Link>
          </li>
        ) : (
          <></>
        )}

        {JSON.parse(localStorage.getItem("usertype")) === "E" ? (
          <li className="nav-item">
            <Link
              to={"/employees-list"}
              className={`${
                activepage === "employees-list" ? "active" : " "
              } nav-link d-flex align-items-center gap-2`}
              onClick={() => closeSidebar("employees-list")}
            >
              <i className="bi bi-journal-bookmark-fill"></i> Employee Directory
            </Link>
          </li>
        ) : (
          <></>
        )}

        {JSON.parse(localStorage.getItem("usertype")) === "E" ? (
          <li className="nav-item">
            <Link
              to={"/calendar"}
              className={`${
                activepage === "calendar" ? "active" : " "
              } nav-link d-flex align-items-center gap-2`}
              onClick={() => closeSidebar("calendar")}
            >
              <i className="bi bi-calendar2-week"></i> Calendar
            </Link>
          </li>
        ) : (
          <></>
        )}
        {JSON.parse(localStorage.getItem("usertype")) === "E" ? (
          <li className="nav-item">
            <Link
              to={"/settings"}
              className={`${
                activepage === "settings" ? "active" : " "
              } nav-link d-flex align-items-center gap-2`}
              onClick={() => closeSidebar("settings")}
            >
              <i className="bi bi-gear-fill"></i> Settings
            </Link>
          </li>
        ) : (
          <></>
        )}
        <li className="nav-item dropdown">
          <Link
            className="nav-link dropdown-toggle"
            data-bs-toggle="collapse"
            to="#helpCenterMenu1"
            role="button"
            aria-expanded="false"
            aria-controls="helpCenterMenu"
          >
            <i className="bi bi-person-raised-hand"></i> Help Center
          </Link>
          <div className="collapse mt-2 mx-3" id="helpCenterMenu1">
            <ul className="dropdown-menu card px-3 py-2">
              {JSON.parse(localStorage.getItem("usertype")) === "E" ? (
                <li className="mb-2">
                  <Link
                    to="http://54.183.186.136/OITrack_UserManual_Admin.pdf"
                    className="fs-14 text-dark d-flex align-items-center"
                    target="_blank"
                  >
                    Admin Manual
                  </Link>
                </li>
              ) : (
                <></>
              )}
              {JSON.parse(localStorage.getItem("usertype")) === "C" ? (
                <li className="">
                  <Link
                    to="http://54.183.186.136/OITrack_UserManual_Client.pdf"
                    className="fs-14 text-dark d-flex align-items-center"
                    target="_blank"
                  >
                    Client Manual
                  </Link>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </div>
        </li>

        {/* <li className="nav-item">
                    <Link to="/dashboard" className={`${activepage === 'dashboard' ? 'active' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('dashboard')}>
                        <i className="bi bi-house-door"></i>
                        <span>Dashboard</span>
                    </Link>
                </li> */}
        {/* <li className="nav-item">
                    <Link to="/company-list" className={`${activepage === 'company-list' ? 'active' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('company-list')}>
                        <i className="bi bi-buildings"></i>
                        <span>Company List</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/clients-list" className={`${activepage === 'add-company' ? 'active' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('add-company')}>
                        <i className="bi bi-people-fill"></i>
                        <span>Clients List</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={'/employees-list'} className={`${activepage === 'employees-list' ? 'active' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('employees-list')}>
                        <i className="bi bi-person-badge-fill"></i>
                        <span>Employees List</span>
                    </Link>
                </li> */}
        {/* <li className="nav-item">
                    <Link to={'/cases-list'} className={`${activepage === 'cases-list' ? 'active' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('cases-list')}>
                        <i className="bi bi-building-add"></i>
                        <span>Clients & Cases</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={'/journal-cases'} className={`${activepage === 'journal-cases' ? 'active' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('journal-cases')}>
                        <i className="bi bi-receipt"></i>
                        <span>Invoice & Expense</span>
                    </Link>
                </li> */}
        {/* <li className="nav-item">
                    <Link to={'/journal-cases'} className={`${activepage === 'journal-cases' ? 'active' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('journal-cases')}>
                        <i className="bi bi-briefcase"></i>
                        <span>Journal Case</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={'/meeting-list'} className={`${activepage === 'meeting-list' ? 'active' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('meeting-list')}>
                        <img src={require ('../assets/meeting.png')} alt="Meeting" />
                        <span>Meeting List</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={'/milestone-list'} className={`${activepage === 'milestone-list' ? 'active' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('milestone-list')}>
                        <i className="bi bi-signpost"></i>
                        <span>Milestone List</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={'deadline-list'} className={`${activepage === 'deadline-list' ? 'active' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('deadline-list')}>
                        <i className="bi bi-calendar-week"></i>
                        <span>Deadline List</span>
                    </Link>
                </li> */}
      </ul>
    </aside>
  );
};

export default Sidebar;
