import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import API_URL from '../../Apiurl';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  error: false,
  success: '',
  eventList: [],
  deleteLoading: false,
  deleteSuccess: '',
  deleteError: '',
  eventDetails: null,
  eventListType: [],
  event: []
};

const slice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false; // Clear previous errors
      state.success = '';  // Clear previous success messages
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.error("Error:", action.payload); // Log error
    },

    // GET COMPANY LIST SUCCESS
    getEventListSuccess(state, action) {
      state.isLoading = false;
      state.eventList = action.payload.data;
      state.error = false;
      state.success = 'Event list fetched successfully!';
    },
    getEventSuccess(state, action) {
      state.isLoading = false;
      state.event = action.payload.data;
      state.error = false;
      state.success = 'Event list fetched successfully!';
    },
    // START DELETE LOADING
    startDeleteLoading(state) {
      state.deleteLoading = true;
      state.deleteError = false; // Clear previous errors
      state.deleteSuccess = ''; // Clear previous success messages
    },

    // DELETE HAS ERROR
    deleteHasError(state, action) {
      state.deleteLoading = false;
      state.deleteError = action.payload;
      console.error("Delete Error:", action.payload); // Log error
    },

    // DELETE COMPANY SUCCESS
    deleteEventSuccess(state, action) {
      state.deleteLoading = false;
      state.deleteSuccess = 'Event deleted successfully!';
      state.deleteError = false;

      // Optionally, remove the deleted event from the eventList
      state.eventList = state.eventList?.filter(event => event.id !== action.payload.id);
    },
    getEventDetailsSuccess(state, action) {
      state.isLoading = false;
      state.eventDetails = action.payload.data;
      state.error = false;
    },
    getEventTypeListSuccess(state, action) {
        state.isLoading = false;
        state.eventListType = action.payload.data;
        state.error = false;
        state.success = "Project list fetched successfully!";
    },
    
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  getEventListSuccess,
  getEventSuccess,
  startDeleteLoading,
  deleteHasError,
  deleteEventSuccess,
  getEventDetailsSuccess,
  getEventTypeListSuccess
} = slice.actions;

// Async Action to Get Event List
export function getEventList(id) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}list-event/${id}`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getEventListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch event list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the event list"));
    }
  };
}
export function getEventListDay() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}events-day`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getEventSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch event list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the event list"));
    }
  };
}

export function getCalander(url) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(url);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getEventSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch event list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the event list"));
    }
  };
}
export function getEventListId(id) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}list-event-id/${id}`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getEventListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch event list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the event list"));
    }
  };
}

// Async Action to Add Event
export function addEvent(e) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.post(`${API_URL}add-event`, e, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getEventListSuccess(resp.data));
        toast("Added sucessfully!", {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
      } else {
        dispatch(hasError(resp.message || "Failed to add event"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while adding the event"));
    }
  };
}

// Async Action to Delete Event
export function deleteEvent(eventId) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const resp = await axios.delete(`${API_URL}delete-event/${eventId}`);
      console.log("API delete response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(deleteEventSuccess({ id: eventId }));
      } else {
        dispatch(deleteHasError(resp.message || "Failed to delete event"));
      }
    } catch (error) {
      console.error("Caught delete error:", error); // Log caught error
      dispatch(deleteHasError(error.message || "An error occurred while deleting the event"));
    }
  };
}
export function getEventDetails(eventId) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}view-event/${eventId}`);
      console.log("API response:", resp);
      if (resp && resp.status === 200) {
        dispatch(getEventDetailsSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch event details"));
      }
    } catch (error) {
      console.error("Caught details error:", error);
      dispatch(hasError(error.message || "An error occurred while fetching the event details"));
    }
  };
}
export function editEvent(id, e) {
  return async (dispatch) => {
    console.log(e)
    dispatch(startLoading());
    try {
      const resp = await axios.put(`${API_URL}update-event/${id}`, e, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
          toast("Updated sucessfully!", {
            autoClose: 5000 // 5000 milliseconds = 5 seconds
          });
          dispatch(getEventListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to add event"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while adding the event"));
    }
  };
  
}
export function getEventTypeList() {
    return async (dispatch) => {
      dispatch(startLoading());
      try {
        const resp = await axios.get(`${API_URL}list-event-type`);
        console.log("API response:", resp); // Log API response
        if (resp && resp.status === 200) {
          dispatch(getEventTypeListSuccess(resp.data));
        } else {
          dispatch(hasError(resp.message || "Failed to fetch event list"));
        }
      } catch (error) {
        console.error("Caught error:", error); // Log caught error
        dispatch(
          hasError(
            error.message || "An error occurred while fetching the event list"
          )
        );
      }
    };
  }
  