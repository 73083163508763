import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useNavigate } from "react-router-dom";
import { getCustomerList } from "../redux/slices/customer";
import { useSelector, useDispatch } from "react-redux";
import { getEmployeeList } from "../redux/slices/employee";
import { getProjectList } from "../redux/slices/project";
import {
  addEvent,
  getEventTypeList,
  getEventDetails,
  editEvent
} from "../redux/slices/event";
import moment from "moment";
import { useParams } from "react-router-dom";

const animatedComponents = makeAnimated();

const customStyles = {
  placeholder: (provided) => ({
    ...provided,
    color: "#969fc2",
    fontSize: "14px",
    fontWeight: "400",
    padding: "0",
  }),
};

export const AddMeetingDashboard = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { customerList, editCustomerList } = useSelector(
    (state) => state.customer
  );
  const { employeeList } = useSelector((state) => state.employee);
  const { projectList } = useSelector((state) => state.project);
  const { eventListType, eventDetails } = useSelector((state) => state.event);

  const [formData, setFormData] = useState({
    type_id: 3,
    location: "",
    organizer_id: "",
    details: "",
    pattern: 0,
    recurrenceEvery: 1,
    recurrenceDays: "",
    recurrence_pattern: "1st",
    recurrence_week: "Sunday",
    recurrence_month: "",
    recurrenceRange: "range-option1",
    recurrence_start: "2024-06-26",
    recurrence_end: "2024-06-26",
    timeRange: "option1",
    start_time: "",
    end_time: "",
    post_type: "Public",
    participants: [],
    prj_id: localStorage.getItem("prj_id"),
    title: "",
    date: "",
  });

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getCustomerList());
    dispatch(getEmployeeList());
    dispatch(getProjectList());
    dispatch(getEventTypeList());
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (id) {
      dispatch(getEventDetails(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && eventDetails) {
      setFormData(eventDetails);
    }
  }, [id, eventDetails]);

  const handleSelectChange = (selectedOptions, actionMeta) => {
    setFormData((prevData) => ({
      ...prevData,
      [actionMeta.name]: selectedOptions.map((option) => option.value),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Perform form submission logic here (e.g., send data to server)
    console.log("Form data:", formData);
    if (id) {
      await dispatch(editEvent(id, formData))
    } else {
      await dispatch(addEvent(formData));
    }
    const prj_id = await localStorage.getItem("prj_id")
    await navigate("/dashboard");
  };
  console.log(moment().toDate());
  const today = moment().format("YYYY-MM-DD");

  return (
    <div className="addMeetingPage py-3">
      <div className="container-fluid">
        <div className="bg-white rounded-4 shadow-sm mx-auto px-3 px-md-5 pt-4 pb-5" style={{ maxWidth: "850px" }}>
          <div className="heading-wrapper text-center mt-md-2 mb-4">
            <div className="fs-3 fw-bold">{id ? "Edit": "Add a new" } Event</div>
          </div>
          <Form onSubmit={handleSubmit}>
            <div className="row gx-2 gx-md-3">
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    Title<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter title"
                    name="title"
                    autoComplete="off"
                    required
                    value={formData.title}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    defaultValue="2011-08-11"
                    autoComplete="off"
                    value={formData.date}
                    min={today}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>

              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Time Range</Form.Label>
                  <div className="radio-wrapper">
                    <div className="form-check mb-2">
                      <input
                        type="radio"
                        className="form-check-input border-secondary border-opacity-75"
                        name="timeRange"
                        id="allDayEvent"
                        value="allDay"
                        checked={formData.timeRange === "allDay"}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="allDayEvent"
                        className="form-check-label fs-14"
                      >
                        All day event
                      </label>
                    </div>
                    <div className="form-check d-flex align-items-center gap-2">
                      <input
                        type="radio"
                        className="form-check-input border-secondary border-opacity-75 mt-0"
                        name="timeRange"
                        id="inactive"
                        value="specificTime"
                        checked={formData.timeRange === "specificTime"}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="inactive"
                        className="form-check-label fs-14"
                      >
                        <div className="d-flex flex-wrap gap-3 gap-lg-4">
                          <Form.Group className="d-flex align-items-center gap-2">
                            <Form.Label
                              htmlFor="inactive"
                              className="form-check-label text-nowrap m-0"
                            >
                              Start Time
                            </Form.Label>
                            <Form.Control
                              type="time"
                              name="start_time"
                              defaultValue="09:30"
                              autoComplete="off"
                              value={formData.start_time}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="d-flex align-items-center gap-2">
                            <Form.Label
                              htmlFor="inactive"
                              className="form-check-label text-nowrap m-0"
                            >
                              End Time
                            </Form.Label>
                            <Form.Control
                              type="time"
                              name="end_time"
                              defaultValue="20:30"
                              autoComplete="off"
                              value={formData.end_time}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </div>
                      </label>
                    </div>
                  </div>
                </Form.Group>
              </div>

              <div className="col-12">
                <Form.Group className="d-flex align-items-center gap-4 mb-3">
                  <Form.Label className="mb-0">Post Type</Form.Label>
                  <div className="radio-wrapper">
                    <div className="form-check form-check-inline me-5">
                      <input
                        type="radio"
                        className="form-check-input border-secondary border-opacity-75"
                        name="post_type"
                        id="Public"
                        value="Public"
                        checked={formData.post_type === "Public"}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="public"
                        className="form-check-label fs-14"
                      >
                        Public
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        className="form-check-input border-secondary border-opacity-75"
                        name="post_type"
                        id="Private"
                        value="Private"
                        checked={formData.post_type === "Private"}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="private"
                        className="form-check-label fs-14"
                      >
                        Private
                      </label>
                    </div>
                  </div>
                </Form.Group>
              </div>

              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Participants</Form.Label>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    className="custom-select text-dark"
                    classNamePrefix="custom-select"
                    options={editCustomerList}
                    styles={customStyles}
                    placeholder="Add Participants"
                    value={formData.participants?.map((participant) =>
                      editCustomerList.find(
                        (option) => option.value === participant
                      )
                    )}
                    name="participants"
                    onChange={handleSelectChange}
                  />
                </Form.Group>
              </div>

              {/* <div className="col-12">
                <Form.Group className="d-flex flex-wrap align-items-center row-gap-2 column-gap-4 mb-3">
                  <Form.Label className="text-nowrap mb-0">
                    Event Type
                  </Form.Label>
                  <div className="radio-wrapper">
                    {eventListType.map((e) => {
                      return (
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            className="form-check-input border-secondary border-opacity-75"
                            name="type_id"
                            id={e.id}
                            value={e.id}
                            checked={formData.type_id == e.id}
                            onChange={handleChange}
                          />
                          <label
                            htmlFor={e.name}
                            className="form-check-label fs-14"
                          >
                            {e.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </Form.Group>
              </div> */}

              <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter location"
                    name="location"
                    autoComplete="off"
                    value={formData.location}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>

              <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Organizer</Form.Label>
                  <Form.Select
                    value={formData.organizer_id}
                    onChange={(e) =>
                      setFormData({ ...formData, organizer_id: e.target.value })
                    }
                  >
                    <option selected disabled value="">
                      Select employee lead
                    </option>
                    {employeeList?.map((e) => {
                      return (
                        <option value={e.id}>
                          {e.first_name + " " + e.last_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Project</Form.Label>
                  <Form.Select
                    value={formData.prj_id}
                    onChange={(e) =>
                      setFormData({ ...formData, prj_id: e.target.value })
                    }
                  >
                    <option selected disabled value="">
                      Select Project
                    </option>
                    {projectList?.map((e) => {
                      return <option value={e.id}>{e.name}</option>;
                    })}
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Details</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Enter Details"
                    name="details"
                    autoComplete="off"
                    value={formData.details}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>

              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Recurrence Pattern</Form.Label>
                  <div className="radio-wrapper">
                    <div className="form-check mb-2">
                      <input
                        type="radio"
                        className="form-check-input border-secondary border-opacity-75"
                        name="pattern"
                        id="none"
                        value={0}
                        checked={formData.pattern === 0}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            pattern: 0,
                          })
                        }
                      />
                      <label htmlFor="none" className="form-check-label fs-14">
                        None
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input
                        type="radio"
                        className="form-check-input border-secondary border-opacity-75"
                        name="pattern"
                        id="every"
                        value={1}
                        checked={formData.pattern === 1}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            pattern: 1,
                          })
                        }
                        style={{ marginTop: "14px" }}
                      />
                      <label htmlFor="every" className="form-check-label fs-14">
                        <Form.Group className="d-flex align-items-center gap-3 gap-lg-4">
                          <Form.Label
                            htmlFor="every"
                            className="form-check-label fs-14 m-0"
                          >
                            Every
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="recurrenceInterval"
                            defaultValue={1}
                            autoComplete="off"
                            style={{ width: "50px" }}
                            value={formData.recurrenceInterval}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                recurrenceInterval: e.target.value,
                              })
                            }
                          />
                          <Form.Select
                            value={formData.recurrenceUnit}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                recurrenceUnit: e.target.value,
                              })
                            }
                            style={{ maxWidth: "200px" }}
                          >
                            <option hidden value="">
                              Day(s)
                            </option>
                            <option value="Day">Day</option>
                            <option value="Week">Week</option>
                            <option value="Month">Month</option>
                          </Form.Select>
                        </Form.Group>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input border-secondary border-opacity-75"
                        name="pattern"
                        id="every1"
                        value={2}
                        checked={formData.pattern === 2}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            pattern: 2,
                          })
                        }
                        style={{ marginTop: "14px" }}
                      />
                      <label
                        htmlFor="every1"
                        className="form-check-label fs-14 w-100"
                      >
                        <Form.Group className="d-flex flex-wrap align-items-center gap-3 gap-lg-4">
                          <Form.Label
                            htmlFor="every1"
                            className="form-check-label fs-14 m-0"
                          >
                            Every
                          </Form.Label>
                          <Form.Select
                            className="w-100"
                            style={{ minWidth: "100px", maxWidth: "100px" }}
                            value={formData.recurrence_pattern}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                recurrence_pattern: e.target.value,
                              })
                            }
                          >
                            <option value="1st">1st</option>
                            <option value="2nd">2nd</option>
                            <option value="3rd">3rd</option>
                          </Form.Select>
                          <Form.Select
                            className="w-100"
                            style={{ minWidth: "135px", maxWidth: "200px" }}
                            value={formData.recurrenceDay}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                recurrenceDay: e.target.value,
                              })
                            }
                          >
                            <option value="Sunday">Sunday</option>
                            <option value="Monday">Monday</option>
                            <option value="Tuesday">Tuesday</option>
                            <option value="Wednesday">Wednesday</option>
                            <option value="Thursday">Thursday</option>
                            <option value="Friday">Friday</option>
                            <option value="Saturday">Saturday</option>
                          </Form.Select>
                          <Form.Select
                            className="w-100"
                            style={{ minWidth: "135px", maxWidth: "200px" }}
                            value={formData.recurrence_month}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                recurrence_month: e.target.value,
                              })
                            }
                          >
                            <option value="Every Month">Every Month</option>
                            <option value="Every 2 Months">
                              Every 2 Months
                            </option>
                            <option value="Every 3 Months">
                              Every 3 Months
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </label>
                    </div>
                  </div>
                </Form.Group>
              </div>
              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Recurrence Range</Form.Label>
                  <div className="radio-wrapper">
                    <div className="form-check mb-2">
                      <input
                        type="radio"
                        className="form-check-input border-secondary border-opacity-75"
                        name="recurrenceRange"
                        id="startNow"
                        value="range-option1"
                        checked={formData.recurrenceRange === "range-option1"}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            recurrenceRange: e.target.value,
                          })
                        }
                      />
                      <label
                        htmlFor="startNow"
                        className="form-check-label fs-14"
                      >
                        Starts now and never expires
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input
                        type="radio"
                        className="form-check-input border-secondary border-opacity-75"
                        name="recurrenceRange"
                        id="startFrom"
                        value="range-option2"
                        checked={formData.recurrenceRange === "range-option2"}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            recurrenceRange: e.target.value,
                          })
                        }
                        style={{ marginTop: "14px" }}
                      />
                      <label
                        htmlFor="startFrom"
                        className="form-check-label fs-14 w-100"
                      >
                        <div className="d-flex flex-wrap align-items-center gap-3">
                          <Form.Group className="d-flex align-items-center gap-3">
                            <Form.Label
                              htmlFor="startFrom"
                              className="form-check-label fs-14 m-0"
                            >
                              From
                            </Form.Label>
                            <Form.Control
                              type="date"
                              name="recurrence_start"
                              value={formData.recurrence_start}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  recurrence_start: e.target.value,
                                })
                              }
                              autoComplete="off"
                            />
                          </Form.Group>
                          <Form.Group className="d-flex align-items-center gap-3">
                            <Form.Label className="form-check-label fs-14 m-0">
                              TO
                            </Form.Label>
                            <Form.Control
                              type="date"
                              name="recurrence_end"
                              value={formData.recurrence_end}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  recurrence_end: e.target.value,
                                })
                              }
                              autoComplete="off"
                            />
                          </Form.Group>
                        </div>
                      </label>
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>

            <div className="btn-wrapper d-flex flex-wrap gap-3 pt-3">
              <Button
                type="submit"
                variant="primary"
                className="btn-custom text-decoration-none px-4"
              >
                {id ? "Edit": "Add" } Event
              </Button>
              <Button
                type="reset"
                variant="secondary"
                className="btn-custom text-decoration-none px-5"
              >
                Reset
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
