import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
// import {Button, Spinner} from "react-bootstrap/Button";
import { Button, Spinner } from "react-bootstrap";
import {
  addCompany,
  getCompanyDetails,
  editCompany,
} from "../redux/slices/company";
import { getCountryList } from "../redux/slices/countries";
import { getStateList } from "../redux/slices/state";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

export const AddCompany = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const { companyDetails, isLoading } = useSelector((state) => state.company);
  const { stateList } = useSelector((state) => state.state);
  const [preview, setPreview] = useState(null); // State for preview image

  useEffect(() => {
    dispatch(getStateList());
    dispatch(getCountryList());
  }, [dispatch]);
  const [formData, setFormData] = useState({
    name: "",
    url: "",
    logo: "",
    file:"",
    phone: "",
    fax: "",
    address1: "",
    address2: "",
    // country: "",
    sta_id: 1,
    city: "",
    zip: "",
    status: "A",
    cnt_iso: "US",
  });
  const [formDataUpdate, setFormDataUpdate] = useState({
    name: "",
    url: "",
    logo: "",
    phone: "",
    fax: "",
    address1: "",
    address2: "",
    // country: "",
    sta_id: 1,
    city: "",
    zip: "",
    status: "A",
    cnt_iso: "US",
  });

  useEffect(() => {
    if (id) {
      dispatch(getCompanyDetails(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && companyDetails) {
      setFormData(companyDetails);
    }
  }, [id, companyDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // console.log(name, value);
    if (name === "sta_id") {
      const selectedState = stateList.find(
        (state) => state.id === parseInt(value)
      );
      if (selectedState) {
        setFormData({
          ...formData,
          sta_id: value,
          cnt_iso: selectedState.cnt_iso, // Set cnt_iso from the selected state
        });
      }
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        file: file,
        logo: URL.createObjectURL(file),
      });
      setPreview(URL.createObjectURL(file)); // Set preview URL
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send the data to an API
    // console.log(formData);
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("url", formData.url);
    // formDataToSend.append("logo", formData.logo); // Note: This handles file input
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("fax", formData.fax);
    formDataToSend.append("address1", formData.address1);
    formDataToSend.append("address2", formData.address2);
    // formDataToSend.append("country", formData.country);
    formDataToSend.append("sta_id", formData.sta_id);
    formDataToSend.append("city", formData.city);
    formDataToSend.append("zip", formData.zip);
    formDataToSend.append("status", formData.status);
    formDataToSend.append("cnt_iso", formData.cnt_iso);
    if (id) {
      await formDataToSend.append("id", id);
      console.log(formDataToSend);
      await dispatch(editCompany(id, formDataToSend, formData.file));
    } else {
      formDataToSend.append("logo", formData.file); // Note: This handles file input
      await dispatch(addCompany(formDataToSend));
    }
    await navigate("/company-list")
  };

  return (
    <div className="addCompanyPage py-3">
      <div className="container-fluid">
        <div
          className="bg-white border border-primary rounded-4 mx-auto px-3 px-md-5 pt-4 pb-5"
          style={{ maxWidth: "850px" }}
        >
          <div className="heading-wrapper text-center mt-md-2 mb-4">
            <div className="fs-3 fw-bold">{id ? "Edit" : "Add"} Company</div>
          </div>
          <Form onSubmit={handleSubmit}>
            <div className="row gx-2 gx-md-3">
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    Company name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter company name"
                    name="name"
                    value={formData?.name}
                    onChange={handleInputChange}
                    autoComplete="off"
                    required
                  />
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter site url"
                    name="url"
                    value={formData?.url}
                    onChange={handleInputChange}
                    autoComplete="off"
                    required
                  />
                </Form.Group>
              </div>

              <div className="col-12">
                <Form.Group className="mb-3">
                  {/* <img
                    src={`https://oitrack.s3.us-west-1.amazonaws.com/${formData?.logo}`}
                    className="rounded-circle"
                    style={{ width: "40px", height: "40px" }}
                    alt=""
                  /> */}
                  <img
                    src={
                      formData.logo.includes("http")
                        ? formData.logo
                        : `https://oitrack.s3.us-west-1.amazonaws.com/${formData.logo}`
                    }
                    className="rounded-circle"
                    style={{ width: "40px", height: "40px" }}
                    alt=""
                  />

                  <Form.Label className="form-label">Company logo</Form.Label>
                  <div
                    className="rounded-3 text-center p-4"
                    style={{ border: "1.5px dashed #969fc2" }}
                  >
                    <input
                      type="file"
                      name="logo"
                      id="file-upload"
                      className="d-none"
                      accept=".webp,.png,.jpg,.jpeg"
                      onChange={handleFileChange}
                    />
                    <label
                      htmlFor="file-upload"
                      className="btn btn-outline-primary rounded-pill px-4 mt-1 mb-2"
                    >
                      Upload Logo
                    </label>
                    <div className="suppFormat text-clr2 fs-14">
                      Supported Formats: webp, png, jpg, jpeg, up to 2 MB
                    </div>
                  </div>
                </Form.Group>
              </div>

              <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Phone number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter phone number"
                    name="phone"
                    value={formData?.phone}
                    onChange={handleInputChange}
                    autoComplete="off"
                    required
                  />
                </Form.Group>
              </div>

              <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Fax</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter fax number"
                    name="fax"
                    value={formData?.fax}
                    onChange={handleInputChange}
                    autoComplete="off"
                    required
                  />
                </Form.Group>
              </div>

              <div className="col-12 col-lg-6">
                <Form.Group className="mb-3">
                  <Form.Label>Address1</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter address 1"
                    name="address1"
                    value={formData?.address1}
                    onChange={handleInputChange}
                    autoComplete="off"
                    required
                  />
                </Form.Group>
              </div>

              <div className="col-12 col-lg-6">
                <Form.Group className="mb-3">
                  <Form.Label>Address2</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter address 2"
                    name="address2"
                    value={formData?.address2}
                    onChange={handleInputChange}
                    autoComplete="off"
                    required
                  />
                </Form.Group>
              </div>

              <div className="col-12 col-md-4">
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Form.Select
                    name="sta_id"
                    value={formData?.sta_id}
                    onChange={handleInputChange}
                    required
                  >
                    <option disabled value="">
                      Select state
                    </option>
                    {stateList?.map((e) => {
                      return <option value={e.id}>{e.name}</option>;
                    })}
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="col-12 col-sm-6 col-md-4">
                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter city"
                    name="city"
                    value={formData?.city}
                    onChange={handleInputChange}
                    autoComplete="off"
                    required
                  />
                </Form.Group>
              </div>

              <div className="col-12 col-sm-6 col-md-4">
                <Form.Group className="mb-3">
                  <Form.Label>Zipcode</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter zipcode"
                    name="zip"
                    value={formData?.zip}
                    onChange={handleInputChange}
                    maxLength="6"
                    autoComplete="off"
                    required
                  />
                </Form.Group>
              </div>

              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <div className="radio-wrapper">
                    <div className="form-check form-check-inline me-5">
                      <input
                        className="form-check-input border-secondary border-opacity-75"
                        type="radio"
                        name="status"
                        id="A"
                        value="A"
                        checked={formData?.status === "A"}
                        onChange={handleInputChange}
                      />
                      <label className="form-check-label fs-14" htmlFor="A">
                        Active
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input border-secondary border-opacity-75"
                        type="radio"
                        name="status"
                        id="I"
                        value="I"
                        checked={formData?.status === "I"}
                        onChange={handleInputChange}
                      />
                      <label
                        className="form-check-label fs-14"
                        htmlFor="inactive"
                      >
                        Inactive
                      </label>
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>

            <div className="btn-wrapper d-grid pt-2">
              <Button
                type="submit"
                variant="primary"
                className="btn-custom text-decoration-none"
              >
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {" Loading..."}
                  </>
                ) : (
                  "Submit"
                )}

                {/* {isLoading}Submit */}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
