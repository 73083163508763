import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import API_URL from "../../Apiurl";
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  error: false,
  success: "",
  projectList: [],
  projectListType: [],
  projectListStatus: [],
  projectFullDetails: [],
  deleteLoading: false,
  deleteSuccess: "",
  deleteError: "",
  projectDetails: null,
};

const slice = createSlice({
  name: "project",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false; // Clear previous errors
      state.success = ""; // Clear previous success messages
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.error("Error:", action.payload); // Log error
    },

    // GET COMPANY LIST SUCCESS
    getProjectListSuccess(state, action) {
      state.isLoading = false;
      state.projectList = action.payload.data;
      state.error = false;
      state.success = "Project list fetched successfully!";
    },
    getProjectTypeListSuccess(state, action) {
      state.isLoading = false;
      state.projectListType = action.payload.data;
      state.error = false;
      state.success = "Project list fetched successfully!";
    },
    getProjectStatusListSuccess(state, action) {
      state.isLoading = false;
      state.projectListStatus = action.payload.data;
      state.error = false;
      state.success = "Project list fetched successfully!";
    },

    // START DELETE LOADING
    startDeleteLoading(state) {
      state.deleteLoading = true;
      state.deleteError = false; // Clear previous errors
      state.deleteSuccess = ""; // Clear previous success messages
    },

    // DELETE HAS ERROR
    deleteHasError(state, action) {
      state.deleteLoading = false;
      state.deleteError = action.payload;
      console.error("Delete Error:", action.payload); // Log error
    },

    // DELETE COMPANY SUCCESS
    deleteProjectSuccess(state, action) {
      state.deleteLoading = false;
      state.deleteSuccess = "Project deleted successfully!";
      state.deleteError = false;

      // Optionally, remove the deleted project from the projectList
      state.projectList = state.projectList?.filter(
        (project) => project.id !== action.payload.id
      );
    },
    getProjectDetailsSuccess(state, action) {
      state.isLoading = false;
      state.projectDetails = action.payload.data;
      state.error = false;
    },
    getProjectFullDetailsSuccess(state, action) {
      state.isLoading = false;
      state.projectFullDetails = action.payload.data;
      state.error = false;
    },
  },
  
  getFilesProjectListSuccess(state, action) {
    state.isLoading = false;
    state.filesListCustomer = action.payload.data;
    state.error = false;
    state.success = 'Files list fetched successfully!';
  },

});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  getProjectListSuccess,
  getProjectTypeListSuccess,
  getProjectStatusListSuccess,
  startDeleteLoading,
  deleteHasError,
  deleteProjectSuccess,
  getProjectDetailsSuccess,
  getProjectFullDetailsSuccess,
  getFilesProjectListSuccess
} = slice.actions;

// Async Action to Get Project List
export function getProjectList() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}list-project`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getProjectListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch project list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(
        hasError(
          error.message || "An error occurred while fetching the project list"
        )
      );
    }
  };
}
export function getProjectListId(id) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      // const cmp_id = localStorage.getItem("cmp_id");
      const resp = await axios.get(`${API_URL}clients-project/${id}`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getProjectListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch project list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(
        hasError(
          error.message || "An error occurred while fetching the project list"
        )
      );
    }
  };
}
export function getProjectClientId(id) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      // const cmp_id = localStorage.getItem("cmp_id");
      const resp = await axios.get(`${API_URL}clients-related-project/${id}`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getProjectListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch project list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(
        hasError(
          error.message || "An error occurred while fetching the project list"
        )
      );
    }
  };
}

export function getProjectDetailsById(id) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}project-details/${id}`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getProjectDetailsSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch project list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(
        hasError(
          error.message || "An error occurred while fetching the project list"
        )
      );
    }
  };
}
export function getProjectListById(id, cmp_id) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      // const cmp_id = localStorage.getItem("cmp_id");
      const resp = await axios.get(`${API_URL}clients-project/${id}/${cmp_id}`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getProjectListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch project list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(
        hasError(
          error.message || "An error occurred while fetching the project list"
        )
      );
    }
  };
}

export function getProjectStatusList() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}list-project-status`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getProjectStatusListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch project list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(
        hasError(
          error.message || "An error occurred while fetching the project list"
        )
      );
    }
  };
}
export function getProjectTypeList() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}list-project-types`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getProjectTypeListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch project list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(
        hasError(
          error.message || "An error occurred while fetching the project list"
        )
      );
    }
  };
}

// Async Action to Add Project
export function addProject(e, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.post(`${API_URL}add-project`, e, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        const user = JSON.parse(localStorage.getItem("user"));
        const id = user.user_id;
        dispatch(getProjectListId(id));
        toast("Project added sucessfully!", {
          autoClose: 2000 // 5000 milliseconds = 5 seconds
        });
        await navigate('/cases-list');
      } else {
        console.log(resp.message)
        toast(resp.message, {
          autoClose: 2000 // 5000 milliseconds = 5 seconds
        });
        dispatch(hasError(resp.message || "Failed to add project"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(
        hasError(error.message || "An error occurred while adding the project")
      );
    }
  };
}
export function projectFilesId(id) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      // const id = localStorage.getItem("prj_id");
      const resp = await axios.get(`${API_URL}project-files-project-id/${id}`);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        dispatch(getFilesProjectListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch journal list"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the journal list"));
    }
  };
}
// Async Action to Delete Project
export function deleteProject(projectId) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const resp = await axios.delete(`${API_URL}delete-project/${projectId}`);
      console.log("API delete response:", resp); // Log API response
      if (resp && resp.status === 200) {
        toast("Project deleted sucessfully!", {
          autoClose: 2000 // 5000 milliseconds = 5 seconds
        });
        dispatch(deleteProjectSuccess({ id: projectId }));
      } else {
        dispatch(deleteHasError(resp.message || "Failed to delete project"));
      }
    } catch (error) {
      console.error("Caught delete error:", error); // Log caught error
      dispatch(
        deleteHasError(
          error.message || "An error occurred while deleting the project"
        )
      );
    }
  };
}

export function addProjectFiles(params) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.post(`${API_URL}upload-project-files`, params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log("API delete response:", resp); // Log API response
      if (resp && resp.status === 200) {
        startLoading();
        console.log(resp.data);
        toast("File added sucessfully!", {
          autoClose: 2000 // 5000 milliseconds = 5 seconds
        });
        dispatch(projectFilesId(resp.data.data.prj_id));
      } else {
        dispatch(deleteHasError(resp.message || "Failed to delete project"));
      }
    } catch (error) {
      console.error("Caught delete error:", error); // Log caught error
      dispatch(
        deleteHasError(
          error.message || "An error occurred while deleting the project"
        )
      );
    }
  };
}
export function getProjectDetails(projectId) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}view-project/${projectId}`);
      console.log("API response:", resp);
      if (resp && resp.status === 200) {
        dispatch(getProjectDetailsSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch project details"));
      }
    } catch (error) {
      console.error("Caught details error:", error);
      dispatch(
        hasError(
          error.message ||
            "An error occurred while fetching the project details"
        )
      );
    }
  };
}
export function getProjectFullDetails(projectId) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.get(`${API_URL}view-project-details/${projectId}`);
      console.log("API response:", resp);
      if (resp && resp.status === 200) {
        dispatch(getProjectFullDetailsSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message || "Failed to fetch project details"));
      }
    } catch (error) {
      console.error("Caught details error:", error);
      dispatch(
        hasError(
          error.message ||
            "An error occurred while fetching the project details"
        )
      );
    }
  };
}
export function editProject(id, e, navigate) {
  return async (dispatch) => {
    console.log(e);
    dispatch(startLoading());
    try {
      const resp = await axios.post(`${API_URL}update-project/${id}`, e, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {  
        toast("Project updated sucessfully!", {
          autoClose: 2000 // 5000 milliseconds = 5 seconds
        });
        // dispatch(getProjectListSuccess(resp.data));
        const user = JSON.parse(localStorage.getItem("user"));
        const id = user.user_id;
        dispatch(getProjectListId(id));
        await navigate('/cases-list');

      } else {
        dispatch(hasError(resp.message || "Failed to add project"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(
        hasError(error.message || "An error occurred while adding the project")
      );
    }
  };
}
