// reducers/userSlice.js
import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios'; // Adjust the path as needed
import API_URL from '../../Apiurl'; // Adjust the path as needed
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  error: false,
  myProfile: null
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },
  },
});

export default slice.reducer;

export const { startLoading, hasError, getProfileSuccess } = slice.actions;

export const login = (credentials, navigate) => async (dispatch) => {
  console.log(credentials);
  dispatch(startLoading());
  try {
    try {
      const resp = await axios.post(`${API_URL}login`, credentials);
      console.log("API response:", resp); // Log API response
      if (resp && resp.data.status === 200) {
        localStorage.setItem('username', JSON.stringify(credentials.username));
        localStorage.setItem('token', JSON.stringify(resp.data.data.token));
        localStorage.setItem('user', JSON.stringify(resp.data.data.user));
        localStorage.setItem('company', JSON.stringify(resp.data.data.company));
        localStorage.setItem('usertype', JSON.stringify(credentials.usertype));
        dispatch(getProfileSuccess(resp.data));
        // debugger
        toast("Login sucessfull!", {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
        navigate('/dashboard');
      } else {
        console.log("skdjfkndsml");
        dispatch(hasError(resp.message || "Failed to fetch company list"));
        toast("Invalid Credential!!", {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(hasError(error.message || "An error occurred while fetching the company list"));
    }
    
  } catch (error) {
    dispatch(hasError(error.message));
  }
};
export function changePassword(e) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.post(`${API_URL}change-user-password`, e);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        console.log(resp.data);
        toast("Password changed...Please login again to check!!", {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
      } else {
        dispatch(hasError(resp.message || "Failed to add project"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(
        hasError(error.message || "An error occurred while adding the project")
      );
    }
  };
}
export function forgetPassword(e) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await axios.post(`${API_URL}forgot-password`, e);
      console.log("API response:", resp); // Log API response
      if (resp && resp.status === 200) {
        console.log(resp.data);
        toast("Reset mail is sent to your account....please check!!", {
          autoClose: 5000 // 5000 milliseconds = 5 seconds
        });
      } else {
        dispatch(hasError(resp.message || "Failed to add project"));
      }
    } catch (error) {
      console.error("Caught error:", error); // Log caught error
      dispatch(
        hasError(error.message || "An error occurred while adding the project")
      );
    }
  };
}
